// @ts-check

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// core
import { SpinnerLoader } from 'core/components/Loaders';

import { TableContainer } from '../TableContainer';

const ButtonLink = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: inherit;
  text-decoration: underline;
`;

const SortIconContainer = styled.span`
  [data-loading='true'] & {
    box-shadow: none !important;
    & * {
      box-shadow: none !important;
      cursor: not-allowed !important;
    }
  }
`;

const SortIcon = styled.i.attrs({
  className: 'fas fa-sort',
})`
  margin-left: 0.5rem;
  cursor: pointer;
  outline: none !important;
  transition: 0.3s ease;

  *:not([data-loading='true']) & {
    &:focus {
      box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #191919 !important;
    }
    &:active {
      transform: scale(1.2);
      transition: transform 300ms;
    }
  }
`;

const ShowAll = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  h2,
  h3,
  h4 {
    margin: 0;
  }
`;

const BadgeStyle = styled.span.attrs(({ badgeType }) => ({
  badgeType: '',
  className: `badge badge-${badgeType} text-dark p-1`,
}))`
  border-radius: 5px;
  width: 100%;
  max-width: fit-content;
`;

/**
 * @param {PropsWithChildren<{
 *  badgeType: 'success' | 'info'
 * }>} props
 * */
const Badge = ({ badgeType, children }) => (
  <BadgeStyle badgeType={badgeType}>{children}</BadgeStyle>
);

const Container = styled.div`
  .loader-wrapper {
    margin-block: 1rem;
  }

  .no-data-wrapper {
    text-align: left;
    letter-spacing: 0px;
    color: #191919;
    margin: 1rem;
  }
`;

/**
 * @param {PropsWithChildren<{
 *  testId?: string
 *  loading?: boolean
 *  config: TableConfig
 *  rows: Object[]
 *  caption?: string
 *  height?: string
 *  noDataPlaceholder?: string
 *  showNoDataPlaceholder?: boolean
 *  showActionButton?: boolean
 *  actionButtonText?: string
 *  onActionButtonClick?: () => void,
 *  onCellClick?: (param: CellClickType) => void,
 *  onSort?: (colId: string, colIndex: number) => void
 * }>} props
 */
function DataTable({
  testId,
  loading,
  caption,
  config,
  rows,
  height,
  noDataPlaceholder = 'No data to show',
  showNoDataPlaceholder = true,
  actionButtonText = '',
  showActionButton = false,
  onActionButtonClick = () => null,
  onCellClick = (param) => null,
  onSort = (colKey, colIndex) => null,
}) {
  const { columns } = config;

  const [dataRows, setDataRows] = useState([]);
  const [sortStarted, setSortStarted] = useState(false);
  const [sortedRows, setSortRows] = useState([]);
  const [sortedColumns, setSortedColumns] = useState(
    new Array(columns.length).fill(false),
  );

  useEffect(() => {
    setDataRows(rows);
    setSortedColumns(new Array(columns.length).fill(false));
  }, [columns.length, rows]);

  useEffect(() => {
    if (sortedRows.length > 0) setDataRows(sortedRows);
  }, [sortedRows]);

  /**
   * @param {string} colId
   * @param {CellDataType} dataType
   */
  const clientSort = (colId, dataType) => {
    setSortStarted(true);
    const isSorted = sortedColumns[colId];
    let newRows = [...rows];

    if (dataType === 'boolean') {
      newRows.sort((a, b) => {
        const val1 = a[colId];
        const val2 = b[colId];
        return !isSorted ? val1 - val2 : val2 - val1;
      });
    } else {
      newRows.sort((a, b) =>
        !isSorted
          ? a[colId]?.localeCompare?.(b[colId])
          : b[colId]?.localeCompare?.(a[colId]),
      );
    }
    setSortRows([...newRows]);
    sortedColumns[colId] = !isSorted;
    setSortedColumns(sortedColumns);
    setSortStarted(false);
  };

  /**
   * @param {{colId: string, dataType: CellDataType, colIndex: number}} params
   */
  const sortTable = ({ colId = '', dataType, colIndex = 0 }) => {
    config.clientSorting
      ? clientSort(colId, dataType)
      : onSort(colId, colIndex);
  };

  /**
   * @param {CellRenderInputType} params
   */
  const getCellElement = (params) => {
    const { col, cellValue: rawValue } = params;
    const cellValue = col.format?.(rawValue) || rawValue;

    if (col.render) return col.render(params);

    const {
      className,
      text,
      linkUrl = null,
      linkTo = null,
    } = col?.componentProps || {};

    if (col.componentType === 'badge')
      return (
        <Badge badgeType={col.computedValue?.(rawValue)}>{cellValue}</Badge>
      );

    if (col.componentType === 'button')
      return (
        <button
          type="button"
          className={`btn btn-sm btn-dark ${className || ''}`}
          onClick={() => onCellClick(params)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...col.componentProps}
        >
          {text}
        </button>
      );

    if (col.componentType === 'nav-link')
      return (
        <a
          className={`${className || ''}`}
          href={linkUrl || linkTo?.(params) || '/'}
          onClick={(e) => {
            e.preventDefault();
            onCellClick(params);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...col.componentProps}
        >
          {text}
        </a>
      );

    return cellValue;
  };

  return (
    <Container data-loading={loading}>
      <ShowAll>
        {caption && <h4>{caption}</h4>}
        {showActionButton && (
          <ButtonLink className="text-maroon" onClick={onActionButtonClick}>
            {actionButtonText}
          </ButtonLink>
        )}
      </ShowAll>
      <div>
        <TableContainer
          columFixedLayout={false}
          recordCount={rows.length}
          height={height}
        >
          <table data-sort-started={sortStarted} data-testid={testId}>
            <thead>
              <tr>
                {config?.columns?.map((col, colIndex) => (
                  <th
                    key={col.id}
                    style={{
                      width: col.width || 'auto',
                      textAlign: col.textAlign,
                      ...col.columnStyle,
                    }}
                    data-sortable={col.sortable}
                  >
                    {col.text}
                    {col.sortable && (
                      <SortIconContainer
                        data-disabled={sortStarted}
                        onClick={() =>
                          !loading &&
                          !sortStarted &&
                          sortTable({
                            colId: col.id,
                            dataType: col.dataType,
                            colIndex,
                          })
                        }
                      >
                        <SortIcon tabIndex={0} />
                      </SortIconContainer>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataRows?.map((dataRow, rowIndex) => {
                const row = dataRow;
                const id = row[config.rowKeyId];

                return (
                  <tr key={id}>
                    {config?.columns?.map((col, colIndex) => {
                      const cellValue = row[col.id];

                      return (
                        <td
                          key={col.id}
                          style={{
                            ...col.cellStyle,
                            textAlign: col.textAlign,
                          }}
                          data-sortable={col.sortable}
                        >
                          {getCellElement({
                            col,
                            row,
                            cellValue,
                            id,
                          })}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {loading && (
            <div className="loader-wrapper">
              <SpinnerLoader
                spinnerType="circle"
                spinnerMessage={[
                  'Campaigns loading...',
                  'The campaigns may take a few minutes to load.',
                ]}
              />
            </div>
          )}

          {!loading && showNoDataPlaceholder && rows.length === 0 && (
            <p className="no-data-wrapper">{noDataPlaceholder}</p>
          )}
        </TableContainer>
      </div>
    </Container>
  );
}

export { DataTable };
