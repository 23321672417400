// @ts-check

import React from 'react';
import {
  CheckBoxList,
  ErrorValidation,
  RequiredIcon,
  Tooltip,
} from 'core/components';

/** @type {CheckBoxListItem[]} */
const purposes = [
  { id: 'engagement', value: 'Engagement' },
  { id: 'informational', value: 'Informational' },
  { id: 'persistenceRetention', value: 'Persistence/Retention' },
  { id: 'support', value: 'Support' },
];

/** @type {CheckBoxListItem} */
const otherItem = { id: 'other', value: '', checked: false };

/**
 * @param {{
 *    campaignPurpose: CampaignPurpose
 *    onChange: (e) => void
 *    readonly: boolean
 *    errors?: string []
 * }} props
 */
function CampaignPurpose({ campaignPurpose, onChange, readonly, errors }) {
  return (
    <div
      className={`form-group list-group m-0 ${
        errors.length > 0 ? 'is-invalid' : ''
      }`}
      id="campaign-purpose-field"
      data-testid="campaign-purpose-field"
    >
      <div className={`form-check form-control border-0 m-0 pl-0 `}>
        <label>
          <RequiredIcon />
          Campaign Purpose
          <Tooltip tooltipId="campaign-purpose-tooltip">
            The purpose of the campaign; select at least 1. This field is for
            reporting and tracking purposes, as well as to inform future
            development.
          </Tooltip>
        </label>

        <div className={errors?.length > 0 ? 'mb-2' : 'd-none'}>
          <ErrorValidation errors={errors} />
        </div>

        <CheckBoxList
          testId="campaign-purpose"
          id="campaign-purpose"
          name="campaignPurpose"
          items={purposes}
          otherItem={otherItem}
          value={{
            selectedItems: campaignPurpose.purposes,
            other: campaignPurpose.other,
          }}
          placeholder="Other purpose"
          readOnly={readonly}
          disabled={readonly}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export { CampaignPurpose };
