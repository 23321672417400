// @ts-check
import React from 'react';
import DOMPurify from 'dompurify';

/**
 * @param {{
 *  content: string
 *  title: string
 *  isOpen: boolean
 *  onClose: () => void
 * }} props
 */
function AnnouncementAlert({
  content,
  title,
  isOpen = false,
  onClose = () => null,
}) {
  const sanitizedContent = DOMPurify.sanitize(content);

  return isOpen ? (
    <div className="row g-0">
      <div className="col uds-full-width">
        <section className="banner-blue">
          <div className="banner uds-content-align" role="alert">
            <div className="banner-icon">
              <i title="Banner" className="fa fa-icon fa-bell" />
            </div>
            <div className="banner-content">
              <p>
                <strong>{title}</strong>
              </p>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedContent,
                  }}
                />
              </div>
            </div>

            <div className="alert-close">
              <button
                type="button"
                className="btn btn-circle btn-circle-alt-black close"
                aria-label="Close"
                data-dismiss="alert"
                onClick={onClose}
              >
                <i className="fas fa-times" />
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : null;
}

export { AnnouncementAlert };
