import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Modal is on top of other elements.

  h3 {
    margin: 0 0 10px 0;
  }

  #cb-modal {
    background: white;
    padding: 10px 20px 10px 20px;
    max-width: 500px;
    width: 100%;
    max-height: calc(100vh - 10px - 10px);
    // overflow-y: auto; // Enable vertical scrolling.
    margin-bottom: -20px;
    margin-top: 120px;
    position: relative;
  }

  .modal-header {
    align-items: center;
    display: flex;
    justify-content: full;
    padding: 0;
  }

  .btn-close-x {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    // Handle the case when isOpen changes
    const handleEscape = (event) => {
      if (event.keyCode === 27) onClose(); // Close modal on ESC key
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Container>
      <div id="cb-modal">
        <div className="modal-header">
          <h3>Export List</h3>
          <button
            type="button"
            name="close-x-button"
            className="btn-close-x"
            onClick={onClose}
          >
            <span className="fa-regular fa-circle-xmark" />
          </button>
        </div>
        {children}
      </div>
    </Container>,
    document.body,
  );
};

export { Modal };
