// @ts-check
import React, { useEffect, useReducer, useState } from 'react';

// core
import {
  csvErrorType,
  Dialog,
  OPEN_MODE,
  processCsvFile,
  removeFileExtension,
  RequiredIcon,
  scrollToElement,
} from 'core';

// state
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  debounceStoreDraft,
  setPageLoading,
  setRemoveFileAsync,
} from 'core/store/slices';

// local components
import { CampaignDescription } from './components/CampaignDescription';
import { CampaignName } from './components/CampaignName';
import { CampaignPurpose } from './components/CampaignPurpose';
import { FileUploader } from './components/FileUploader';
import { OutreachChannel } from './components/OutreachChannel';

import './style.scss';

// constraints
const CAMPAIGN_NAME_MIN_LENGTH = 5;
const CAMPAIGN_NAME_MAX_LENGTH = 60;
const CAMPAIGN_DESC_MIN_LENGTH = 5;
const FILE_NAME_MAX_LENGTH = 30;
/* eslint-disable max-len */
// errors
const ERROR_CAMPAIGN_NAME_MAX_LENGTH = `Please use a max of ${CAMPAIGN_NAME_MAX_LENGTH} characters in your Campaign Name.`;
const ERROR_CAMPAIGN_NAME_MIN_LENGTH = `Please use a minimum of ${CAMPAIGN_NAME_MIN_LENGTH} characters in your Campaign Name.`;
const ERROR_CAMPAIGN_DESC = `Please include at least ${CAMPAIGN_DESC_MIN_LENGTH} characters in your Campaign Description.`;
const ERROR_CAMPAIGN_PURPOSE = `Please select at least 1 Campaign Purpose.`;
const ERROR_OTHER_PURPOSE_DUPLICATED = `This option already exists; please enter the name of your Campaign Purpose.`;
const ERROR_OTHER_PURPOSE_EMPTY = `The option "other" can't be empty; please enter the name of your Campaign Purpose. `;
const ERROR_OUTREACH_CHANNEL = `Please select at least 1 Outreach Channel.`;
const ERROR_OTHER_CHANNEL_DUPLICATED = `This option already exists; please enter the name of your Outreach Channel.`;
const ERROR_OTHER_CHANNEL_EMPTY = `The option "other" can't be empty; please enter the name of your Outreach Channel. `;
const ERROR_FILE_FIRST_COLUMN_LENGTH =
  'All first column (student_id) values of the file must be 10 digits in length.';
const ERROR_FILE_HEADER_FIRST_COLUMN_NAME = `The first column header must be named "student_id"`;
const ERROR_FILE_HEADER_SPECIAL_CHARACTERS =
  'The header columns must not contain any special characters.';
const ERROR_FILE_NAME_LENGTH = `The file name must not exceed ${FILE_NAME_MAX_LENGTH} characters in length.`;
const ERROR_FILE_NAME_NOT_VALID = 'Please use a valid file name.';
const ERROR_FILE_SIZE = 'Import file too large. Must be less than 100MB';

/* eslint-enable max-len */
/** @type {CampaignCreateErrors} */
const initStateErrors = {
  campaignName: '',
  campaignDescription: '',
  campaignPurpose: '',
  otherPurposeDuplicated: '',
  otherPurposeEmpty: '',
  outreachChannel: '',
  otherChannelDuplicated: '',
  otherChannelEmpty: '',
  fileFirstColumnLength: '',
  fileHeaderFirstColumnName: '',
  fileHeaderSpecialCharacters: '',
  fileNameLength: '',
  fileNameNotValid: '',
  fileSize: '',
  otherErrors: '',
};
/** @param {CampaignCreateErrors} errors */
const isValid = (errors) => {
  return Object.values(errors).every((val) => val.length === 0);
};

/** @param {string []} errors */
const parseErrors = (errors) => errors.filter((err) => err);

/**
 * @param {Partial<CampaignModel>} state
 * @param {Partial<CampaignModel>} payload
 */
const handleFormReducer = (state, payload) => ({
  ...state,
  ...payload,
});

/**
 * @param {{
 *   onNext: (campaign: CampaignModel) => void
 *   onViewFilters: () => void
 * }} props
 */
const DataForm = ({ onNext, onViewFilters }) => {
  const dispatch = useAppDispatch();
  const campaign = useAppSelector((state) => state.campaignData.campaign);
  const openMode = useAppSelector((state) => state.campaignData.openMode);
  const readonly = openMode === OPEN_MODE.VIEW;

  useEffect(() => {
    setFormData({
      ...campaign,
    });
  }, [campaign]);

  const [formData, setFormData] = useReducer(handleFormReducer, {
    campaignName: campaign.campaignName,
    campaignDescription: campaign.campaignDescription,
    campaignPurpose: campaign.campaignPurpose,
    outreachChannel: campaign.outreachChannel,
    fileName: campaign.fileName,
  });

  /** @type {[CampaignCreateErrors, (errors: CampaignCreateErrors) => void ]} */
  const [errors, setErrors] = useState({ ...initStateErrors });

  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const openFileStatusPendingAlert = () => {
    setMessageAlert(
      'File upload is still pending, you cannot remove it right. ' +
        'Please try later',
    );
    setOpenAlert(true);
  };

  /** @type {UseStateOf<File>} */
  const [file, setFile] = useState();
  /**
   * @param {ChangeEvent<HTMLInputElement>} e
   * @param {string} fileName
   * @param {File} file
   */
  const fileChange = (e, fileName, file) => {
    setFormData({
      fileName: removeFileExtension(fileName),
    });
    fileReset(file);
  };

  /**
   * @param {File} file
   */
  const fileReset = (file) => {
    setFile(file);
    setErrors({
      ...errors,
      fileFirstColumnLength: '',
      fileNameLength: '',
      fileNameNotValid: '',
      fileSize: '',
      fileHeaderFirstColumnName: '',
      fileHeaderSpecialCharacters: '',
    });
  };

  const removeFile = (fileName) => {
    // check if the file is already attached to this campaign
    const found = campaign.details.sources.find((source) => {
      /* `fileName` might have the suffix [pending],
         `source.filename` might have the suffix `.csv`,
          this double check covers both cases */
      return (
        fileName.includes(source.filename) || source.filename.includes(fileName)
      );
    });

    if (found) {
      if (found.status === 'pending') {
        // file part of the campaign but upload still in progress
        openFileStatusPendingAlert();
      } else {
        // file part of the campaign, so it requests the WEB api to remove it
        dispatch(
          setRemoveFileAsync({ campaignId: campaign.campaignId, fileName }),
        );
        fileReset(null);
        setFormData({ fileName: '' });
      }
    } else {
      // file no uploaded yet, so it just cleans up the input text
      setFormData({ fileName: '' });
      fileReset(null);
    }
  };

  /**
   * @param {ChangeEvent<HTMLInputElement> | CheckBoxListEventChange} e
   * @param {CampaignCreateErrors} [prevErrors]
   */
  const checkErrors = (e, prevErrors = initStateErrors) => {
    const { name, value } = e.target;
    let newErrors = errors;

    setErrors({ ...prevErrors, [name]: '', otherErrors: '' });
    if (name === 'campaignName') {
      const campaignNameLength = String(value).trim().length;

      newErrors.campaignName = '';
      if (campaignNameLength < CAMPAIGN_NAME_MIN_LENGTH) {
        newErrors.campaignName = ERROR_CAMPAIGN_NAME_MIN_LENGTH;
      } else if (campaignNameLength > CAMPAIGN_NAME_MAX_LENGTH) {
        newErrors.campaignName = ERROR_CAMPAIGN_NAME_MAX_LENGTH;
      }
    } else if (name === 'campaignDescription') {
      newErrors.campaignDescription =
        String(value).trim().length < CAMPAIGN_DESC_MIN_LENGTH
          ? ERROR_CAMPAIGN_DESC
          : '';
    } else if (name === 'file') {
      const { csvFile } = /** @type {{ csvFile: ProcessedCsvFile }} */ (
        /** @type { unknown } */ (value)
      );
      if (csvFile && csvFile.errors.length > 0) {
        if (
          csvFile.errors.some(
            (err) => err.errorType === csvErrorType.FILE_FIRST_COLUMN_LENGTH,
          )
        ) {
          newErrors.fileFirstColumnLength = ERROR_FILE_FIRST_COLUMN_LENGTH;
        }
        if (
          csvFile.errors.some(
            (err) =>
              err.errorType === csvErrorType.FILE_HEADER_SPECIAL_CHARACTERS,
          )
        ) {
          newErrors.fileHeaderSpecialCharacters =
            ERROR_FILE_HEADER_SPECIAL_CHARACTERS;
        }

        if (
          csvFile.errors.some(
            (err) =>
              err.errorType === csvErrorType.FILE_HEADER_FIRST_COLUMN_NAME,
          )
        ) {
          newErrors.fileHeaderFirstColumnName =
            ERROR_FILE_HEADER_FIRST_COLUMN_NAME;
        }

        if (
          csvFile.errors.some(
            (err) => err.errorType === csvErrorType.FILE_NAME_LENGTH,
          )
        ) {
          newErrors.fileNameLength = ERROR_FILE_NAME_LENGTH;
        }

        if (
          csvFile.errors.some(
            (err) => err.errorType === csvErrorType.FILE_NAME_NOT_VALID,
          )
        ) {
          newErrors.fileNameNotValid = ERROR_FILE_NAME_NOT_VALID;
        }

        if (
          csvFile.errors.some((err) => err.errorType === csvErrorType.FILE_SIZE)
        ) {
          newErrors.fileSize = ERROR_FILE_SIZE;
        }
      }
    } else if (name === 'campaignPurpose') {
      const { duplicatedItem, other, selectedItems } =
        /** @type {CheckBoxListValue} */ (value);
      newErrors.otherPurposeDuplicated = duplicatedItem
        ? ERROR_OTHER_PURPOSE_DUPLICATED
        : '';

      newErrors.otherPurposeEmpty =
        !other?.value && other?.checked ? ERROR_OTHER_PURPOSE_EMPTY : '';

      newErrors.campaignPurpose =
        (!other?.value && other?.checked) ||
        (selectedItems.length === 0 && !other?.checked)
          ? ERROR_CAMPAIGN_PURPOSE
          : '';
    } else if (name === 'outreachChannel') {
      const { duplicatedItem, other, selectedItems } =
        /** @type {CheckBoxListValue} */ (value);
      newErrors.otherChannelDuplicated = duplicatedItem
        ? ERROR_OTHER_CHANNEL_DUPLICATED
        : '';

      newErrors.otherChannelEmpty =
        !other?.value && other?.checked ? ERROR_OTHER_CHANNEL_EMPTY : '';

      newErrors.outreachChannel =
        (!other?.value && other?.checked) ||
        (selectedItems.length === 0 && !other?.checked)
          ? ERROR_OUTREACH_CHANNEL
          : '';
    }

    setErrors({ ...prevErrors, ...newErrors });

    return newErrors;
  };

  /**
   * @param {ChangeEvent<HTMLInputElement> & CheckBoxListEventChange} e
   */
  const inputChange = (e) => {
    const { name, value } = e.target;
    checkErrors(e, errors);
    /** @type {any} */
    let newValue = value;

    if (name === 'campaignPurpose') {
      const { other, selectedItems, duplicatedItem } = value;

      newValue = {
        purposes: selectedItems,
        other,
        duplicatedItem,
      };
    } else if (name === 'outreachChannel') {
      const { other, selectedItems, duplicatedItem } = value;

      newValue = {
        channels: selectedItems,
        other,
        duplicatedItem,
      };
    }

    setFormData({ [name]: newValue });
  };

  /**
   * @param {ProcessedCsvFile} csvFile
   */
  function hasMandatoryFields(csvFile) {
    let newErrors = { ...errors };

    const inputNames = {
      campaignName: 'campaign-name',
      campaignDescription: 'campaign-description',
      file: 'file-upload',
      campaignPurpose: 'campaign-purpose',
      outreachChannel: 'outreach-channel',
    };

    let firstInvalidElement;

    /** @param {string} id   */
    const setFirstInvalidElement = (id) => {
      if (!firstInvalidElement) {
        firstInvalidElement = `#${id}-field`;
      }
    };

    Object.entries(inputNames).forEach(([inputName, inputId]) => {
      let value = formData[inputName];

      if (inputName === 'campaignPurpose') {
        value = {
          ...formData.campaignPurpose,
          selectedItems: formData.campaignPurpose.purposes,
        };
      } else if (inputName === 'outreachChannel') {
        value = {
          ...formData.outreachChannel,
          selectedItems: formData.outreachChannel.channels,
        };
      } else if (inputName === 'file') {
        value = {
          csvFile,
        };
      }

      const targetToCheck =
        /** @type {ChangeEvent<HTMLInputElement>} */
        ({
          target: {
            name: inputName,
            value,
          },
        });
      newErrors = checkErrors(targetToCheck, newErrors);

      if (newErrors[inputName]) {
        setFirstInvalidElement(inputId);
      }
    });

    if (firstInvalidElement) {
      scrollToElement(firstInvalidElement, true);
    }

    return isValid(newErrors);
  }

  const createFilters = async (e) => {
    e.preventDefault();

    dispatch(setPageLoading(true));
    const csvFile = file ? await processCsvFile(file) : null;
    const hasValidFields = hasMandatoryFields(csvFile);
    dispatch(setPageLoading(false));

    if (!hasValidFields) {
      return;
    }

    const campaignData = /** @type {CampaignModel} */ ({
      ...campaign,
      ...formData,
      csvFile,
    });

    onNext(campaignData);
  };

  const viewFilters = async (e) => {
    e.preventDefault();
    onViewFilters();
  };

  const classFirstCol = 'col-md-4';
  const classSecondCol = 'col-md-2';

  return (
    <form
      className="uds-form"
      noValidate
      method="post"
      onSubmit={(event) => event.preventDefault()}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          event.preventDefault();
          return false;
        }
      }}
      onChange={() => debounceStoreDraft(dispatch, formData)}
    >
      <div className="container p-0">
        <header className={`${classFirstCol} mb-3`}>
          <label>
            <RequiredIcon />
            <span className="font-weight-normal">Required fields</span>
          </label>
        </header>
        <section className={classFirstCol}>
          <CampaignName
            campaignName={formData.campaignName}
            errors={parseErrors([errors.campaignName])}
            readonly={readonly}
            onChange={inputChange}
          />
        </section>
        <section className={classFirstCol}>
          <CampaignDescription
            campaignDescription={formData.campaignDescription}
            errors={parseErrors([errors.campaignDescription])}
            readonly={readonly}
            onChange={inputChange}
          />
        </section>
        <section className="col">
          <FileUploader
            fileName={formData.fileName || ''}
            errors={parseErrors([
              errors.fileFirstColumnLength,
              errors.fileHeaderFirstColumnName,
              errors.fileHeaderSpecialCharacters,
              errors.fileNameLength,
              errors.fileNameNotValid,
              errors.fileSize,
            ])}
            readonly={readonly}
            outputTextClass={classFirstCol}
            chooseButtonClass={classSecondCol}
            onChange={fileChange}
            onRemoveFile={removeFile}
          />
        </section>
        <section className={classFirstCol}>
          <CampaignPurpose
            campaignPurpose={formData.campaignPurpose}
            errors={parseErrors([
              errors.campaignPurpose,
              errors.otherPurposeDuplicated,
              errors.otherPurposeEmpty,
            ])}
            readonly={readonly}
            onChange={inputChange}
          />
        </section>
        <section className={classFirstCol}>
          <OutreachChannel
            outreachChannel={formData.outreachChannel}
            errors={parseErrors([
              errors.outreachChannel,
              errors.otherChannelDuplicated,
              errors.otherChannelEmpty,
            ])}
            readonly={readonly}
            onChange={inputChange}
          />
        </section>
        <footer className={`${classFirstCol}  mt-2`}>
          {campaign?.published || readonly ? (
            <button
              data-testid="btn-view-filter"
              type="button"
              className="btn btn-md btn-maroon"
              onClick={viewFilters}
            >
              View Filters
            </button>
          ) : (
            <button
              data-testid="btn-create-filter"
              type="button"
              className="btn btn-md btn-maroon"
              onClick={createFilters}
            >
              Continue
            </button>
          )}
        </footer>
      </div>
      <Dialog
        title="Campaign Builder"
        message={messageAlert}
        open={openAlert}
        okButtonText="Got it"
        onOkButtonClick={() => setOpenAlert(false)}
      />
    </form>
  );
};

export { DataForm };
