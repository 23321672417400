// @ts-check

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// core
import { routerUrl } from 'core';
import { AuthContext } from 'core/auth';

const Button = styled.button`
  border: 0;
  background: transparent;
  color: #8c1d40;
  font-weight: bold;
`;

const Message = styled.p`
  max-width: 50ch;
  margin: 0 auto;
  color: #637381;
`;

const LogOut = () => {
  const history = useHistory();
  const { loginWithRedirect } = useContext(AuthContext);

  const login = () => {
    history.push(routerUrl.HOME);
    loginWithRedirect();
  };

  return (
    <section className="container text-center">
      <h1 className="d-inline-block">You have been signed out.</h1>
      <Message className="text">
        To access to the Campaign Builder{' '}
        <Button onClick={login}>Sign In</Button> to My ASU.
      </Message>
    </section>
  );
};

export { LogOut };
