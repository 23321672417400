// @ts-check

import { SPLIT_CSV_VALUE_WITH_QUOTE } from './csv-utils';
import { logger } from './logger-utils';

function containsSpecialChars(str) {
  // eslint-disable-next-line no-useless-escape
  const specialChars = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

const FILE_NAME_MAX_LENGTH = 30;
const FILE_MAX_SIZE = 100 * 1024 ** 2; // 100MB
const csvErrorType = {
  FILE_FIRST_COLUMN_LENGTH: 1,
  FILE_HEADER_FIRST_COLUMN_NAME: 2,
  FILE_HEADER_SPECIAL_CHARACTERS: 3,
  FILE_NAME_NOT_VALID: 4,
  FILE_NAME_LENGTH: 5,
  FILE_SIZE: 6,
};

/**
 * @param {{
 *  fileNameNoExt: string
 *  headers: string[]
 *  rows: string[]
 *  fileSize: number
 * }} props
 */
function validateCsvFile({ fileNameNoExt, headers, rows, fileSize }) {
  /** @type {FileError []} */
  const listErrors = [];
  logger.group('CSV validation');

  // =======================================================
  // Validate file size
  // =======================================================
  if (fileSize > FILE_MAX_SIZE) {
    listErrors.push({
      // Import file too large.  Must be less than 100MB
      reason: `Expect the file size to be less than ${FILE_NAME_MAX_LENGTH}MB`,
      errorType: csvErrorType.FILE_SIZE,
    });
  }

  // =======================================================
  // Validate filename
  // =======================================================
  const FILENAME_REGEX = /^[a-zA-Z0-9-_]+$/;
  if (fileNameNoExt.search(FILENAME_REGEX) === -1) {
    listErrors.push({
      reason: `Expect the file name '${fileNameNoExt}' to be valid`,
      errorType: csvErrorType.FILE_NAME_NOT_VALID,
    });
  }

  if (fileNameNoExt.length > FILE_NAME_MAX_LENGTH) {
    listErrors.push({
      reason: `Expect the file name '${fileNameNoExt}' length to be less than ${FILE_NAME_MAX_LENGTH} characters`,
      errorType: csvErrorType.FILE_NAME_LENGTH,
    });
  }

  // =======================================================
  // 1st row must be column headers with no special characters
  // =======================================================
  for (let i = 0; i < headers.length; i++) {
    const header = headers[i];
    if (containsSpecialChars(header)) {
      listErrors.push({
        reason: `Expect the column header '${header}' to not contain any special characters'`,
        errorType: csvErrorType.FILE_HEADER_SPECIAL_CHARACTERS,
      });
    }
  }
  // =======================================================
  // 1st column header must be named "student_id"
  // =======================================================
  const STUDENT_COL = 'student_id';
  const firstHeader = headers[0]?.trim();
  if (firstHeader !== STUDENT_COL) {
    listErrors.push({
      reason: `Expect the first column header to be 'student_id' but it got '${firstHeader}'`,
      errorType: csvErrorType.FILE_HEADER_FIRST_COLUMN_NAME,
    });
  }
  // =======================================================
  // 1st column data must be  10 digits (e.g., 1000768561)
  // =======================================================
  const TEN_DIGITS_REGEX = /^[0-9]{10}$/;
  const totalRows = rows.length;
  for (let i = 0; i < totalRows; i++) {
    const row = rows[i];

    if (row === '') {
      console.warn('Last row  is empty');
      continue;
    }
    /** @type {string[]} */
    const cells = row.split(SPLIT_CSV_VALUE_WITH_QUOTE);
    const firstCell = cells[0]?.trim();

    if (!TEN_DIGITS_REGEX.test(firstCell)) {
      listErrors.push({
        reason:
          `Expect the row[${i}][0] value '${firstCell}' to be 10 digits length` +
          ` but it got '${String(firstCell).length}'`,
        errorType: csvErrorType.FILE_FIRST_COLUMN_LENGTH,
      });
    }
  }

  console.log(`The CSV file has ${listErrors.length} errors.`, listErrors);
  console.groupEnd();

  return listErrors;
}

/**
 * @param {File} file
 * @returns {Promise<ProcessedCsvFile>}
 */
async function processCsvFile(file, delimiter = ',') {
  const inputString = await file.text();
  const fileNameNoExt = file.name.replace('.csv', '');
  const fileSize = file.size;
  // =======================================================
  // slice from start of text to the first \n index
  // =======================================================
  const headers = inputString
    .slice(0, inputString.indexOf('\n'))
    .split(delimiter)
    .map((header) => header.trim());
  // =======================================================
  // slice from \n index + 1 to the end of the text
  // =======================================================
  const endLine = inputString.indexOf('\n') + 1;
  const rows = inputString
    .slice(endLine)
    .split('\n')
    .map((header) => header.trim());
  // =======================================================
  // Validate headers and data
  // =======================================================
  const errors = validateCsvFile({
    fileNameNoExt,
    headers,
    rows,
    fileSize,
  });

  return {
    fileNameNoExt,
    headers,
    rows,
    errors,
  };
}
/** @param {string} filename */
function validateCsvFileName(filename) {
  const regex = /^[a-zA-Z0-9-_]+$/;
  const fileNameNoExt = filename.substring(0, filename.length - 4);

  return fileNameNoExt.search(regex) > -1;
}

export { processCsvFile, validateCsvFile, validateCsvFileName, csvErrorType };
