// @ts-check

/**
 * @type {AuthState}
 */
export const initialAuthState = {
  isAuthenticated: false,
  error: undefined,
  user: undefined,
  isLoading: true,
  userRoles: undefined,
  errorBlocker: false,
};
