// @ts-check
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

// Core.
import { appRoles, AuthContext } from 'core';
import { ButtonLink } from 'core/components/QueryBuilder/components/QueryFilterBuilder/index.style';
import { useAppDispatch } from 'core/store';
import { getCampaignListAsync } from 'core/store/slices';

// Local components.
import { CampaignName } from './components/CampaignName';
import { CreatedBy } from './components/CreatedBy';
import { SearchDate } from './components/SearchDate';
import { SearchDropdown } from './components/SearchDropdown';

const Container = styled.header`
  flex: 1 0 10%;
  justify-content: space-between;
  margin-bottom: 1rem;
  h2,
  h3,
  h4 {
    margin: 0;
  }
`;

/** @type {OptionsOrGroups} */
const purposeCombinators = [
  { value: 'engagement', label: 'Engagement' },
  { value: 'informational', label: 'Informational' },
  { value: 'persistenceRetention', label: 'Persistence/Retention' },
  { value: 'support', label: 'Support' },
  { value: 'other-purpose', label: 'Other' },
];

/** @type {OptionsOrGroups} */
const outreachCombinators = [
  { value: 'asuSunnyChatbot', label: 'ASU Sunny Chatbot' },
  { value: 'asuMobileApp', label: 'ASU Mobile App' },
  { value: 'directOutreach', label: 'Direct Outreach' },
  {
    value: 'entSFAdvisorPortal',
    label: 'Enterprise Salesforce Advisor Portal',
  },
  { value: 'entSFCoachPortal', label: 'Enterprise Salesforce Coach Portal' },
  {
    value: 'entSFMarketingCloud',
    label: 'Enterprise Salesforce Marketing Cloud',
  },
  {
    value: 'entSFServicePortal',
    label: 'Enterprise Salesforce Service Portal',
  },
  { value: 'other-channel', label: 'Other' },
];

const initialFormData = {
  nameQuery: '',
  createdFrom: '',
  createdTo: '',
  createdBy: '',
  purposes: [],
  outreachChannels: [],
};

const SearchForm = () => {
  const { userRoles } = useContext(AuthContext);
  const [formData, setFormData] = useState(initialFormData);
  const dispatch = useAppDispatch();
  const [hideShowFilters, setHideShowFilters] = useState(true);

  const doSearch = async (e) => {
    e.preventDefault();

    // Set all=true if current user has Admin role.
    // Only Admin users can see (search) all campaigns.
    formData.all = userRoles.includes(appRoles.ADMIN);

    dispatch(getCampaignListAsync({ searchFilter: formData }));
  };

  // Map the value to the corresponding name (label).
  // Example, if Purpose "Engagement" is selected, then the dropdown control will display "Engagement" as being chosen.
  const getNameForValue = (value, combinator) => {
    switch (combinator) {
      case 'outreach':
        return outreachCombinators.find((option) => option.value === value)
          ?.label;
      case 'purposes':
        return purposeCombinators.find((option) => option.value === value)
          ?.label;
      default:
        return '';
    }
  };

  // Map the value in campaignPurpose or outreachChannel to an array of { value, label } objects,
  // which is the format that react-select expects for its value prop.
  const getValueOption = (values, combinator) => {
    return values.map((value) => ({
      value,
      label: getNameForValue(value, combinator),
    }));
  };

  const handleDateChange = (date, element) => {
    setFormData((prevState) => ({ ...prevState, [element]: date }));
  };

  const handleSelectChange = (selectedOptions, element) => {
    // selectedOptions is an array of { value, label } objects.
    // Extraxt the selected value(s) and to the formData state object.
    const selectedValues = selectedOptions.map((option) => option.value);

    // Add the selected value(s) to the formData state object.
    // Use the element name as the name of the key.
    setFormData((prevState) => ({
      ...prevState,
      [element.name]: selectedValues,
    }));
  };

  const handleTextInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Clear any existing choices in the campaign search form.
  const resetSearchForm = (e) => {
    e.preventDefault();

    formData.createdBy = '';
    formData.createdFrom = '';
    formData.createdTo = '';
    formData.nameQuery = '';
    formData.outreachChannels = [];
    formData.purposes = [];

    dispatch(getCampaignListAsync({ searchFilter: formData }));
  };

  // Show or hide the campaign search section.
  const toggleFilters = (e) => {
    if (e.type === 'keydown' && e.key === 'Enter') {
      e.preventDefault();
      return;
    }

    setHideShowFilters(!hideShowFilters);
  };

  return (
    <form className="uds-form" noValidate method="post">
      <section>
        <header>
          <ButtonLink
            data-testid="btn-toggle-filters"
            className="text-maroon"
            type="button"
            onClick={toggleFilters}
            onKeyDown={toggleFilters}
          >
            {hideShowFilters ? 'Hide filters' : 'Show filters'}
          </ButtonLink>
        </header>
        {hideShowFilters && (
          <div style={{ display: 'flex', flex: 'wrap', flexDirection: 'row' }}>
            <Container>
              <section>
                <div className="row text-nowrap">
                  <div className="col pr-3">
                    <CampaignName
                      nameQuery={formData.nameQuery}
                      onChange={handleTextInputChange}
                    />
                  </div>
                  <div className="col pr-3">
                    <SearchDate
                      dateId="createdFrom"
                      dateName="createdFrom"
                      searchDate={formData.createdFrom}
                      dateTitle="Date Created From"
                      tooltip="Campaigns created on or after this date will be included in the search results."
                      onChange={(date) => handleDateChange(date, 'createdFrom')}
                    />
                  </div>
                  <div className="col pr-3">
                    <SearchDate
                      dateId="createdTo"
                      dateName="createdTo"
                      searchDate={formData.createdTo}
                      dateTitle="Date Created To"
                      tooltip="Campaigns created on or before this date will be included in the search results."
                      onChange={(date) => handleDateChange(date, 'createdTo')}
                    />
                  </div>
                  {userRoles.includes(appRoles.ADMIN) && (
                    <div className="col">
                      <CreatedBy
                        createdBy={formData.createdBy}
                        onChange={handleTextInputChange}
                      />
                    </div>
                  )}
                </div>

                <div className="row text-nowrap">
                  <div className="col pr-3">
                    <SearchDropdown
                      id="purposes"
                      label="Campaign Purpose"
                      name="purposes"
                      placeholder="Select purpose"
                      testId="campaign-purpose"
                      options={purposeCombinators}
                      value={getValueOption(formData.purposes, 'purposes')}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="col">
                    <SearchDropdown
                      id="outreachChannels"
                      label="Outreach Channels"
                      name="outreachChannels"
                      placeholder="Select channel"
                      testId="outreach-channel"
                      options={outreachCombinators}
                      value={getValueOption(
                        formData.outreachChannels,
                        'outreach',
                      )}
                      onChange={handleSelectChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex justify-content-between">
                    <div>
                      <button
                        className="btn btn-maroon"
                        type="submit"
                        aria-describedby="search-campaigns-tooltip"
                        onClick={doSearch}
                      >
                        <i className="fa-solid fa-magnifying-glass" />
                        &nbsp; Search Campaigns
                      </button>
                    </div>
                    <div>
                      <ButtonLink
                        data-testid="btn-clear-all-filters"
                        className="text-maroon"
                        onClick={resetSearchForm}
                      >
                        Clear all filters
                      </ButtonLink>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      className="btn btn-maroon"
                      type="button"
                      aria-describedby="export-campaigns-tooltip"
                      onClick={null}
                    >
                      <i className="fa-solid fa-file-export" />
                      &nbsp; Export Results
                    </button>
                  </div>
                </div> */}
              </section>
            </Container>
          </div>
        )}
      </section>
    </form>
  );
};

export { SearchForm };
