// @ts-check
import React from 'react';

/**
 * @param {{
 *  errors: string []
 *  isOpen: boolean
 *  onClose: () => void
 * }} props
 */
function ErrorAlert({ errors, isOpen = false, onClose = () => null }) {
  return isOpen ? (
    <div className="row">
      <div className="col-md-12">
        <div className="alert align-items-center alert-danger" role="alert">
          <div className="alert-icon">
            <i className="fa fa-icon fa-exclamation-triangle" />
          </div>
          <div className="alert-content">
            {errors.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </div>

          <div className="alert-close">
            <button
              type="button"
              className="btn btn-circle btn-circle-alt-black close"
              aria-label="Close"
              data-dismiss="alert"
              onClick={onClose}
            >
              <i className="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export { ErrorAlert };
