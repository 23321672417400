// @ts-check
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

// core
import { OPEN_MODE, PAGE_STEP, resolveRouteWithId, routerUrl } from 'core';

const StyledLink = styled(Link).attrs((props) => ({
  disabled: props.disabled || false,
}))`
  ${(props) =>
    props.disabled
      ? `
        --disabled-color: #191919;
        color: var(--disabled-color)! important;
        text-decoration: none;
        &:hover {
          color: var(--disabled-color)! important;
        }
      `
      : ''}
`;

/**
 * @param {{
 *  testId?: string
 *  currentPage: PageSteps
 *  openMode: OpenMode
 *  onRouteChange?: (route: string) => void
 * }} props
 */
function Breadcrumb({
  testId = 'breadcrumb',
  currentPage,
  openMode,
  onRouteChange,
}) {
  /** @type {{ id: string }} */
  let { id } = useParams();

  const metadataPageTitle = {
    [OPEN_MODE.VIEW]: 'View campaign',
    [OPEN_MODE.UPDATE]: 'Update campaign',
    [OPEN_MODE.CREATE]: 'Create campaign',
  };

  const basicDataLink = {
    [OPEN_MODE.VIEW]: resolveRouteWithId(routerUrl.CAMPAIGN_VIEW_DATA, id),
    [OPEN_MODE.UPDATE]: resolveRouteWithId(routerUrl.CAMPAIGN_UPDATE_DATA, id),
    [OPEN_MODE.CREATE]: routerUrl.CAMPAIGN_CREATE_DATA,
  };

  const defineFiltersLink = {
    [OPEN_MODE.VIEW]: resolveRouteWithId(routerUrl.CAMPAIGN_VIEW_FILTER, id),
    [OPEN_MODE.UPDATE]: resolveRouteWithId(
      routerUrl.CAMPAIGN_UPDATE_FILTER,
      id,
    ),
    [OPEN_MODE.CREATE]: routerUrl.CAMPAIGN_DEFINE_FILTER,
  };

  const detailLink = resolveRouteWithId(routerUrl.CAMPAIGN_DETAILS, id);

  /**
   * @type {{
   *  key: string,
   *  text: string,
   *  link: string,
   * }[]}
   * */
  const breadcrumbs = [
    {
      key: PAGE_STEP.CAMPAIGN_DATA,
      text: metadataPageTitle[openMode],
      link: basicDataLink[openMode],
    },
    {
      key: PAGE_STEP.CAMPAIGN_FILTER,
      text: 'Define filters',
      link: defineFiltersLink[openMode],
    },
  ];

  if (openMode === OPEN_MODE.VIEW) {
    breadcrumbs.push({
      key: PAGE_STEP.CAMPAIGN_DETAILS,
      text: 'Campaign details',
      link: detailLink,
    });
  }

  return (
    <section>
      <div className="row">
        <div className="col-12">
          <nav aria-label="breadcrumbs" data-testid={testId}>
            <ol className="breadcrumb bg-white">
              {breadcrumbs.map((bread, index) => (
                <li
                  data-testid={`${testId}-item-${index}`}
                  key={bread.key}
                  className={`breadcrumb-item ${
                    currentPage === bread.key ? 'active' : ''
                  }`}
                  onClick={() => onRouteChange?.(bread.link)}
                >
                  <StyledLink
                    data-testid={`${testId}-link-${index}`}
                    disabled={currentPage === bread.key}
                    to={bread.link}
                  >
                    {bread.text}
                  </StyledLink>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
}

export { Breadcrumb };
