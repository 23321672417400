// @ts-check

import React, { useRef, useState } from 'react';
import styled from 'styled-components';

// core
import { Dialog, ErrorValidation, Tooltip } from 'core/components';

const ChooseButton = styled.button`
  transition: background-color 700ms cubic-bezier(0.39, 0.58, 0.57, 1);
  &:disabled {
    pointer-events: none;
  }
`;

/**
 * @param {{
 *  fileName: string
 *  onChange: (
 *    e:ChangeEvent<HTMLInputElement>,
 *    filename: string,
 *    file:File,
 *  ) => void
 *  onRemoveFile: (fileName: string) => void,
 *  readonly: boolean
 *  errors?: string []
 *  outputTextClass?: string
 *  chooseButtonClass?: string
 * }} props
 */
function FileUploader({
  fileName = 'No file chosen',
  onChange,
  onRemoveFile,
  errors,
  readonly,
  outputTextClass = 'col-md-4',
  chooseButtonClass = 'col-md-2',
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const hasChanged = fileName ? true : false;

  /** @type {LegacyRef<HTMLInputElement>} */
  const inputFileRef = useRef(null);

  /** @param {ChangeEvent<HTMLInputElement>} e */
  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const chosenFileName = file.name;
      onChange(e, chosenFileName, file);
    }
  };

  // Set the file's path to null every time user clicks on the button.
  // This is necessary if user selects the same file name twice in a row, onChange is not fired.
  const resetFilePath = (e) => {
    //e.target.value = '' would probably also work.
    e.target.value = null;
  };

  const chooseFile = (e) => {
    e.preventDefault();
    inputFileRef?.current.click();
  };

  const requestRemoveFile = (e) => {
    e.preventDefault();
    setOpenConfirm(true);
  };
  const closeConfirm = () => setOpenConfirm(false);

  const removeFile = (e) => {
    setOpenConfirm(false);
    onRemoveFile(fileName);
  };

  return (
    <div
      className="form-group"
      id="file-upload-field"
      data-testid="file-upload-field"
    >
      <label htmlFor="file-upload" className="custom-file-upload">
        <i /> Choose File{' '}
      </label>
      <Tooltip tooltipId="custom-file-upload-tooltip">
        This provides the option to upload a CSV file to supplement your
        campaign. Only one file can be uploaded per campaign. No spaces are
        allowed in the file name or column headers. The file name is limited to
        30 characters. First column should be: student_id
      </Tooltip>
      <div className="row">
        <div className={outputTextClass}>
          <input
            className={`form-control form-control-sm mr-2 ${
              errors.length > 0 ? 'is-invalid' : ''
            }`}
            value={fileName}
            readOnly
            disabled
          />
        </div>
        <div className={chooseButtonClass}>
          <input
            ref={inputFileRef}
            data-testid="file"
            id="file"
            type="file"
            accept=".csv"
            className="d-none"
            onChange={fileChange}
            onClick={resetFilePath}
          />

          <ChooseButton
            data-testid="btn-choose-remove"
            type="button"
            className={`btn btn-md ${hasChanged ? 'btn-gold' : 'btn-maroon'}`}
            tabIndex={0}
            disabled={readonly}
            onClick={hasChanged ? requestRemoveFile : chooseFile}
          >
            {hasChanged ? 'Remove file' : 'Choose file'}
          </ChooseButton>
        </div>
      </div>
      <ErrorValidation errors={errors} />
      <Dialog
        testId="remove-file-dialog"
        title="Remove file"
        message="Do you want to remove this file?"
        open={openConfirm}
        cancelButtonText="Cancel"
        cancelButtonDisplay
        okButtonText="Confirm"
        onCancelButtonClick={closeConfirm}
        onOkButtonClick={removeFile}
      />
    </div>
  );
}

export { FileUploader };
