// @ts-check

/**
 * @param {string} fileUrl
 * @param {string} filename
 */
async function downloadFile(fileUrl, filename) {
  console.log(`Download START for file ${filename}`);
  const res = await fetch(fileUrl);
  const blob = await res.blob();

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.target = '_blank';
  link.download = filename;

  link.click();
  console.log('Download END');
}

export { downloadFile };
