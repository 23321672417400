import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { getActiveAnnouncements } from 'core/services';
import { setHttpError } from './sharedSlice';

/** @type { AnnouncementActiveState} */
const initialState = {
  announcements: [],
  closedAnnouncements:
    JSON.parse(localStorage.getItem('closedAnnouncements')) || [],
  loading: false,
};

export const selectActiveAnnouncements = (state) =>
  state.announcementActive.announcements;
export const selectClosedAnnouncements = (state) =>
  state.announcementActive.closedAnnouncements;

export const selectMostRecentOpenAnnouncement = createSelector(
  [selectActiveAnnouncements, selectClosedAnnouncements],
  (announcements, closedAnnouncements) => {
    const openAnnouncements = announcements.filter(
      (announcement) =>
        !isAnnouncementClosed(announcement, closedAnnouncements),
    );
    return getMostRecentAnnouncement(openAnnouncements);
  },
);

const isAnnouncementClosed = (announcement, closedAnnouncements) => {
  // Check if the announcement is present in the closed announcements list
  return closedAnnouncements.some((closedAnnouncement) =>
    areAnnouncementsEquivalent(closedAnnouncement, announcement),
  );
};

const areAnnouncementsEquivalent = (announcement1, announcement2) => {
  return (
    announcement1.announcement_id === announcement2.announcement_id &&
    announcement1.updated_at === announcement2.updated_at
  );
};

const getMostRecentAnnouncement = (announcements) => {
  // Sort the announcements based on the updated_at field in descending order
  const sortedAnnouncements = announcements
    .slice()
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  // Return the most recent announcement (first in the sorted list)
  return sortedAnnouncements[0];
};

export const announcementActiveSlice = createSlice({
  name: 'announcementActive',
  initialState,
  reducers: {
    setActiveAnnouncementsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setActiveAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
    closeAnnouncment: (state, action) => {
      state.closedAnnouncements.push(action.payload);
      localStorage.setItem(
        'closedAnnouncements',
        JSON.stringify(state.closedAnnouncements),
      );
    },
  },
});

export const getActiveAnnouncementsAsync = createAsyncThunk(
  'announcementActive/getActiveAnnouncementsAsync',
  /** @param {AnnouncementResponse} payload */
  async (payload, { dispatch }) => {
    dispatch(setActiveAnnouncementsLoading(true));
    dispatch(setActiveAnnouncements([]));
    try {
      const { announcements } = await getActiveAnnouncements();
      if (announcements) {
        dispatch(setActiveAnnouncements(announcements));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getActiveAnnouncementsAsync.typePrefix,
        }),
      );
    }
    dispatch(setActiveAnnouncementsLoading(false));
  },
);

export const {
  setActiveAnnouncementsLoading,
  setActiveAnnouncements,
  closeAnnouncment,
} = announcementActiveSlice.actions;

export default announcementActiveSlice.reducer;
