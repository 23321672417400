// @ts-check

import React from 'react';

const RequiredIcon = ({ className = '' }) => (
  <i
    title="Required"
    className={`fa fa-icon fa-circle uds-field-required ${className}`}
  />
);

const ArrowLeftIcon = ({ className = '' }) => (
  <i title="Previous" className={`fa-solid fa-angle-left ${className}`} />
);

const ArrowRightIcon = ({ className = '' }) => (
  <i title="Next" className={`fa-solid fa-angle-right ${className}`} />
);

export { RequiredIcon, ArrowLeftIcon, ArrowRightIcon };
