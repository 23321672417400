/* eslint-disable react/jsx-props-no-spreading */
// @ts-check

import React, { useState } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Dropdown } from './Dropdown';

const Container = styled.section`
  [class*='Group']:last-of-type .custom-group-heading {
    border-bottom-width: 0;
  }

  .custom-group-heading {
    // border-bottom: 1px solid #d3d3d3;

    // =========================
    // Options container
    // =========================
    & + div {
      margin-left: 25px;
      transition: all 0.3s ease-in-out;
      width: 320px;
      z-index: 1;
    }
    &:not(collapsed) + div {
      border-bottom: 1px solid #d3d3d3;
    }
    &.collapsed + div {
      display: none;
    }
    // =========================
    .icon {
      transition-duration: 0.4s;
    }
    &.collapsed .icon {
      transform: rotate(180deg);
    }
    // =========================

    &:hover {
      background-color: #e8e8e8;
    }
  }
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 0.5rem;

  color: rgb(25, 25, 25);
  font-size: 1rem;
  font-family: Arial;
  text-transform: none;
  font-weight: normal;

  transition: font-weight 0.2s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const formatGroupLabel = (data) => {
  return (
    <GroupHeader>
      <span>{data.label}</span>
      <span>
        <i className="fas fa-chevron-left icon" />
      </span>
    </GroupHeader>
  );
};

const CustomGroupHeading = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div
      className={'custom-group-heading ' + (collapsed ? 'collapsed' : '')}
      onClick={() => {
        setCollapsed(!collapsed);
      }}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const FaListIcon = () => {
  return <FontAwesomeIcon icon="list" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaListIcon />
    </components.DropdownIndicator>
  );
};

/**
 * @param {Parameters<Select>[0]} props
 */
const GroupDropdown = ({ options, ...props }) => {
  // In the context of QueryBuilder, the "options" property is the full list of top-level groups and
  // their subcategories. props is "name" and "value", for example, when Demographics \ Asian incl is selected then
  // props = {name: 'field', value: 'asian_incl'}.
  return (
    <Container>
      <Dropdown
        options={options}
        blurInputOnSelect={false}
        components={{
          GroupHeading: CustomGroupHeading,
          DropdownIndicator,
        }}
        formatGroupLabel={(group) => formatGroupLabel(group)}
        {...props}
      />
      {/* <Select
        options={options}
        isMulti
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        components={{
          GroupHeading: CustomGroupHeading,
          Menu: (props) => <components.Menu {...props} className="menu" />,
        }}
        // menuIsOpen
        formatGroupLabel={(group) => formatGroupLabel(group)}
        styles={{
          input: (provided) => ({
            ...provided,
            'input:focus': {
              boxShadow: 'none !important',
            },
          }),
          group: (provided) => ({
            ...provided,
            paddingBlock: 0,
          }),
        }}
        {...props}
      /> */}
    </Container>
  );
};

export { GroupDropdown };
