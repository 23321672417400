// @ts-check
import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  pre {
    border: 1px solid #dcdbdb;
    border-radius: 4px;
    padding: 1rem;

    display: inline-block;
    overflow: hidden;
    width: 100%;

    font-size: small;
    white-space: break-spaces;
    background-color: #fff;
  }
  code {
    white-space: pre-wrap;
    display: inline-block;
    width: 100%;
  }
`;

const ButtonLink = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: inherit;
  text-decoration: underline;
`;

/**
 * @param {PropsWithChildren<{}>} props
 */
const CodeTemplate = ({ children }) => (
  <code>
    <pre>{children}</pre>
  </code>
);
/**
 * @param {{
 *  query: QueryFilterValue,
 *  showStyledHtmlPLainQuery?: boolean,
 *  showSqlQuery?: boolean,
 *  showStyledHtmlQuery?: boolean,
 *  showJsonStringQuery?: boolean,
 *  showJsonQuery?: boolean,
 * }} props
 */
function QueryFilterPreview({
  query = {},
  showStyledHtmlPLainQuery = true,
  showSqlQuery = false,
  showStyledHtmlQuery = false,
  showJsonStringQuery = false,
  showJsonQuery = false,
}) {
  const {
    sqlQuery,
    styledHtmlPLainQuery,
    styledHtmlQuery,
    jsonStringQuery,
    jsonQuery,
  } = query;

  const [open, setOpen] = useState(true);
  return (
    <Container className="container p-0">
      <header className="mb-2">
        <ButtonLink className="text-maroon" onClick={() => setOpen(!open)}>
          {open ? 'Hide Logic' : 'Show Logic'}
        </ButtonLink>
      </header>
      {open ? (
        <>
          {showStyledHtmlPLainQuery && styledHtmlPLainQuery && (
            <CodeTemplate>
              <span
                dangerouslySetInnerHTML={{ __html: styledHtmlPLainQuery }}
              />
            </CodeTemplate>
          )}
          {showSqlQuery && sqlQuery && <CodeTemplate>{sqlQuery}</CodeTemplate>}
          {showStyledHtmlQuery && styledHtmlQuery && (
            <CodeTemplate>
              <span dangerouslySetInnerHTML={{ __html: styledHtmlQuery }} />
            </CodeTemplate>
          )}
          {showJsonStringQuery && jsonStringQuery && (
            <CodeTemplate>{jsonStringQuery}</CodeTemplate>
          )}
          {showJsonQuery && jsonQuery && (
            <CodeTemplate>{JSON.stringify(jsonQuery, null, 4)}</CodeTemplate>
          )}
        </>
      ) : null}
    </Container>
  );
}

export { QueryFilterPreview };
