// @ts-check

const groupCollapsed = false;

function formaInfo(text) {
  return [`%c${text}`, 'color: blue;'];
}

function formaWarning(text) {
  return [`%c${text}`, 'color: yellow;'];
}

function formaError(text) {
  return [`%c${text}`, 'color: tomato;'];
}

function formaSuccess(text) {
  return [`%c${text}`, 'color: green;'];
}

const logger = /** @type {const} */ ({
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
  logRed: (text = '') => {
    console.log(...formaError(text));
  },
  logBlue: (text = '') => {
    console.log(...formaInfo(text));
  },
  logGreen: (text = '') => {
    console.log(...formaSuccess(text));
  },
  logYellow: (text = '') => {
    console.log(...formaWarning(text));
  },
  group: (text = '') => {
    groupCollapsed ? console.group(`${text}`) : console.group(`${text}`);
  },
  groupEnd: () => {
    console.groupEnd();
  },
});

logger.logY = '';

export { logger };
