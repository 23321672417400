/* eslint-disable camelcase */
// @ts-check

/** @type {Record<keyof Announcement, keyof AnnouncementModel>} */
const schema = /** @type {const} */ ({
  announcement_id: 'announcementId',
  title: 'title',
  content: 'content',
  created_by: 'createdBy',
  end_at: 'endAt',
  start_at: 'startAt',
  created_at: 'createdAt',
  updated_at: 'updatedAt',
  last_updated_by: 'lastUpdatedBy',
});

const modelMapper = Object.entries(schema);

/**
 * @param {AnnouncementResponse} data
 */
const mapToAnnouncementClientModel = (data) => {
  // Map HTTP response attribute to the client model attributes.
  const announcementData = /** @type {AnnouncementModel} */ ({});

  for (let [serverKey, clientKey] of modelMapper) {
    announcementData[clientKey] = /** @type {never} */ (data[serverKey]);
  }

  return announcementData;
};

/**
 * @param {AnnouncementModel} data
 * @return {AnnouncementPostPutRequest}
 */
const mapToAnnouncementWebApiModel = (data) => {
  const announcementData = /** @type {Announcement} */ ({});

  for (let [serverKey, clientKey] of modelMapper) {
    announcementData[serverKey] = data[clientKey];
  }

  return announcementData;
};

export { mapToAnnouncementClientModel, mapToAnnouncementWebApiModel };
