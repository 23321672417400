// @ts-check
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// core
import { getCampaigns } from 'core/services/campaign-service';
import { setCampaignData } from './campaignDataSlice';
import { setHttpError } from './sharedSlice';

/** @type {Partial<CampaignListState>} */
const initialState = {
  loading: false,
  campaigns: [],
  isLastPage: false,
  page: 1,
};

const campaignListSlice = createSlice({
  name: 'campaignList',
  initialState,
  reducers: {
    setCampaignListLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * @param {{
     *  payload: {
     *    campaigns: CampaignMetaData []
     *    isLastPage: boolean
     *    page: number
     *  }
     * }} action
     */
    setCampaignList: (state, action) => {
      const { campaigns, isLastPage, page } = action.payload;
      state.campaigns = campaigns;
      state.isLastPage = isLastPage;
      state.page = page;
    },
    resetCampaignListCache: (state) => {
      state.loadFromCached = false;
    },
  },
});

export const getCampaignListAsync = createAsyncThunk(
  'campaignList/getCampaignList',
  /**
   * @param {{ searchFilter: SearchFilter}} payload
   */
  async (payload, { dispatch }) => {
    dispatch(
      setCampaignList({
        campaigns: [],
        isLastPage: false,
        page: 1,
      }),
    );

    dispatch(setCampaignListLoading(true));

    try {
      const { campaigns, isLastPage, page } = await getCampaigns(
        payload.searchFilter,
      );

      if (campaigns) {
        dispatch(
          setCampaignList({
            campaigns,
            isLastPage,
            page,
          }),
        );
        dispatch(setCampaignData({ reloadData: true }));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getCampaignListAsync.typePrefix,
        }),
      );
    }
    dispatch(setCampaignListLoading(false));
  },
);

export const {
  setCampaignListLoading,
  setCampaignList,
  resetCampaignListCache,
} = campaignListSlice.actions;

export default campaignListSlice.reducer;
