import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Core.
import { OPEN_MODE } from 'core/constants/app-constants';
import { defaultAnnouncementModel } from 'core/model/default-announcement-data';
import { getAnnouncement } from 'core/services';
import { updateAnnouncement } from 'core/services/announcement-service';
import { setHttpError, setPageLoading } from './sharedSlice';

/** @type {AnnouncementDataState} */
const initialState = {
  loading: false,
  openMode: OPEN_MODE.VIEW,
  newAnnouncementId: null,
  announcement: { ...defaultAnnouncementModel },
};

export const announcementDataSlice = createSlice({
  name: 'announcementData',
  initialState,
  reducers: {
    /**
     * @param {{ payload: boolean }} action
     */
    setAnnouncementLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * @param {{
     *  payload: {
     *    announcement: AnnouncementModel,
     *  }
     * }} action
     */
    setAnnouncement: (state, action) => {
      state.announcement = action.payload.announcement;
    },
    /**
     * @param {{ payload: Partial<AnnouncementDataState> }} action
     */
    setAnnouncementData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const getAnnouncementAsync = createAsyncThunk(
  'announcementData/getAnnouncementAsync',
  /**
   * @param {Partial<AnnouncementDataState>} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setAnnouncementLoading(true));
    dispatch(setAnnouncement({ announcement: defaultAnnouncementModel }));

    try {
      const announcement = await getAnnouncement(payload.announcementId);

      if (announcement) {
        dispatch(
          setAnnouncementData({
            announcement,
          }),
        );
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getAnnouncementAsync.typePrefix,
        }),
      );
    }

    dispatch(setAnnouncementLoading(false));
  },
);

export const updateAnnouncementAsync = createAsyncThunk(
  'announcementData/updateAnnouncementAsync',
  /**
   * @param {any} payload
   */
  async (payload, { dispatch }) => {
    try {
      dispatch(setPageLoading(true));

      const { announcement } = payload;

      const updatedAnnouncement = await updateAnnouncement(announcement);
      dispatch(
        setAnnouncement({
          announcement: updatedAnnouncement,
        }),
      );
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: updateAnnouncementAsync.typePrefix,
        }),
      );
    }

    dispatch(setPageLoading(false));
  },
);

export const { setAnnouncementLoading, setAnnouncement, setAnnouncementData } =
  announcementDataSlice.actions;

export default announcementDataSlice.reducer;
