// @ts-check

import React from 'react';
import { Tooltip } from 'core/components';

/**
 * @param {{
 *    createdBy: string
 *    onChange: (e) => void
 * }} props
 */
function CreatedBy({ createdBy, onChange }) {
  return (
    <div
      className="form-group"
      id="created-by-field"
      style={{ margin: '0 0 1rem 0' }}
    >
      <label>Created By</label>
      <Tooltip tooltipId="created-by-tooltip">
        The ASURITE of the user who created the campaign.
      </Tooltip>
      <input
        data-testid="created-by"
        type="text"
        className="form-control"
        style={{ border: '1px solid #BFBFBF' }}
        placeholder="ASURITE"
        aria-describedby="error-created-by"
        aria-required="true"
        required
        name="createdBy"
        id="createdBy"
        value={createdBy}
        autoComplete="off"
        autoFocus
        onChange={onChange}
      />
    </div>
  );
}

export { CreatedBy };
