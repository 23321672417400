// @ts-check

const appRoles = /** @type {const} */ ({
  ADMIN: 'admin',
  CREATOR: 'creator',
});

const statementStatus = /** @type {const} */ ({
  RUNNING: 'running',
  FINISHED: 'finished',
  FAILED: 'failed',
});

const userType = /** @type {const} */ ({
  OWNER: 'owner',
  COLLABORATOR: 'collaborator',
});

const sourceStatus = /** @type {const} */ ({
  PENDING: 'pending',
  COMPLETE: 'complete',
});

export { appRoles, userType, statementStatus, sourceStatus };
