//@ts-check
import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Core.
import { Tooltip } from 'core/components';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePickDiv = styled.div`
  background-color: white;
  border: solid 0.1em #bfbfbf;
  border-radius: 0.25em;
  padding: 0.3em 1em 0 1em;
`;

//TODO: Fix the error about "Type aliases can only be used in TypeScript files.ts(8008)".
type Props = {
  onClick: () => void,
  value: string,
};

const CustomInput = React.forwardRef(({ onClick, value }: Props) => {
  return (
    <CustomDatePickDiv>
      <label className="font-weight-normal" onClick={onClick}>
        {value}
      </label>
      &nbsp;
      <FontAwesomeIcon icon={faCalendarAlt} onClick={onClick} />
    </CustomDatePickDiv>
  );
});

/**
 * @param {{
 *    dateId: string
 *    dateName: string
 *    searchDate: string
 *    dateTitle: string
 *    tooltip: string
 *    onChange: (e) => void
 * }} props
 */
function SearchDate({
  dateId,
  dateName,
  searchDate,
  dateTitle,
  tooltip,
  onChange,
}) {
  // Note: the datepicker control was partially hidden by the results table header.
  // Adding the prop portalId fixes this issue.
  return (
    <div className="form-group" id="search-date-field">
      <label>{dateTitle || 'Date'}</label>
      <Tooltip tooltipId="search-date-tooltip">{tooltip}</Tooltip>
      <section>
        <DatePicker
          id={dateId}
          name={dateName}
          // @ts-ignore - Fix the error about "Type 'Date' is not assignable to type 'string'."
          customInput={<CustomInput />}
          dateFormat="yyyy-MM-dd"
          portalId="root-portal"
          showIcon
          selected={searchDate}
          onChange={onChange}
        />
      </section>
    </div>
  );
}
export { SearchDate };
