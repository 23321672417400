// @ts-check

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// state
import { useAppDispatch } from 'core/store';
import { getAnnouncementAsync } from 'core/store/slices';

const useStateAnnouncement = () => {
  const dispatch = useAppDispatch();

  /** @type {{ id: string }} */
  let { id: announcementId } = useParams();
  useEffect(() => {
    if (!announcementId) {
      return;
    }

    dispatch(
      getAnnouncementAsync({
        announcementId,
      }),
    );
  }, [announcementId, dispatch]);
};

export { useStateAnnouncement };
