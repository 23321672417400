// @ts-check
import React, { useCallback, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
// local services
import { routerUrl } from 'core/constants/router-url';
import { getSSOName, getUserRoles } from 'core/services/user-service';
import { logger } from 'core/utils';
import { AuthError } from '../services/auth-error';
import * as client from '../services/auth-service';
import { actionTypes } from '../store/actions';
import { reducer } from '../store/reducer';
import { initialAuthState } from '../store/state';
import { getTokenFromStorage } from '../utils/get-token-from-storage';
import { hasAuthParams } from '../utils/utils';
import { AuthContext } from './context';

/**
 * @param {PropsWithChildren<AuthSettings>} props
 */

/**
 * @param {PropsWithChildren<{
 *  defaultState?: AuthState
 * }>} props
 */
export function AuthProvider({ children, defaultState = initialAuthState }) {
  let history = useHistory();

  /** @type {[AuthState, (action: AuthAction) => void]} */
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    let timer;

    const startRefreshTokenTimeout = (remainingTimeSeconds = 60 * 9) => {
      const timeOutSeconds = remainingTimeSeconds;
      const TIME_OUT = timeOutSeconds * 1000;

      logger.logYellow(
        `[TIMER] - Next token will be requested in ${timeOutSeconds} in second`,
      );

      timer = setTimeout(async () => {
        try {
          await client.requestRefreshToken();
          const jwt = getTokenFromStorage();
          startRefreshTokenTimeout(jwt?.remainingTimeSeconds);
        } catch (error) {
          dispatch({ type: actionTypes.ERROR, error: error });
        }
      }, TIME_OUT);
    };

    (async () => {
      if (state.errorBlocker) {
        return;
      }

      console.group('Auth - Checking for auth params');

      try {
        if (hasAuthParams()) {
          const { returnTo } = await client.handleRedirectCallback();
          history.push(returnTo);
        }

        let jwt = await client.checkSession();
        jwt && startRefreshTokenTimeout(jwt.remainingTimeSeconds);

        const user = await client.getUser();
        const ssoName = getSSOName();
        dispatch({ type: actionTypes.INITIALISED, user, ssoName });
      } catch (error) {
        dispatch({
          type: actionTypes.ERROR,
          error,
          errorBlocker: true,
        });

        if (
          error instanceof AuthError ||
          error.message.includes('status code 400')
        ) {
          history.push('/');
        }
      } finally {
        console.groupEnd();
      }
    })();

    return () => clearTimeout(timer);
  }, [history, state.errorBlocker]);

  const loginWithRedirect = useCallback(() => {
    if (state.errorBlocker) {
      return null;
    }

    return client.loginWithRedirect();
  }, [state.errorBlocker]);

  const loadUserRoles = async () => {
    const me = await getUserRoles();
    dispatch({
      type: actionTypes.SET_USER_ROLES,
      userRoles: me?.userRoles,
    });
  };

  const logout = useCallback(() => {
    history.push(routerUrl.LOG_OUT);
    client.logout();
    dispatch({ type: actionTypes.LOGOUT });
  }, [history]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginWithRedirect,
        logout,
        loadUserRoles,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
