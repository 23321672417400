// @ts-check

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const DialogStyle = styled.dialog`
  ::backdrop {
    background-color: rgba(25, 25, 25, 0.75);
  }

  margin: auto;
  border: none !important;
  border-radius: 0.5em;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  #open-dialog {
    margin: 0 auto;
  }

  header {
    font-size: 1.35rem;
    font-weight: bold;
  }

  p {
    line-height: 1.625;
  }

  footer button {
    min-width: 6em;
  }
`;

/**
 * @param {{
 *  testId?: string,
 *  id?: string,
 *  open: boolean,
 *  title: string,
 *  message: string,
 *  cancelButtonText?: string,
 *  cancelButtonDisplay?: boolean,
 *  onCancelButtonClick?: () => void,
 *  okButtonText: string,
 *  okButtonDisplay?: boolean,
 *  onOkButtonClick:  () => void,
 * }} props
 */
const Dialog = ({
  testId,
  id,
  open,
  title,
  message: text,
  cancelButtonText = 'Cancel',
  cancelButtonDisplay = false,
  onCancelButtonClick = () => null,
  okButtonText = 'Ok',
  okButtonDisplay = true,
  onOkButtonClick = () => null,
}) => {
  /** @type {UseRefOf<HTMLDialogElement>} */
  const modalRef = useRef();

  useEffect(() => {
    if (open && !modalRef.current?.open) modalRef.current?.showModal?.();
    else modalRef.current?.close?.();
  }, [open]);

  return (
    <DialogStyle
      data-testid={testId}
      id={id}
      ref={modalRef}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <header id="dialog-title">{title}</header>
      <p id="dialog-description">{text}</p>

      <footer className="d-flex justify-content-around">
        {cancelButtonDisplay && (
          <button
            data-testid="btn-close-dialog"
            type="button"
            id="close-dialog"
            className="btn btn-md btn-gold"
            onClick={onCancelButtonClick}
          >
            {cancelButtonText}
          </button>
        )}
        {okButtonDisplay && (
          <button
            data-testid="btn-confirm-dialog"
            type="button"
            id="confirm-dialog"
            className="btn btn-md btn-maroon"
            onClick={onOkButtonClick}
          >
            {okButtonText}
          </button>
        )}
      </footer>
    </DialogStyle>
  );
};

export { Dialog };
