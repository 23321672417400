// @ts-check

// import * as mock from '../../__dev__/data-mocks/api-response/display-content.json';
import { fetchData } from '../utils/http-utils';
import { mapToDisplayContentModel } from '../utils/model-mapper-utils';

async function getDisplayContent() {
  /** @type {AxiosResponse<DisplayContentResponse>} */
  const { data } = await fetchData(`/display-content`);

  //#region "Mock HTTP request"
  // @ts-ignore Mock response
  // const data = await new Promise((resolve) =>
  //   setTimeout(
  //     () =>
  //       resolve(
  //         // @ts-ignore
  //         /** @type {DisplayContentResponse} */ (mock.default || mock),
  //       ),
  //     2500,
  //   ),
  // );
  //#endregion

  return mapToDisplayContentModel(data);
}

export { getDisplayContent };
