// @ts-check
import React from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import styled from 'styled-components';

// Core.
import { RequiredIcon, Tooltip } from 'core/components';

// Overrided the 'u' element style set by ASU bootstrap theme.
// The ASU theme sets 'u' element text-decoration-line: none and font-style: italic. Wierd.
const Container = styled.div`
  u {
    text-decoration-line: underline;
    font-style: normal;
  }
`;

/**
 * @param {{
 * announcementContent: string
 * onChange: (e) => void
 * readonly: boolean
 * errors?: string []
 * }} props
 */
function AnnouncementContent({
  announcementContent,
  onChange,
  readonly,
  errors,
}) {
  return (
    <Container className="form-group" id="announcement-content-field">
      <label>
        <RequiredIcon />
        Announcement Content
      </label>
      <Tooltip tooltipId="announcement-content-tooltip">
        This is a content of the announcement; minimum 5 characters.
      </Tooltip>
      <EditorProvider>
        <Editor value={announcementContent} onChange={onChange}>
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnBulletList />
            <BtnNumberedList />
            <Separator />
            <BtnLink />
            <BtnClearFormatting />
            <Separator />
            <BtnStyles />
          </Toolbar>
        </Editor>
      </EditorProvider>
    </Container>
  );
}

export { AnnouncementContent };
