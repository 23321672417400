// @ts-check
import React from 'react';

const Support = () => {
  return (
    <div className="container">
      <h2>
        <span className="highlight-gold" data-testid="support">
          Support
        </span>
      </h2>
      <section className="mb-7">
        <h3>Contact Us</h3>
        <ul className="pl-0 m-0 list-unstyled">
          <li className="mb-1">
            <strong>Email:</strong>{' '}
            <a href="mailto:aacb@asu.edu">aacb@asu.edu</a>
          </li>
          <li className="mb-1">
            <strong>Slack channel:</strong>{' '}
            <a
              href="https://asu-community.slack.com/archives/C03MN8FTMGD"
              target="_blank"
              rel="noreferrer"
            >
              #aacb_support
            </a>
          </li>
          <li className="mb-1">
            <strong>Feedback: </strong>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeAlPrRi19Baa_T1C-EvxB9uH6PTyvX7WzwbvBN60ib3VbzqQ/viewform?usp=sf_link"
              target="_blank"
              rel="noreferrer"
            >
              Campaign Builder Feedback Form
            </a>
          </li>
        </ul>
      </section>
      <section className="mb-7">
        <h3>Training Materials</h3>
        <ul className="pl-0 m-0 list-unstyled">
          <li className="mb-1">
            <a
              href="https://docs.google.com/document/d/1TugNj5gDc85lvVSA4TsOYHXCCa8pTQtuX7NtKo4nBuM/edit"
              target="_blank"
              rel="noreferrer"
            >
              End User Documentation
            </a>
          </li>
          <li className="mb-1">
            <a
              href="https://docs.google.com/document/d/1xg-SVGKZSjBtaMtbcikWBWrqfJbjxpFILsmuMCdAAjo/edit#heading=h.ijge6lzdpn1k"
              target="_blank"
              rel="noreferrer"
            >
              Quick Start Guide
            </a>{' '}
            (recommended for new users)
          </li>
          <li className="mb-1">
            <a
              href="https://docs.google.com/document/d/1xfyMYw_BVXR0MZ7WCIFy2l2f_bQuUZpIYgl0LPfgJfE/edit?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              Job Aid Reference
            </a>
          </li>
          <li className="mb-1">
            <a
              href="https://docs.google.com/spreadsheets/d/1pdhpt_jn9wdIEVxcmOUJTb133QJBIYl0agPEqNxesHM/edit#gid=312242724"
              target="_blank"
              rel="noreferrer"
            >
              Filters Dictionary
            </a>
          </li>
          <li className="mb-1">
            <a
              href="https://docs.google.com/document/d/1N35nsOAMO12rwqw_yUJz4JuccFQb1p0apngaqZF-El4/edit?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              Release Notes
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export { Support };
