// @ts-check
import React from 'react';
import { Link } from 'react-router-dom';

const getAnnouncementId = (apiBaseUrl) => {
  // This is a temporary solution to get the announcementId based on the apiBaseUrl
  const announcementIds = {
    'https://1lbfz901zf.execute-api.us-west-2.amazonaws.com/qa':
      '9cd09969-40d5-478a-848d-f39aded6199b',
    'https://b779yzl2k1.execute-api.us-west-2.amazonaws.com/prod':
      'ef038141-35ac-4000-af0c-786aa76d11fe',
  };
  return announcementIds[apiBaseUrl] || '';
};

const Admin = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const announcementId = getAnnouncementId(API_BASE_URL);

  return (
    <div className="container">
      <div className="row">
        <h2>
          <span className="highlight-gold" data-testid="support">
            Admin
          </span>
        </h2>
      </div>

      <div className="row">
        <div className="col col-lg-4">
          <div className="card">
            <div className="card-header" />
            <div className="card-body">
              <div className="card-event-icons">
                <div>
                  <i className="fa-solid fa-calendar-week" />
                </div>
                <div>
                  <Link to={`/announcement/view/${announcementId}`}>
                    Manage Announcements
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-lg-4">
          <div className="card">
            <div className="card-header" />
            <div className="card-body">
              <div className="card-event-icons">
                <div>
                  <i className="fa-solid fa-user-group" />
                </div>
                <div>Group Management</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Admin };
