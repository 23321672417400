// @ts-check

import React from 'react';
import { ErrorValidation, RequiredIcon, Tooltip } from 'core/components';

/**
 * @param {{
 *    announcementTitle: string
 *    onChange: (e) => void
 *    readonly: boolean
 *    errors?: string []
 * }} props
 */
function AnnouncementTitle({ announcementTitle, onChange, readonly, errors }) {
  return (
    <div className="form-group" id="announcement-title-field">
      <label>
        <RequiredIcon />
        Announcement Title
      </label>
      <Tooltip tooltipId="announcement-title-tooltip">
        The name given to the campaign, potentially public; minimum 5
        characters.
      </Tooltip>
      <input
        data-testid="announcement-title"
        type="text"
        className={`form-control ${errors.length > 0 ? 'is-invalid' : ''}`}
        placeholder="Announcement title"
        aria-describedby="error-campaign-name"
        aria-required="true"
        required
        name="title"
        id="title"
        value={announcementTitle}
        autoComplete="off"
        autoFocus
        readOnly={readonly}
        disabled={readonly}
        onChange={onChange}
      />
      <ErrorValidation errors={errors} />
    </div>
  );
}

export { AnnouncementTitle };
