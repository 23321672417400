// @ts-check
import { createSlice } from '@reduxjs/toolkit';

// core
import { wsResponseType } from 'core/services/web-socket-service';

/** @type {CampaignPreviewState} */
const initialState = {
  loading: false,
  previewData: {
    columns: [' '],
    records: [],
    totalRecordCount: 0,
    queryLogic: null,
  },
};

export const campaignPreviewSlice = createSlice({
  name: 'campaignPreview',
  initialState,
  reducers: {
    setCampaignPreviewLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCampaignPreview: (state, action) => {
      state.previewData = action.payload.previewData;
    },
    resetCampaignPreview: (state) => {
      state.previewData = initialState.previewData;
    },
    setCampaignPreviewWebsocket: (
      state,
      /**
       * @type {{
       *  payload: {
       *    wsData: WebSocketData,
       *    queryLogic: CB_RuleGroupType
       * }
       * }}
       * */ action,
    ) => {
      const { wsData, queryLogic } = action.payload;

      if (wsData.detail_type === wsResponseType.QUERY_RESULTS) {
        state.previewData = {
          columns: wsData.detail.results.columns,
          records: wsData.detail.results.records,
          totalRecordCount: wsData.detail.record_count,
          queryLogic,
        };
        state.loading = false;
      } else if (wsData.detail_type === wsResponseType.QUERY_FAILED) {
        state.previewData = {
          columns: [],
          records: [],
          queryLogic: null,
        };
        state.loading = false;
      }
    },
  },
});

export const {
  setCampaignPreviewLoading,
  setCampaignPreview,
  resetCampaignPreview,
  setCampaignPreviewWebsocket,
} = campaignPreviewSlice.actions;

export default campaignPreviewSlice.reducer;
