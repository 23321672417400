// @ts-check
/** If you to try 'react-querybuilder' switch those 2 lines */
// import { defaultValueProcessor, formatQuery } from 'react-querybuilder';
import { defaultValueProcessor, formatQuery } from './format-query';
import { betweenOperators, inOperators } from './query-builder-config';

/** @typedef {CB_RuleGroupType} RuleGroupType */

/**
 * @param {string} field
 * @param {string} operator
 * @param {undefined | [{value: string}]} value
 */
const sqlValueProcessor = (field, operator, value) => {
  if (['null', 'notnull'].includes(operator)) {
    return '';
  }

  if (!value) return `''`;

  if (Array.isArray(value)) {
    if (inOperators.includes(operator)) {
      return `(${value.map((v) => `'${v.value?.trim()}'`).join(',')})`;
    }

    if (betweenOperators.includes(operator)) {
      return `${value
        .slice(0, 2)
        .map((v) => `'${v.value?.trim()}'`)
        .join(' AND ')}`;
    }

    return `"${value[0].value}"`;
  }

  return defaultValueProcessor(field, operator, value);
};

/**
 * @param {RuleGroupType} newQuery
 */
function formatQueryToSql(newQuery) {
  let query = `SELECT *\n FROM my_table\n WHERE `;

  query += `${formatQuery(newQuery, {
    format: 'sql',
    valueProcessor: sqlValueProcessor,
  })};`;

  return query;
}

/**
 * @param {RuleGroupType} newQuery
 * @returns {string}
 */
function formatHtmlPLainQuery(newQuery) {
  let processedQuery = `${formatQuery(newQuery, {
    format: 'sql',
    valueProcessor: sqlValueProcessor,
  })};`;

  /**
   * @typedef {{
   *  keyword: string,
   *  value: string,
   *  replaceSubstrings?: {
   *    keyword: string,
   *    value?: string,
   *    takeSubTerm?: boolean,
   *  }[]
   * }} PlainQueryOperator
   *
   * @type {PlainQueryOperator[]}
   * */
  const plainQueryOperators = [
    { keyword: ' = ', value: 'equals' },
    { keyword: ' != ', value: 'does not equal' },
    { keyword: ' < ', value: 'is less than' },
    { keyword: ' > ', value: 'is greater than' },
    { keyword: ' <= ', value: 'is less or equals than' },
    { keyword: ' >= ', value: 'is greater or equal than' },
    {
      keyword: " not like '[A-Z]+[%]' ",
      value: '',
      replaceSubstrings: [
        {
          keyword: ' not like ',
          value: 'does not begin with',
        },
        {
          keyword: '[A-Z]+[%]',
          takeSubTerm: true,
        },
      ],
    },
    {
      keyword: " not like '%[A-Z]+' ",
      value: '',
      replaceSubstrings: [
        {
          keyword: ' not like ',
          value: 'does not end with',
        },
        {
          keyword: '[%][A-Z]+',
          takeSubTerm: true,
        },
      ],
    },
    {
      keyword: " like '[A-Z]+%' ",
      value: '',
      replaceSubstrings: [
        {
          keyword: ' like ',
          value: 'begins with',
        },
        {
          keyword: '[A-Z]+[%]',
          takeSubTerm: true,
        },
      ],
    },
    {
      keyword: " like '%[A-Z]+' ",
      value: '',
      replaceSubstrings: [
        {
          keyword: ' like ',
          value: 'ends with',
        },
        {
          keyword: '[%][A-Z]+',
          takeSubTerm: true,
        },
      ],
    },
    { keyword: ' not like ', value: 'does not contain' },
    { keyword: ' like ', value: 'contains' },
    { keyword: ' is not null ', value: 'is not null' },
    { keyword: ' is null ', value: 'is null' },
    { keyword: ' not in  ', value: 'does not include' },
    { keyword: ' in ', value: 'includes' },
    { keyword: ' not between ', value: 'is not between' },
    { keyword: ' between ', value: 'is between' },
    { keyword: ' and ', value: 'and' },
    { keyword: ' or ', value: 'or' },
  ];

  const htmlSpanOperator = (value) =>
    `<span style="font-weight: normal;">&nbsp;${value}&nbsp;</span>`;
  /**
   * @param {string} term
   * @param {PlainQueryOperator} operator
   * @return {string}
   */
  const processSubstringOperator = (term, { replaceSubstrings }) => {
    let compoundValues = [];
    let compoundValue = term;

    replaceSubstrings.forEach((el) => {
      compoundValue = compoundValue.replace(
        new RegExp(el.keyword, 'gi'),
        (subTerm) => {
          return el.takeSubTerm
            ? `<span>${subTerm.replace('%', '')}</span>`
            : htmlSpanOperator(el.value);
        },
      );

      compoundValues.push(compoundValue);
    });

    return compoundValue;
  };

  plainQueryOperators.forEach((operator) => {
    const { keyword, value, replaceSubstrings } = operator;
    processedQuery = processedQuery.replace(
      new RegExp(keyword, 'gi'),
      (term) => {
        if (replaceSubstrings?.length > 0) {
          return processSubstringOperator(term, operator);
        } else {
          return htmlSpanOperator(value);
        }
      },
    );
  });

  const query = `<span style="font-weight: bold;">${processedQuery}</span>`;

  return query;
}

/**
 * @param {string} sqlQuery
 * @returns {string}
 */
function formatQueryToHTML(sqlQuery = '') {
  // ==============================================
  // Style SQL keywords
  // ==============================================
  const operators = [
    ' < ',
    ' > ',
    ' >= ',
    ' <= ',
    ' = ',
    ' != ',
    ' is null',
    ' is not null',
    ' not in ',
    ' in ',
    ' not between ',
    ' between ',
  ];

  const keyWords = [
    ...operators,
    'SELECT',
    'FROM',
    'WHERE',
    'NOT LIKE',
    'LIKE',
    /\(/,
    /\)/,
  ];

  let styledKeywords = sqlQuery;
  keyWords.forEach((keyword) => {
    styledKeywords = styledKeywords.replace(
      new RegExp(keyword, 'gi'),
      (value) => `<b>${value.toUpperCase()}</b>`,
    );
  });
  // ==============================================
  // style SQL values
  // ==============================================

  const whereValues = [/"(.*?)"/, /'(.*?)'/, /\*/];
  let styledWhere = styledKeywords;
  whereValues.forEach((keyword) => {
    styledWhere = styledWhere.replace(new RegExp(keyword, 'gi'), (value) => {
      return `<span style="color: blue;">${value}</span>`;
    });
  });
  // ==============================================

  return styledWhere;
}

/**
 * @param {RuleGroupType} newQuery
 * @returns {string}
 */
function formatQueryToStringJSON(newQuery) {
  const query = /** @type {string} */ (formatQuery(newQuery, {
    format: 'json_only_query_attributes',
  }));

  return query;
}

/**
 * @param {RuleGroupType} newQuery
 * @returns {string}
 */
function formatQueryJSONtoHTML(newQuery) {
  if (!newQuery) return '';
  const sqlQuery = formatQueryToSql(newQuery);
  const htmlQuery = formatQueryToHTML(sqlQuery);

  return htmlQuery;
}

/**
 * @param {RuleGroupType} rawQuery
 * @returns {QueryFilterValue}
 */
const processQuery = (rawQuery) => {
  if (!rawQuery) return {};

  const valueQuery = rawQuery;
  const jsonStringQuery = formatQueryToStringJSON(rawQuery);
  const jsonQuery = JSON.parse(jsonStringQuery);
  const sqlQuery = formatQueryToSql(rawQuery);
  const styledHtmlQuery = formatQueryToHTML(sqlQuery);
  const styledHtmlPLainQuery = formatHtmlPLainQuery(rawQuery);

  return {
    valueQuery,
    jsonStringQuery,
    jsonQuery,
    sqlQuery,
    styledHtmlQuery,
    styledHtmlPLainQuery,
  };
};

export {
  processQuery,
  formatQueryToSql,
  formatQueryToHTML,
  formatQueryToStringJSON,
  formatQueryJSONtoHTML,
};
