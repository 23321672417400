// @ts-check
import React from 'react';

function PageTitle({ title }) {
  return (
    <h2>
      <span className="highlight-gold">{title}</span>
    </h2>
  );
}

export { PageTitle };
