// @ts-check
import { sanitize } from 'dompurify';

/**
 * @param {string} content
 */
const sanitizeDangerousMarkup = (content) => {
  return { __html: sanitize(content) };
};

function scrollTopPage() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

/**
 * @param {string} elementSelector
 */
function scrollToElement(elementSelector, focused = false) {
  const element = document.querySelector(elementSelector);
  element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  if (focused && element) {
    const input = /** @type {HTMLInputElement} */ (
      element.querySelector('input, textarea')
    );
    input && setTimeout(() => input.focus(), 800);
  }
}

export { scrollToElement, sanitizeDangerousMarkup, scrollTopPage };
