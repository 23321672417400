// @ts-check
import styled from 'styled-components';

const MIN_ROW_HEIGHT = 38;

const Container = styled.section`
  & input:disabled {
    background-color: rgb(242 242 242);
    cursor: not-allowed;
  }

  & *:disabled {
    cursor: not-allowed;
  }

  footer button {
    transition: background-color 700ms cubic-bezier(0.39, 0.58, 0.57, 1);
  }
`;

const inputErrorStyle = `
  border-style: solid;
  border: 1px solid #b72a2a;
  border-bottom: 8px solid #b72a2a;
  height: 100%;
  height: calc(${MIN_ROW_HEIGHT}px + 8px);
`;

const focusStyle = `
    &:focus {
      outline: none !important;
      box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #191919 !important;
    }
`;
const Grid = styled.div`
  display: flex;
  flex-direction: column;
  min-width: max-content;
  width: 100%;
  height: 100%;
  box-shadow: none !important;

  &[data-aria-readonly='true'] * {
    cursor: not-allowed;
  }
  //======================
  // Error handling
  //======================
  &[data-grouping-error='true'] {
    [data-grouping='('],
    [data-grouping=')'] {
      & .grouping [class*='-control'] {
        ${inputErrorStyle}
      }
    }
  }

  &[data-show-error='true'][data-error='true'] {
    .grid-row:not([data-row-index='0']) .combinator,
    .field,
    .operator,
    .value {
      &[data-has-error='true'] [class*='-control'],
      &[data-has-error='true'] [class='input-value']{
        ${inputErrorStyle}
    }
  }
`;

const GridHeader = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: max-content;
  width: 100%;

  .row-error {
    display: flex;
    flex-direction: column;
  }

  .row-border {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #d0d0d0;
  }
`;

const GridScrollBody = styled.div`
  min-width: max-content;
  width: 100%;

  &.scroll {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const GridBody = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  min-width: max-content;
  width: 100%;
`;

const Row = styled.div`
  padding: 0.25rem;
  display: grid;
  grid-template-columns: 84px 63px 362px 180px auto 63px 26px;
  column-gap: 1rem;

  & label {
    font-size: 1rem;
  }

  &.no-label label {
    display: none;
  }

  &.row-error {
    gap: 0;
  }

  & .input-value {
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    border: solid 1px hsl(0, 0%, 80%);
    min-height: ${MIN_ROW_HEIGHT}px;
    outline: 0 !important;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 2px 8px;
    ${focusStyle}
  }
`;

const RowHeader = styled.div`
  &.row-header span {
    font-weight: bold;
    font-size: 1rem;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
  }

  &.combinator,
  &.groupStart,
  &.groupEnd {
    font-size: 0.875rem;
  }

  &.value {
    max-width: 352px;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    font-size: 0.875rem;
  }

  &.remove-button {
    display: flex;
    justify-content: center;

    [data-aria-readonly='true'] & {
      display: none;
    }

    .row-data &.remove-button {
      height: fit-content;
      padding-block: 0.5rem;
    }
  }
`;

const RemoveButton = styled.span.attrs({
  role: 'button',
  tabIndex: 0,
})`
  display: inline;
  align-content: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  & > * {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
  }
`;

const ButtonLink = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: inherit;
  text-decoration: underline;

  &:disabled {
    color: #999999 !important;
  }
`;

export {
  Container,
  Grid,
  GridHeader,
  GridBody,
  GridScrollBody,
  Row,
  Cell,
  RowHeader,
  ButtonLink,
  RemoveButton,
};
