// @ts-check

// Core.
import { fetchData, putData } from 'core/utils';
import {
  mapToAnnouncementClientModel,
  mapToAnnouncementWebApiModel,
} from 'core/utils/announcement-model-mapper-utils';

// import * as mock from '../../__dev__/data-mocks/api-response/announcement-get.json';

// This api line works: https://1lbfz901zf.execute-api.us-west-2.amazonaws.com/qa/announcement/9cd09969-40d5-478a-848d-f39aded6199b
// Returns this JSON:
// {
//     "announcement_id": "9cd09969-40d5-478a-848d-f39aded6199b",
//     "created_by": "ckwon6@asu.edu",
//     "title": "Placeholder Announcement Title",
//     "announcement": "This is placeholder announcement text.",
//     "start_at": "2024-02-02 00:00:00",
//     "end_at": "2024-05-05 00:00:00",
//     "created_at": "2024-02-06 16:22:39",
//     "updated_at": "2024-02-06 16:22:39",
//     "last_updated_by": "ckwon6@asu.edu"
// }

/**
 * @param {string} announcementId
 */
async function getAnnouncement(announcementId) {
  /** @type {AxiosResponse<AnnouncementResponse>} */
  const { data } = await fetchData(`/announcement/${announcementId}`);

  return mapToAnnouncementClientModel(data);
}

async function getActiveAnnouncements() {
  //#region "Real HTTP request"
  /** @type {AxiosResponse<AnnouncementResponse>} */
  const { data } = await fetchData(`/announcement`);
  //#endregion

  //#region "Mock HTTP request"
  // @ts-ignore Mock response
  // const data = await new Promise((resolve) =>
  //   setTimeout(
  //     () =>
  //       resolve(
  //         // @ts-ignore
  //         /** @type {AnnouncementResponse} */ ({
  //           announcements: [
  //             {
  //               announcement_id: '9cd09969-40d5-478a-848d-f39aded6199b',
  //               created_by: 'ckwon6@asu.edu',
  //               title: 'Fake Announcement Title 1',
  //               announcement: 'This is placeholder announcement text.',
  //               start_at: '2024-02-02 00:00:00',
  //               end_at: '2024-05-05 00:00:00',
  //               created_at: '2024-02-06 16:22:39',
  //               updated_at: '2024-02-06 16:22:39',
  //               last_updated_by: 'ckwon6@asu.edu',
  //             },
  //             {
  //               announcement_id: '9cd09969-40d5-478a-848d-f39aded6199c',
  //               created_by: 'ckwon6@asu.edu',
  //               title: 'Fake Announcement Title 2',
  //               announcement: 'This is placeholder announcement text.',
  //               start_at: '2024-02-02 00:00:00',
  //               end_at: '2024-05-06 00:00:00',
  //               created_at: '2024-02-07 16:22:39',
  //               updated_at: '2024-02-10 16:22:39',
  //               last_updated_by: 'ckwon6@asu.edu',
  //             },
  //           ],
  //         }),
  //       ),
  //     2500,
  //   ),
  // );
  //#endregion

  return {
    announcements: data.announcements,
  };
}

async function getAllAnnouncements({ page = 1, perPage = 100 } = {}) {
  const searchParams = new URLSearchParams();

  searchParams.set('all', 'true');

  if (page) {
    searchParams.set('page', String(page));
  }

  if (perPage) {
    searchParams.set('per_page', String(perPage));
  }

  //#region "Real HTTP request"
  /** @type {AxiosResponse<AnnouncementResponse>} */
  const { data } = await fetchData(`/announcement`, searchParams);
  //#endregion

  //#region "Mock HTTP request"
  // @ts-ignore Mock response
  // const data = await new Promise((resolve) =>
  //   setTimeout(
  //     () =>
  //       resolve(
  //         // @ts-ignore
  //         /** @type {AnnouncementResponse} */ ({
  //           announcements: [
  //             {
  //               announcement_id: '9cd09969-40d5-478a-848d-f39aded6199b',
  //               created_by: 'ckwon6@asu.edu',
  //               title: 'Fake Announcement Title 1',
  //               announcement: 'This is placeholder announcement text.',
  //               start_at: '2024-02-02 00:00:00',
  //               end_at: '2024-05-05 00:00:00',
  //               created_at: '2024-02-06 16:22:39',
  //               updated_at: '2024-02-06 16:22:39',
  //               last_updated_by: 'ckwon6@asu.edu',
  //             },
  //             {
  //               announcement_id: '9cd09969-40d5-478a-848d-f39aded6199c',
  //               created_by: 'ckwon6@asu.edu',
  //               title: 'Fake Announcement Title 2',
  //               announcement: 'This is placeholder announcement text.',
  //               start_at: '2024-02-02 00:00:00',
  //               end_at: '2024-05-06 00:00:00',
  //               created_at: '2024-02-07 16:22:39',
  //               updated_at: '2024-02-10 16:22:39',
  //               last_updated_by: 'ckwon6@asu.edu',
  //             },
  //           ],
  //         }),
  //       ),
  //     2500,
  //   ),
  // );
  //#endregion

  return {
    announcements: data.announcements,
    isLastPage: data.is_last_page,
  };
}

/**
 *
 * @param {AnnouncementModel} announcement
 */
async function updateAnnouncement(announcement) {
  const announcementData = mapToAnnouncementWebApiModel(announcement);

  /** @type {AxiosResponse<AnnouncementResponse>} */
  const { data } = await putData(
    `/announcement/${announcement.announcementId}`,
    {
      title: announcementData.title,
      content: announcementData.content,
      // eslint-disable-next-line camelcase
      start_at: announcementData.start_at,
      // eslint-disable-next-line camelcase
      end_at: announcementData.end_at,
      // eslint-disable-next-line camelcase
      last_updated_by_user_id: announcementData.last_updated_by,
    },
  );

  return mapToAnnouncementClientModel(data);
  // return data;
}

export {
  getAnnouncement,
  getActiveAnnouncements,
  getAllAnnouncements,
  updateAnnouncement,
};
