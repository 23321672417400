// @ts-check
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// core
import { getUrlDownload } from 'core/services/campaign-file-service';
import { downloadFile } from 'core/utils/download-utils';

import { setHttpError, setPageLoading } from './sharedSlice';

/** @type {CampaignDownloadState} */
const initialState = {
  exportInProgress: false,
  downloadInProgress: false,
  downloadEnd: false,
};

export const campaignDownloadState = createSlice({
  name: 'campaignDownloadState',
  initialState,
  reducers: {
    setExportInProgress: (state, action) => {
      state.exportInProgress = action.payload;
    },
    setDownloadInProgress: (state, action) => {
      state.downloadInProgress = action.payload;
    },
    setDownloadEnd: (state, action) => {
      state.downloadEnd = action.payload;
    },
  },
});

export const exportCampaignAsync = createAsyncThunk(
  'campaignDownloadState/exportCampaignAsync',
  /**
   * @param {{
   *  statementId: string
   *  type: string
   *  downloadFilename: string,
   * }} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setPageLoading(true));
    dispatch(setExportInProgress(true));
    try {
      const res = await getUrlDownload(payload.statementId, payload.type);
      if (res.url) {
        dispatch(setDownloadInProgress(true));
        await downloadFile(res.url, payload.downloadFilename);
        dispatch(setDownloadInProgress(false));
        dispatch(setDownloadEnd(true));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: exportCampaignAsync.typePrefix,
        }),
      );
    }
    dispatch(setExportInProgress(false));
    dispatch(setPageLoading(false));
  },
);

export const { setExportInProgress, setDownloadInProgress, setDownloadEnd } =
  campaignDownloadState.actions;

export default campaignDownloadState.reducer;
