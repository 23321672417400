// @ts-check
import { createSlice } from '@reduxjs/toolkit';

// core
import { processAppError } from 'core/utils';

/** @type {SharedState} */
const initialState = {
  pageLoading: false,
  resourceNotFound: false,
  feedback: {
    networkErrorMessage: '',
    errorMessage: '',
    successMessage: '',
    infoMessage: '',
    pageSummary: '',
  },
  hero: {
    title: undefined,
    image: undefined,
    isOpen: false,
  },
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setResourceNotFound: (state, action) => {
      state.resourceNotFound = action.payload;
    },
    setInfoMessage: (state, action) => {
      state.feedback.infoMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.feedback.errorMessage = action.payload;
    },
    /**
     * @param {{
     *  payload: {
     *    httpError: any,
     *    sourceAction: string
     *  }
     * }} action
     */
    setHttpError: (state, action) => {
      const {
        payload: { httpError, sourceAction },
      } = action;
      const customError = processAppError(httpError);
      /** When error is 403 the app will redirect the user to the login page
       * and when possible it would automatically login the use  */
      if (customError.errorCode === 403) {
        state.feedback.infoMessage = 'Redirecting you to the login page...';
      } else {
        if (sourceAction === 'CREATE_CAMPAIGN') {
          state.feedback.errorMessage =
            `An error occurred while I tried to save the campaign.` +
            `Please, try again later.`;
        }
        state.feedback.networkErrorMessage = customError.error;
        state.resourceNotFound = customError.resourceNotFound;
        state.pageLoading = false;
      }
    },
    resetAlertMessages: (state) => {
      state.feedback = {
        ...initialState.feedback,
      };
    },
    setPageSummary: (state, action) => {
      state.feedback.pageSummary = action.payload;
    },
    resetPageSummary: (state) => {
      state.feedback.pageSummary = null;
    },
  },
});

export const {
  setPageLoading,
  setInfoMessage,
  setErrorMessage,
  resetAlertMessages,
  setResourceNotFound,
  setPageSummary,
  resetPageSummary,
  setHttpError,
} = sharedSlice.actions;

export default sharedSlice.reducer;
