// @ts-check

/** @type {Readonly<AnnouncementModel>} */
const defaultAnnouncementModel = {
  announcementId: '',
  title: '',
  content: '',
  startAt: '',
  endAt: '',
  createdBy: '',
  createdAt: '',
  lastUpdatedBy: '',
};

export { defaultAnnouncementModel };
