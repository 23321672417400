// @ts-check
import React from 'react';

/**
 * @param {{
 *  title: string
 *  items: CheckBoxListItem[]
 *  otherItem?: CheckBoxListItem
 * }} props
 */
const CheckBoxLabel = ({ title, items = [], otherItem }) => {
  return (
    <div className="pb-1">
      <strong>{title}</strong>
      <ul className="uds-list mt-1 mb-0">
        {items
          .filter((item) => item.value)
          .map((item, itemIndex) => {
            return <li key={item.id}>{item.value}</li>;
          })}
        {otherItem && otherItem.checked && <li>{otherItem.value}</li>}
      </ul>
    </div>
  );
};

export { CheckBoxLabel };
