// @ts-check

/** @type {Readonly<Object>} */
const appConstants = {
  APP_NAME: 'campaignbuilder',
  AUTH_URL: 'https://weblogin.asu.edu/serviceauth/oauth2/native/allow',
  TOKEN_URL: 'https://weblogin.asu.edu/serviceauth/oauth2/native/token',
};

const PAGE_STEP = /** @type {const} */ ({
  NONE: 'NONE',
  HOME: 'HOME',
  CAMPAIGN_DATA: 'CAMPAIGN_DATA',
  CAMPAIGN_FILTER: 'CAMPAIGN_FILTER',
  CAMPAIGN_DETAILS: 'CAMPAIGN_DETAILS',
});

const OPEN_MODE = /** @type {const} */ ({
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  VIEW: 'VIEW',
});

const CAMPAIGN_STATUS = /** @type {const} */ ({
  UPDATED: 'UPDATED',
  UPDATED_LIST_SHARED: 'UPDATED_LIST_SHARED',
  UPDATED_LOGIC_SHARED: 'UPDATED_LOGIC_SHARED',
  UPDATED_PUBLISH: 'UPDATED_PUBLISH',
  SAVED: 'SAVED',
  ERROR: 'ERROR',
});

const appErrors = {
  authErrorMessage: `Something went wrong. Please try again later.`,
  queryFailedError:
    'The query has failed. If the issue persist contact the admin',
  internalServerError:
    'The export has failed. If the issue persist contact the admin',
};

export { appConstants, appErrors, PAGE_STEP, OPEN_MODE, CAMPAIGN_STATUS };
