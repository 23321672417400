// @ts-check

import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

// slices
import announcementActiveReducer from './slices/announcementActiveSlice';
import AnnouncementDataReducer from './slices/announcementDataSlice';
import announcementListSlice from './slices/announcementListSlice';
import campaignDataReducer from './slices/campaignDataSlice';
import campaignDownloadReducer from './slices/campaignDownloadSlice';
import campaignListReducer from './slices/campaignListSlice';
import campaignPreviewReducer from './slices/campaignPreviewSlice';
import displayContentReducer from './slices/displayContentSlice';
import exportFilterReducer from './slices/exportFilterSlice';
import navigationReducer from './slices/navigationSlice';
import queryBuilderReducer from './slices/queryBuilderSlice';
import sharedReducer from './slices/sharedSlice';

export const setupStore = (
  /** @type {Partial<AppState> } */ preloadedState,
) => {
  return configureStore({
    reducer: {
      announcementData: AnnouncementDataReducer,
      announcementActive: announcementActiveReducer,
      announcementList: announcementListSlice,
      campaignData: campaignDataReducer,
      campaignDownload: campaignDownloadReducer,
      campaignList: campaignListReducer,
      campaignPreview: campaignPreviewReducer,
      displayContent: displayContentReducer,
      exportFilter: exportFilterReducer,
      navigation: navigationReducer,
      queryBuilder: queryBuilderReducer,
      shared: sharedReducer,
    },
    preloadedState,
  });
};

export const store = setupStore();
store.getState();
/** @return {typeof store.dispatch} */
const useAppDispatch = () => useDispatch();

/** @typedef {ReturnType<typeof store.getState>} RootState */
/**
 * @template [TState = RootState]
 * @template [TSelected = unknown]
 *
 * @param {(state: TState) => TSelected} selector
 * @return {TSelected}
 */
const useAppSelector = (selector) => {
  return useSelector(selector);
};

export { useAppSelector, useAppDispatch };
