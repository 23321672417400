// @ts-check

/**
 * @return {string[]} v
 */
const toArray = (v) =>
  Array.isArray(v) ? v : typeof v === 'string' ? v.split(',') : [];

const mapOperator = (/** @type {string} */ op) => {
  switch (op?.toLowerCase()) {
    case 'null':
      return 'is null';
    case 'notnull':
      return 'is not null';
    case 'notin':
      return 'not in';
    case 'notbetween':
      return 'not between';
    case 'contains':
    case 'beginswith':
    case 'endswith':
      return 'like';
    case 'doesnotcontain':
    case 'doesnotbeginwith':
    case 'doesnotendwith':
      return 'not like';
    default:
      return op;
  }
};

const parseTrimValue = (qValue) => {
  const value = typeof qValue === 'object' ? qValue.value : qValue;
  return value?.trim();
};

const defaultValueProcessor = (
  _field,
  operator,
  value,
  valueSource = 'string',
) => {
  const valueIsField = valueSource === 'field';
  const operatorLowerCase = operator.toLowerCase();
  if (operatorLowerCase === 'null' || operatorLowerCase === 'notnull') {
    return '';
  } else if (operatorLowerCase === 'in' || operatorLowerCase === 'notin') {
    const valArray = toArray(value);

    if (valArray.length > 0) {
      return `(${valArray
        .map((v) =>
          valueIsField ? `${parseTrimValue(v)}` : `'${parseTrimValue(v)}'`,
        )
        .join(', ')})`;
    } else {
      return '';
    }
  } else if (
    operatorLowerCase === 'between' ||
    operatorLowerCase === 'notbetween'
  ) {
    const valArray = toArray(value);
    if (valArray.length >= 2 && !!valArray[0] && !!valArray[1]) {
      const [first, second] = valArray;
      const firstValue = parseTrimValue(first);
      const secondValue = parseTrimValue(second);

      return valueIsField
        ? `${firstValue} and ${secondValue}`
        : `'${firstValue}' and '${secondValue}'`;
    } else {
      return '';
    }
  } else if (
    operatorLowerCase === 'contains' ||
    operatorLowerCase === 'doesnotcontain'
  ) {
    return valueIsField ? `'%' || ${value} || '%'` : `'%${value}%'`;
  } else if (
    operatorLowerCase === 'beginswith' ||
    operatorLowerCase === 'doesnotbeginwith'
  ) {
    return valueIsField ? `${value} || '%'` : `'${value}%'`;
  } else if (
    operatorLowerCase === 'endswith' ||
    operatorLowerCase === 'doesnotendwith'
  ) {
    return valueIsField ? `'%' || ${value}` : `'%${value}'`;
  } else if (typeof value === 'boolean') {
    return `${value}`.toUpperCase();
  }
  return valueIsField ? `${value}` : `'${value}'`;
};

/**
 * @param {CB_RuleGroupType} newQuery
 * @param {{
 *  format?: "json_only_query_attributes" | "sql" | "sql_query"
 *  jsonTabSpaces?: number,
 *  valueProcessor?:
 *  (
 *   field: string,
 *   operator:string,
 *   value: string | [{value: string}]
 *  ) => string
 * }} [options]
 */
function formatQuery(newQuery, options) {
  const fallbackExpression = '(1 = 1)';
  const format = options?.format ?? 'sql_query';
  const valueProcessor = options?.valueProcessor ?? defaultValueProcessor;

  if (format === 'json_only_query_attributes') {
    const newJSON = JSON.stringify(
      newQuery,
      [
        'version',
        'rules',
        'combinator',
        'groupStart',
        'field',
        'value',
        'operator',
        'groupEnd',
      ],
      options.jsonTabSpaces,
    );

    return newJSON;
  }

  const queryExpressions = newQuery.rules?.map((rule) => {
    const valueSource = 'value';
    const value = valueProcessor(
      rule.field,
      rule.operator,
      rule.value,
      valueSource,
    );
    const combinator = rule.combinator || '';
    const { field } = rule;
    const operator = mapOperator(rule.operator);
    const groupStart = rule.groupStart || '';
    const groupEnd = rule.groupEnd || '';
    // eslint-disable-next-line max-len
    const q = `${combinator} ${groupStart}${field} ${operator} ${value}${groupEnd}`;

    return q.trim();
  });

  const conditions =
    queryExpressions?.length > 0
      ? queryExpressions.join(' ')
      : fallbackExpression;

  let sqlQuery = conditions;
  if (format === 'sql_query') {
    sqlQuery = `SELECT * FROM campaign_builder WHERE${conditions}`;
  }

  return sqlQuery;
}

export { formatQuery, defaultValueProcessor };
