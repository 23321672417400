/* eslint-disable react/jsx-props-no-spreading */
// @ts-check
import React from 'react';
import ReactSelect, { components } from 'react-select';
import { createGlobalStyle } from 'styled-components';

import { dropdownStyles } from './dropdown-config';

const GlobalStyle = createGlobalStyle`
  .menu {
    animation: fadeIn 0.2s ease-in-out;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

/**
 * @param {import('react-select').Props} props
 */
const Dropdown = ({ onChange, ...props }) => (
  <>
    <GlobalStyle />
    <ReactSelect
      {...props}
      components={{
        Menu: (props) => <components.Menu {...props} className="menu" />,
        ...props.components,
      }}
      styles={dropdownStyles}
      onChange={onChange}
    />
  </>
);

export { Dropdown };
