// @ts-check

const actionTypes = /** @type {const} */ ({
  INITIALISED: 'INITIALISED',
  HANDLE_REDIRECT_COMPLETE: 'HANDLE_REDIRECT_COMPLETE',
  GET_ACCESS_TOKEN_COMPLETE: 'GET_ACCESS_TOKEN_COMPLETE',
  SET_USER_ROLES: 'SET_USER_ROLES',
  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR',
});

export { actionTypes };
