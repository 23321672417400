// @ts-check

import React from 'react';

/**
 * @param {{
 *    searchText: string
 *    onChange: (e) => void
 * }} props
 */
function SearchText({ searchText, onChange }) {
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
      }}
    >
      <span
        className="fa-solid fa-magnifying-glass"
        style={{
          position: 'absolute',
          top: '50%',
          left: '28px',
          transform: 'translateY(-40%)',
          pointerEvents: 'none',
          color: '#6e6e6e',
        }}
      />
      <div
        className="form-group"
        id="search-text-field"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <input
          data-testid="search-text"
          type="text"
          placeholder="Search"
          aria-describedby="error-search-text"
          aria-required="true"
          required
          name="searchText"
          id="searchText"
          value={searchText}
          autoComplete="off"
          autoFocus
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            marginTop: '20px',
            paddingLeft: '25px',
            width: '90%',
          }}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export { SearchText };
