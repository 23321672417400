// @ts-check

/** @type {Readonly<CampaignModel>} */
const defaultCampaignModel = {
  campaignId: '',
  campaignName: '',
  campaignDescription: '',
  campaignPurpose: {
    purposes: [],
    other: {
      id: 'other-purpose',
      checked: false,
      value: '',
    },
  },
  outreachChannel: {
    channels: [],
    other: {
      id: 'other-channel',
      checked: false,
      value: '',
    },
  },
  isListShared: false,
  isLogicShared: false,
  logic: null,
  published: false,
  createdByName: '',
  createdAt: '',
  lastUpdated: '',
  calculatedMatchSummary: /** @type {CalculatedMatchSummary} */ ({}),
  details: {
    statements: [],
    sources: [],
    users: [],
  },
};

export { defaultCampaignModel };
