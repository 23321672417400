// @ts-check

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDisplayContent } from 'core/services';
import { setHttpError } from './sharedSlice';

/** @type { DisplayContentState } */
const initialState = {
  loading: false,
  displayContent: {
    censusDate: '',
    currentTerm: '',
    lastRefreshDate: '',
    nextRegularTerm: '',
    nextTerm: '',
  },
  lastModified: '',
};

export const displayContentSlice = createSlice({
  name: 'displayContent',
  initialState,
  reducers: {
    setDisplayContentLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDisplayContent: (state, action) => {
      state.displayContent = action.payload.displayContent;
      state.lastModified = action.payload.lastModified;
    },
  },
});

export const getDisplayContentAsync = createAsyncThunk(
  'displayContent/getDisplayContentAsync',
  /**
   * Instead of any, could specify the shape type of the payload if applicable.
   * @param {DisplayContentResponse} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setDisplayContentLoading(true));
    dispatch(
      setDisplayContent({
        displayContent: {},
      }),
    );
    try {
      // Make the API call.
      const { displayContent, lastModified } = await getDisplayContent();

      if (displayContent) {
        // Set the payload that is passed into setDisplayContent().
        dispatch(setDisplayContent({ displayContent, lastModified }));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getDisplayContentAsync.typePrefix,
        }),
      );
    }
    dispatch(setDisplayContentLoading(false));
  },
);

export const { setDisplayContentLoading, setDisplayContent } =
  displayContentSlice.actions;

export default displayContentSlice.reducer;
