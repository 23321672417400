// @ts-check

/**
 * Full list of configuration can be found
 * at https://react-querybuilder.js.org/docs/api/querybuilder#operators
 */

const defaultOperators = [
  { name: '=', label: '=' },
  { name: '!=', label: '!=' },
  { name: '<', label: '<' },
  { name: '>', label: '>' },
  { name: '<=', label: '<=' },
  { name: '>=', label: '>=' },
  { name: 'contains', label: 'contains' },
  { name: 'beginsWith', label: 'begins with' },
  { name: 'endsWith', label: 'ends with' },
  { name: 'doesNotContain', label: 'does not contain' },
  { name: 'doesNotBeginWith', label: 'does not begin with' },
  { name: 'doesNotEndWith', label: 'does not end with' },
  { name: 'null', label: 'is null' },
  { name: 'notNull', label: 'is not null' },
  { name: 'in', label: 'in' },
  { name: 'notIn', label: 'not in' },
  { name: 'between', label: 'between' },
  { name: 'notBetween', label: 'not between' },
];

const customOperators = [
  { name: '=', label: 'equal to' },
  { name: '!=', label: 'not equal to' },
  { name: '<', label: 'less than' },
  { name: '>', label: 'greater than' },
  { name: '<=', label: 'less or equal to' },
  { name: '>=', label: 'greater or equal to' },
  { name: 'in', label: 'includes' },
  { name: 'notIn', label: 'not includes' },
];

const operators = defaultOperators.map(
  (op) => customOperators.find((cop) => cop.name === op.name) || op,
);

const combinators = [
  { name: 'and', label: 'and' },
  { name: 'or', label: 'or' },
];

const translations = {
  fields: {
    title: 'Fields',
  },
  operators: {
    title: 'Operators',
  },
  value: {
    title: 'Value',
  },
  removeRule: {
    label: 'x',
    title: 'Remove rule',
  },
  removeGroup: {
    label: 'x',
    title: 'Remove group',
  },
  addRule: {
    label: '+Add rule',
    title: 'Add rule',
  },
  addGroup: {
    label: '+Add group',
    title: 'Add group',
  },
  combinators: {
    title: 'Combinators',
  },
};

/** @type {RuleGroupType} */
const defaultQuery = {
  id: 'root',
  combinator: 'and',
  not: false,
  rules: [
    {
      field: 'Campus',
      value: '',
      operator: '=',
    },
  ],
};

const plainTextOperators = [
  'contains',
  'beginsWith',
  'endsWith',
  'doesNotContain',
  'doesNotBeginWith',
  'doesNotEndWith',
];
const equalityOperators = ['=', '!='];
const numericOperators = ['<', '>', '<=', '>='];
const inOperators = ['in', 'notIn'];
const betweenOperators = ['between', 'notBetween'];
const multiValueOperators = [...inOperators, ...betweenOperators];

// const groupedOperators = [
//   {
//     label: "-",
//     children: equalityOperators.map((op) => ({
//       value: op.name,
//       label: op.label,
//     }))
//   },
//   { label: "A root option", value: "value_3" },
//   { label: "Another root option", value: "value_4" }
// ];

export {
  translations,
  operators,
  combinators,
  defaultQuery,
  inOperators,
  betweenOperators,
  multiValueOperators,
  customOperators,
  equalityOperators,
  numericOperators,
  plainTextOperators,
};
