/* eslint-disable react/jsx-props-no-spreading */
// @ts-check

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { GroupDropdown } from 'core/components/Dropdown';
import { ErrorFeedback } from 'core/components/ErrorValidation';
import { formatQuery } from '../../utils/format-query';
import {
  numericOperators,
  plainTextOperators,
  combinators as rawCombinators,
  operators as rawOperators,
} from '../../utils/query-builder-config';

import {
  ButtonLink,
  Cell,
  Container,
  Grid,
  GridBody,
  GridHeader,
  GridScrollBody,
  RemoveButton,
  Row,
  RowHeader,
} from './index.style';
import { QBDropdown, ValueEditor } from './ValueEditor';

/** @type {OptionsOrGroups} */
const grouping = [
  {
    value: '',
    label: <span>&nbsp;</span>,
  },
  {
    value: '(',
    label: '(',
  },
  {
    value: ')',
    label: ')',
  },
];

/** @type {OptionsOrGroups} */
const startGroup = [grouping[0], grouping[1]];

/** @type {OptionsOrGroups} */
const endGroup = [grouping[0], grouping[2]];

/** @type {OptionsOrGroups} */
const operators = rawOperators.map((op) => ({
  value: op.name,
  label: op.label,
}));
/** @type {OptionsOrGroups} */
const combinators = rawCombinators.map((op) => ({
  value: op.name,
  label: op.label,
}));

//#region default rules
export const defaultRule = /** @type {CB_RuleType} */ ({
  id: Math.random().toString(),
  combinator: '',
  groupStart: '',
  field: '',
  operator: '',
  value: '',
  groupEnd: '',
  values: [],
  style: null,
  combinatorHasError: true,
  fieldHasError: true,
  operatorHasError: true,
});

const buildEmptyRule = () => ({ ...defaultRule, id: Math.random().toString() });

const getDefaultQuery = () => {
  /** @type {CB_RuleGroupType} */
  const defaultQuery = {
    version: '2.0',
    id: `${Math.random()}`,
    rules: [],
  };

  defaultQuery.rules.push(buildEmptyRule());

  return defaultQuery;
};
/**
 * @param {string | object} filterOrValue
 * @param {OptionsOrGroups} options
 * @param {string} [operator]
 * @param {number} [defaultIndex]
 */
// Get the selected value from the current dropdown (e.g. And/Or; Field; Operator; or Value dropdown).
// For example, if the Value dropdown is selected "Y", then "found" variable should be "Y".
// The returned value sets the dropdown's selected state (currently displayed selection).
const getValueOption = (filterOrValue, options, operator, defaultIndex) => {
  // Case of numeric or plain text value.
  if (
    operator &&
    (plainTextOperators.includes(operator) ||
      numericOperators.includes(operator))
  ) {
    return filterOrValue;
  }
  // Case of multi select values.
  if (Array.isArray(filterOrValue)) {
    return filterOrValue;
  }
  // Case of single select value.
  const value =
    typeof filterOrValue === 'object' ? filterOrValue?.value : filterOrValue;
  let found = options?.find((o) => o.value === value);

  // Case of the selected value is array of objects.
  if (!found) {
    options?.forEach((suboptions) => {
      if (found) return;
      found = suboptions.options?.find((o) => o.value === value);
    });
  }

  // Case of array of strings (not array of objects).
  if (!found) {
    found = options?.find((o) => o === value);
  }

  return found || (defaultIndex && options?.[defaultIndex]) || null;
};

/**
 * @param {CB_RuleGroupType} query
 * @return {CB_RuleErrorValidator}
 */
const processRuleRequiredFields = (query) => {
  let errorCounter = 0;
  let groupStartCount = 0;
  let groupEndCount = 0;

  query?.rules?.forEach((rule, index) => {
    // required field validation
    rule.combinatorHasError = index > 0 && !rule.combinator;
    rule.fieldHasError = !rule.field;
    rule.operatorHasError = !rule.operator;
    rule.valueHasError =
      !['null', 'notNull', '', undefined].includes(rule.operator) &&
      !rule.value;

    if (
      rule.combinatorHasError ||
      rule.fieldHasError ||
      rule.operatorHasError ||
      rule.valueHasError
    ) {
      errorCounter++;
    }
    // grouping validation
    if (rule.groupStart === '(') {
      groupStartCount++;
    }
    if (rule.groupEnd === ')') {
      groupEndCount++;
    }

    // return rule;
  });

  const isGroupValid = groupStartCount === groupEndCount;
  const isValid = errorCounter === 0 && isGroupValid;

  return {
    isValid,
    isGroupValid,
  };
};

//#endregion

/**
 * Clone a query object to avoid side effect later on the flow
 * @param {CB_RuleGroupType} query
 */
function cloneQueryObject(query) {
  const queryClone = {
    rules: query.rules.map((rule) => ({ ...rule })),
  };

  return queryClone;
}

/**
 * @param {{
 *   className?: string
 *   testId?: string
 *   id?: string
 *   filters: Field []
 *   defaultValueQuery?: CB_RuleGroupType
 *   valueQuery?: CB_RuleGroupType
 *   readOnly?: boolean,
 *   defaultCombinator?: "And" | "Or" |  "",
 *   checkErrors?: boolean,
 *   maxRows?: number,
 *   applyFilterInProgress?: boolean,
 *   debug?: boolean,
 *   onQueryChange?: (query: QueryFilterValue) => void,
 *   onQueryClean?: () => void,
 *   onRowFieldChange?: (rowIndex: number) => void,
 *   onRowOperatorChange?: (rowIndex: number) => void,
 *   onRowValueChange?: (rowIndex: number) => void,
 *   onApplyFilter?: (query: QueryFilterValue) =>  void,
 *   onCancelApplyFilter?: () =>  void,
 *   onQueryValidate?: (isValid: boolean) =>  void,
 *   onAddRule?: () =>  void,
 *   onRemoveRule?: () =>  void,
 * }} props
 */
function QueryFilterBuilder({
  className,
  testId = 'query-filter-builder',
  id,
  filters = [],
  valueQuery = null,
  defaultValueQuery = null,
  readOnly = false,
  checkErrors = false,
  maxRows,
  debug = false,
  applyFilterInProgress = false,
  onQueryChange = ({ jsonQuery, sqlQuery, styledHtmlQuery }) => null,
  onQueryClean = () => null,
  onApplyFilter = () => null,
  onQueryValidate = () => null,
  onCancelApplyFilter = () => null,
  onAddRule = () => null,
  onRemoveRule = () => null,
  onRowFieldChange = () => null,
  onRowOperatorChange = () => null,
  onRowValueChange = () => null,
}) {
  /** @type {UseRefOf<HTMLDivElement>} */
  const gridRef = useRef();
  const [maxHeight, setMaxHeight] = useState('auto');
  const [queryHasError, setQueryError] = useState(false);
  const [groupHasError, setGroupError] = useState(false);
  const [dirty, setDirty] = useState(false);
  /** @type {UseStateOf<CB_RuleGroupType>} */
  const [stateQuery, setStateQuery] = useState(
    defaultValueQuery ?? getDefaultQuery(),
  );

  /** @type {CB_RuleGroupType} */
  let query = valueQuery || stateQuery;

  //#region edge case: redux might set `valueQuery` or  `stateQuery` readonly
  const valueProperties = Object.getOwnPropertyDescriptors(query).rules;
  if (valueProperties.writable === false) {
    query = cloneQueryObject(query);
  }
  //#endregion

  /** @type {[QueryFilterValue, (query: QueryFilterValue) => void]} */
  const [outputQuery, setOutputQuery] = useState();
  const isControlled = !valueQuery;
  /**
   * @param {CB_RuleType} rule
   */
  const loadOptions = (rule) => {
    if (rule.values) return rule.values;
    const filter = filters.find((f) => f.name === rule.field);
    rule.values = filter?.values;
  };

  const clearQuery = () => {
    setStateQuery(getDefaultQuery());
    setOutputQuery(null);
    onQueryChange({});
    onQueryClean();
  };

  const applyFilter = () => {
    applyFilterInProgress ? onCancelApplyFilter() : onApplyFilter(outputQuery);
  };

  const notifyChanges = useCallback(
    (/** @type {CB_RuleGroupType} */ query) => {
      const queryClone = cloneQueryObject(query);

      if (isControlled) {
        setStateQuery(queryClone);
      }

      onQueryChange({ jsonQuery: queryClone });
    },
    [isControlled, onQueryChange],
  );

  /**
   * @param { any | { value: string }[]} selectedValues
   * @param {ActionMeta} actionMeta
   * @param {number} rowIndex
   */
  const valueChange = (selectedValues, actionMeta, rowIndex) => {
    const { value } = selectedValues;
    const name = actionMeta.name;
    const { rules } = query;

    if (name.includes('field')) {
      // Get the chosen field (top-level category).
      // Example, if Enrollment was chosen then get "Enrollment" object with it's "options" array of subcategories.
      const filter = filters.find((category) => {
        return category.options.some((subcategory) => {
          return subcategory.name === value;
        });
      });

      // Get the nested subcategory.
      // Example: if field Enrollment and subcategory Cohort Sessions were selected then
      // return Cohort Sessions with its "values" array.
      const subcategory = filter.options.find((item) => item.name === value);
      rules[rowIndex].values = subcategory.values;
      rules[rowIndex].value = value;
    } else if (name === 'operator') {
      // Reset value field when operator changes.
      rules[rowIndex].value = '';
    }

    if (name === 'value' && Array.isArray(selectedValues)) {
      rules[rowIndex][name] = selectedValues;
    } else {
      rules[rowIndex][name] = value;
    }

    setDirty(true);
  };

  // Get the options that will populate the Values dropdown.
  // Note: getFieldValues should be called before getValueOptions, thus
  // ValueEditor's "values" property appears before "value" in the QueryBuilder HTML below.
  const getFieldValues = (rule, _Filters, rowIndex) => {
    // Get the chosen field (top-level category).
    // Example, if Enrollment was chosen then get "Enrollment" object with it's "options" array of subcategories.
    const filter = filters.find((category) => {
      return category.options.some((subcategory) => {
        return subcategory.name === rule.field;
      });
    });

    if (filter && rule.field) {
      const subcategory = filter.options.find(
        (item) => item.name === rule.field,
      );
      rule.values = subcategory.values;

      // The react-select component expects its options to be passed in as an array of objects.
      // https://react-select.com/home#getting-started
      // Example: { value: 'Tempe', label: 'Tempe' }
      // If value is a string, transform into object for react-select component.
      if (typeof rule?.values[0] === 'string') {
        const multiValues = rule.values?.map((v) => ({
          label: v,
          value: v,
        }));

        rule.values = multiValues;
      }

      return rule.values;
    }
  };

  const addRule = () => {
    notifyChanges({
      ...query,
      rules: [...query.rules, { ...buildEmptyRule() }],
    });
    setDirty(true);
    onAddRule?.();
  };

  const removeRule = (rowIndex = -1) => {
    // Remove the user-selected Campaign Filter rule from the current list.
    const newRules = query.rules.filter((_, index) => index !== rowIndex);

    // After first rule is removed, clear the selections for the
    // combinator ("and"/"or"), and open parenthesis, and close parenthesis in
    // the new first rule in the list. Note: array length will be 0 if the
    // row being removed is the only row.
    if (rowIndex === 0 && newRules.length !== 0) {
      newRules[0].combinator = '';
      newRules[0].groupStart = '';
      newRules[0].groupEnd = '';
    }

    notifyChanges({
      ...query,
      rules: newRules,
    });
    setDirty(true);
    onRemoveRule?.();
  };

  const validateRequiredFields = useCallback(() => {
    const res = processRuleRequiredFields(query);
    setGroupError(!res.isGroupValid);
    setQueryError(!res.isValid);
    onQueryValidate(res.isValid);
  }, [onQueryValidate, query]);

  useEffect(() => {
    validateRequiredFields();
  }, [validateRequiredFields, query.rules?.length]);

  useEffect(() => {
    if (maxRows) {
      const gridHeader = /** @type {HTMLDivElement} */ (
        document.querySelector('.grid-header')
      );
      const firstRow = /** @type {HTMLDivElement} */ (
        document.querySelector('.grid-body .grid-row:first-child')
      );
      const GAP_OFFSET = 8; // 0.5rem == around 8px
      const bodyHeight =
        GAP_OFFSET * maxRows +
        firstRow?.offsetHeight * maxRows +
        gridHeader?.offsetHeight;

      setMaxHeight(bodyHeight + 'px');
    }
  }, [maxRows]);

  useEffect(() => {
    const element = document.querySelector('.grid-scroll-body');

    const closeMenu = () => {
      gridRef?.current?.focus();
      console.log('close menu', gridRef?.current?.className);
    };

    element?.addEventListener('scroll', closeMenu);

    return () => {
      element?.removeEventListener('scroll', closeMenu);
    };
  }, []);

  return (
    <Container
      className={className}
      id={id}
      data-disabled={applyFilterInProgress}
    >
      <Grid
        ref={gridRef}
        tabIndex={0}
        data-aria-readonly={readOnly || applyFilterInProgress}
        data-show-error={queryHasError && (dirty || checkErrors)}
        data-error={queryHasError}
        data-grouping-error={groupHasError}
        className={`grid-rules ${groupHasError ? 'is-invalid' : ''}`}
      >
        <GridHeader className="grid-header">
          <RowHeader className="grid-row row-header">
            <Cell className="header">
              <strong>Campaign Filters ({query.rules?.length})</strong>
            </Cell>
          </RowHeader>

          {(queryHasError && (dirty || checkErrors)) || groupHasError ? (
            <Row className="row-error">
              {groupHasError && (
                <ErrorFeedback
                  id="group-error"
                  error="Please check all parenthesis are all closed."
                />
              )}
              {queryHasError && (
                <ErrorFeedback
                  id="query-error"
                  error="Please provide valid value for all required fields."
                />
              )}
            </Row>
          ) : null}
          <Row className="grid-row row-header row-border">
            <Cell className="combinator">
              <span>And / Or</span>
            </Cell>

            <Cell className="groupStart">
              <span>(</span>
            </Cell>

            <Cell className="field">
              <span>Field</span>
            </Cell>

            <Cell className="operator">
              <span>Operator</span>
            </Cell>

            <Cell className="value">
              <span>Value</span>
            </Cell>

            <Cell className="groupEnd">
              <span>)</span>
            </Cell>

            <Cell className="remove-button">
              <span>&nbsp;</span>
            </Cell>
          </Row>
        </GridHeader>
        <GridScrollBody
          className={`grid-scroll-body ${
            query?.rules?.length > maxRows ? 'scroll' : ''
          }`}
          style={{
            maxHeight,
          }}
        >
          <GridBody className="grid-body">
            {query?.rules?.map((rule, index) => {
              loadOptions(rule);

              let isRowDisabled =
                readOnly ||
                applyFilterInProgress ||
                (index > 0 && !rule.combinator);

              let isCombinatorDisabled =
                index === 0 || readOnly || applyFilterInProgress;

              const ruleId = rule.id || Math.random().toString();
              return (
                <Row
                  className="grid-row row-data no-label"
                  key={ruleId}
                  style={rule.style}
                  data-row-index={index}
                >
                  <Cell
                    data-testid={`${testId}-combinator-${index}`}
                    className="combinator"
                    data-has-error={rule.combinatorHasError}
                  >
                    <label>And / Or</label>
                    <QBDropdown
                      name="combinator"
                      value={getValueOption(rule.combinator, combinators)}
                      options={combinators}
                      placeholder=""
                      isSearchable={false}
                      isDisabled={isCombinatorDisabled}
                      onChange={(newValue, actionMeta) => {
                        valueChange(newValue, actionMeta, index);
                        validateRequiredFields();
                        notifyChanges(query);
                      }}
                    />
                  </Cell>
                  <Cell
                    data-testid={`${testId}-group-start-${index}`}
                    className="groupStart"
                    data-grouping={rule.groupStart}
                  >
                    <label>(</label>
                    <QBDropdown
                      className="grouping"
                      name="groupStart"
                      value={getValueOption(rule.groupStart, grouping)}
                      options={startGroup}
                      placeholder=""
                      isSearchable={false}
                      isDisabled={isRowDisabled}
                      onChange={(newValue, actionMeta) => {
                        valueChange(newValue, actionMeta, index);
                        validateRequiredFields();
                        notifyChanges(query);
                      }}
                    />
                  </Cell>
                  <Cell
                    data-testid={`${testId}-field-${index}`}
                    className="field"
                    data-has-error={rule.fieldHasError}
                  >
                    <label>Field</label>
                    <GroupDropdown
                      name="field"
                      value={getValueOption(rule.field, filters)}
                      options={filters}
                      placeholder="Select filter field"
                      isDisabled={isRowDisabled}
                      onChange={(newValue, actionMeta) => {
                        valueChange(newValue, actionMeta, index);
                        validateRequiredFields();
                        notifyChanges(query);
                        onRowFieldChange(index);
                      }}
                    />
                  </Cell>
                  <Cell
                    data-testid={`${testId}-operator-${index}`}
                    className="operator"
                    data-has-error={rule.operatorHasError}
                  >
                    <label>Operator</label>
                    <QBDropdown
                      name="operator"
                      value={getValueOption(rule.operator, operators)}
                      options={operators}
                      placeholder=""
                      isDisabled={isRowDisabled || !rule.field}
                      onChange={(newValue, actionMeta) => {
                        valueChange(newValue, actionMeta, index);
                        validateRequiredFields();
                        notifyChanges(query);
                        onRowOperatorChange(index);
                      }}
                    />
                  </Cell>
                  <Cell
                    data-testid={`${testId}-value-${index}`}
                    className="value"
                    data-has-error={rule.valueHasError}
                  >
                    <label>Value</label>
                    <ValueEditor
                      values={getFieldValues(rule, filters, index)}
                      value={getValueOption(
                        rule.value,
                        rule.values,
                        rule.operator,
                      )}
                      operator={rule.operator}
                      handleOnChange={(newValue, actionMeta) => {
                        valueChange(newValue, actionMeta, index);
                        notifyChanges(query);
                        onRowValueChange(index);
                      }}
                      index={index}
                      isDisabled={isRowDisabled}
                    />
                  </Cell>
                  <Cell
                    data-testid={`${testId}-group-end-${index}`}
                    className="groupEnd"
                    data-grouping={rule.groupEnd}
                  >
                    <label>)</label>
                    <QBDropdown
                      className="grouping"
                      name="groupEnd"
                      value={getValueOption(rule.groupEnd, grouping)}
                      options={endGroup}
                      placeholder=""
                      isSearchable={false}
                      isDisabled={isRowDisabled}
                      onChange={(newValue, actionMeta) => {
                        valueChange(newValue, actionMeta, index);
                        validateRequiredFields();
                        notifyChanges(query);
                      }}
                    />
                  </Cell>
                  <Cell
                    data-testid={`${testId}-remove-button-${index}`}
                    className="remove-button"
                  >
                    <label>&nbsp;</label>
                    <RemoveButton
                      role="button"
                      tabIndex={0}
                      className="btn btn-sm text-maroon"
                      onClick={() => removeRule(index)}
                    >
                      <i className="fas fa-times" />
                    </RemoveButton>
                  </Cell>
                </Row>
              );
            })}
          </GridBody>
        </GridScrollBody>
      </Grid>
      {!readOnly ? (
        <footer>
          <div className="mt-2">
            <ButtonLink
              data-testid={`${testId}-btn-add-filter`}
              className="text-maroon"
              disabled={applyFilterInProgress}
              onClick={addRule}
            >
              +Add filter
            </ButtonLink>
          </div>
          <div style={{ marginTop: 20 }}>
            <hr style={{ marginBlock: '1rem' }} />
            <p hidden={queryHasError || groupHasError}>
              Note: Clicking the "Apply Filters" button will save your campaign.
              The "Save Campaign" button (at the bottom of this page) will
              automatically change to "Update Campaign" to indicate that the
              campaign has been created. You are still able to modify your saved
              campaign until the status is changed to Published.
            </p>
            <button
              data-testid={`${testId}-btn-cancel-filter`}
              type="button"
              className={`btn btn-sm ${
                applyFilterInProgress ? 'btn-gold' : 'btn-maroon'
              }`}
              disabled={queryHasError || groupHasError}
              onClick={applyFilter}
            >
              {applyFilterInProgress ? 'Cancel Filters' : 'Apply Filters'}
            </button>
            &nbsp;
            <button
              data-testid={`${testId}-btn-clear`}
              type="button"
              className="btn btn-sm btn-gray"
              disabled={applyFilterInProgress}
              onClick={clearQuery}
            >
              Clear All
            </button>
          </div>
        </footer>
      ) : null}

      {debug && (
        <code>
          <pre style={{ marginTop: '2rem', whiteSpace: 'pre-wrap' }}>
            {formatQuery(query, {
              // format: 'json_only_query_attributes',
              format: 'sql_query',
              jsonTabSpaces: 4,
            })}
          </pre>
        </code>
      )}
    </Container>
  );
}

export { QueryFilterBuilder };
