// @ts-check
import React from 'react';
import styled from 'styled-components';

/**
 *
 * @param {{
 *  id?: string,
 *  errors:  string []
 * }} props
 * @returns
 */
const ErrorValidation = ({ id, errors = [] }) => {
  const listErrors = errors.filter((err) => err);
  return (
    <small id={id} className="form-text invalid-feedback">
      {listErrors.map((error, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={'error-' + index}>
          <span title="Alert" className="fa fa-icon fa-exclamation-triangle" />
          &nbsp;{error}
        </div>
      ))}
    </small>
  );
};

const ErrorContainer = styled.small`
  width: 100%;
  margin-top: 0.25rem;
  color: #b72a2a;
  font-size: 90%;
  display: inline-block;
  font-weight: 700;
`;

const ErrorFeedback = ({ error, id }) => {
  return (
    <ErrorContainer id={id}>
      <div>
        <span title="Alert" className="fa fa-icon fa-exclamation-triangle" />
        &nbsp;{error}
      </div>
    </ErrorContainer>
  );
};

export { ErrorValidation, ErrorFeedback };
