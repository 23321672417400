// @ts-check

const routerUrl = /** @type {const} */ {
  DEV_LOGIN: '/dev-login',
  NONE: '',
  HOME: '/',
  CAMPAIGN_SEARCH: '/campaign/search',
  CAMPAIGN_SCHEDULE: '/campaign/schedule',
  //#region Campaign create
  // metadata
  CAMPAIGN_CREATE_DATA: '/campaign/data/create',
  CAMPAIGN_VIEW_DATA: '/campaign/data/view/:id',
  CAMPAIGN_UPDATE_DATA: '/campaign/data/update/:id',
  // logic filters
  CAMPAIGN_DEFINE_FILTER: '/campaign/filters/create',
  CAMPAIGN_VIEW_FILTER: '/campaign/filters/view/:id',
  CAMPAIGN_UPDATE_FILTER: '/campaign/filters/update/:id',
  // details
  CAMPAIGN_DETAILS: '/campaign/detail/:id',
  //#endregion
  REPORTING: '/reporting',
  SUPPORT: '/support',
  ADMIN: '/admin',
  ANNOUNCEMENT_VIEW_DATA: '/announcement/view/:id',
  LOG_OUT: '/logout',
  PAGE_NOT_AUTHORIZED: '/user-not-authorized',
  PAGE_NOT_FOUND: '/404',
};

const GUID_REG_EX =
  `[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-` +
  `[89ab][0-9a-f]{3}-[0-9a-f]{12}`;
const ROUTE_CAMPAIGN_UPDATE_REGEX = new RegExp(
  `.*/campaign/(data|filters)/update/` + GUID_REG_EX,
);
const ROUTE_CAMPAIGN_VIEW_REGEX = new RegExp(
  `.*/campaign/(data|filters)/view/` + GUID_REG_EX,
);
const ROUTE_CAMPAIGN_CREATE_REGEX = new RegExp(
  `.*/campaign/(data|filters)/create`,
);

/**
 * @param {string} route
 * @param {string} id
 * @returns {string}
 */
function resolveRouteWithId(route, id) {
  return route.replace(':id', id);
}

function isRouteView() {
  const url = window.location.pathname;
  return url.match(ROUTE_CAMPAIGN_VIEW_REGEX)?.length > 0;
}

function isRouteUpdate() {
  const url = window.location.pathname;
  return url.match(ROUTE_CAMPAIGN_UPDATE_REGEX)?.length > 0;
}

function isRouteCreate() {
  const url = window.location.pathname;
  return url.match(ROUTE_CAMPAIGN_CREATE_REGEX)?.length > 0;
}

export {
  routerUrl,
  resolveRouteWithId,
  isRouteView,
  isRouteCreate,
  isRouteUpdate,
};
