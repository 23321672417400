// @ts-check

import { routerUrl } from 'core/constants/router-url';

const name = process.env.APP_NAME || 'campaignbuilder';
const defaultClientId = `acan-campaign-builder-public-nonprod`;
const defaultScope = 'https://campaignbuilder-qa.apps.asu.edu/read:self';
const defaultAuthUrl =
  'https://weblogin.asu.edu/serviceauth/oauth2/native/allow';
const defaultTokenUrl =
  'https://weblogin.asu.edu/serviceauth/oauth2/native/token';
const defaultRefreshTokenUrl = `https://weblogin.asu.edu/serviceauth/oauth2/token`;

let authUrl = process.env.AUTH_URL || defaultAuthUrl;
if (window.location.hostname === 'localhost') {
  authUrl = `http://localhost:3000${routerUrl.DEV_LOGIN}`;
}
/** @type {Readonly<AuthSettings>} */
const appSettings = {
  name,
  authUrl,
  websocketUrl: process.env.REACT_APP_WEBSOCKET_ENDPOINT,
  tokenUrl: process.env.TOKEN_URL || defaultTokenUrl,
  refreshTokenUrl: process.env.REFRESH_TOKEN_URL || defaultRefreshTokenUrl,
  clientId: process.env.REACT_APP_CLIENT_ID || defaultClientId,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL || window.location.origin,
  scopes: [process.env.REACT_APP_SCOPE || defaultScope],
  loginPath: process.env.REACT_APP_LOGIN_PATH || '/login',
  clientSecret: process.env.CLIENT_SECRET || 'serviceauth-public-agent',
  dplBaseUrl:
    process.env.DPL_BASE_URL || 'https://api.myasuplat-dpl.asu.edu/api',
  refreshTokenKey: `${name}.jwt.refresh.token`,
  refreshTokenKeyLastTimeRespond: `${name}.jwt.refresh.token.last-time-respond`,
  tokenKey: `${name}.jwt.token`,
  tokenKeyFirstTimeRespond: `${name}.jwt.token.first-time-respond`,
  codeVerifierKey: `${name}.serviceauth.codeVerifier`,
  stateKey: `${name}.serviceauth.state`,
  jwtKey: `${name}.jwt.token`,
  returnToKey: `${name}.app.returnTo`,
  appStateKey: `${name}.app.state`,
};

const isDevMode = process.env.NODE_ENV === 'development';
const useMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';
const MOCK_SERVER_BASE_URL = 'http://localhost:4000';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const isStorybook = process.env.STORYBOOK === 'true';
if (useMockData) {
  // @ts-ignore
  appSettings.websocketUrl = 'ws://localhost:4000/mock-ws';
}

export {
  appSettings,
  apiBaseUrl,
  isStorybook,
  isDevMode,
  useMockData,
  MOCK_SERVER_BASE_URL,
};
