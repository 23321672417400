import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllAnnouncements } from 'core/services';
import { setHttpError } from './sharedSlice';

/** @type { AnnouncementListState} */
const initialState = {
  announcements: [],
  isLastPage: true,
  loading: false,
};

export const announcementListSlice = createSlice({
  name: 'announcementList',
  initialState,
  reducers: {
    setAllAnnouncementsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllAnnouncements: (state, action) => {
      const { announcements, isLastPage } = action.payload;
      state.announcements = announcements;
      state.isLastPage = isLastPage;
    },
  },
});

export const getAllAnnouncementsAsync = createAsyncThunk(
  'announcementList/getAllAnnouncementsAsync',
  /** @param {PaginationParams} payload */
  async (payload, { dispatch }) => {
    dispatch(setAllAnnouncementsLoading(true));
    dispatch(setAllAnnouncements([]));
    try {
      const { announcements, isLastPage } = await getAllAnnouncements(payload);
      if (announcements) {
        dispatch(setAllAnnouncements({ announcements, isLastPage }));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getAllAnnouncementsAsync.typePrefix,
        }),
      );
    }
    dispatch(setAllAnnouncementsLoading(false));
  },
);

export const { setAllAnnouncementsLoading, setAllAnnouncements } =
  announcementListSlice.actions;

export default announcementListSlice.reducer;
