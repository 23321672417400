// @ts-check
import axios from 'axios';
import {
  apiBaseUrl,
  MOCK_SERVER_BASE_URL,
  useMockData,
} from 'core/constants/app-settings';
import { createAuthHeaders } from 'core/services/auth-service';

const API = axios.create({
  baseURL: apiBaseUrl,
});

useMockData &&
  API.interceptors.request.use((request) => {
    const proxyRoutes = [
      {
        url: '/me',
        method: 'get',
      },
      {
        url: '/filters',
        method: 'get',
      },
      {
        url: '/campaign',
        method: 'get',
      },
      {
        url: /(.*?)\/campaign\/(.*?)/,
        method: 'get',
      },
      {
        url: /(.*?)\/campaign\/(.*?)/,
        method: 'put',
      },
    ];

    const res = proxyRoutes.some(
      ({ url, method }) =>
        request.url.match(url) && request.method.includes(method),
    );

    if (res) {
      request.baseURL = MOCK_SERVER_BASE_URL;
    }

    return request;
  });

/**
 * @param {string} url
 * @param {AxiosRequestConfig} config
 */
const fetchData = async (url, searchParams = {}, config = {}) => {
  return API.get(url, {
    params: searchParams,
    headers: await createAuthHeaders(),
    ...config,
  });
};

/**
 * @param {string} url
 * @param {any} data
 * @param {AxiosRequestConfig} config
 */
const postData = async (url, data, config = {}) => {
  return API.post(url, data, {
    headers: await createAuthHeaders(),
    ...config,
  });
};

/**
 * @param {string} url
 * @param {any} data
 * @param {AxiosRequestConfig} config
 */
const putData = async (url, data, config = {}) => {
  return API.put(url, data, {
    headers: await createAuthHeaders(),
    ...config,
  });
};

export { API, fetchData, postData, putData };
