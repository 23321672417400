/* eslint-disable camelcase */
// @ts-check
import axios from 'axios';
import Lock from 'browser-tabs-lock';
import { logger } from 'core';
import { appSettings } from 'core/constants/app-settings';
import { decode } from 'jsonwebtoken';
import { getTokenFromStorage } from '../utils/get-token-from-storage';
import {
  bufferToBase64UrlEncoded,
  createRandomString,
  encode,
  sha256,
} from '../utils/utils';
import { AuthError } from './auth-error';

const lock = new Lock();

/** @return {Promise<void>}  */
export async function loginWithRedirect() {
  const url = await buildAuthorizeUrl();
  window.location.assign(url);
}

async function buildAuthorizeUrl(options) {
  const state = encode(createRandomString());
  const codeVerifier = createRandomString();
  const codeChallengeBuffer = await sha256(codeVerifier);
  const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer);

  sessionStorage.setItem(appSettings.codeVerifierKey, codeVerifier);
  sessionStorage.setItem(appSettings.stateKey, state);
  sessionStorage.setItem(
    appSettings.returnToKey,
    `${window.location.pathname}${window.location.search}`,
  );

  let url = appSettings.authUrl;
  url += '?response_type=code';
  url += '&client_id=' + encodeURIComponent(appSettings.clientId);
  url += '&redirect_uri=' + encodeURIComponent(appSettings.redirectUrl);
  url += '&state=' + encodeURIComponent(state);
  url += '&code_challenge_method=S256';
  url += '&code_challenge=' + codeChallenge;
  url += '&scope=' + encodeURIComponent(appSettings.scopes.join(' '));
  console.log(`redirecting to auth server [${url}]`);
  return url;
}

export async function handleRedirectCallback() {
  const searchParams = new URLSearchParams(window.location.search);

  if (Array.from(searchParams).length === 0) {
    throw new AuthError('There are no query params available for parsing.');
  }

  // const state = searchParams.get('state');
  const code = searchParams.get('code');

  // const storedState = sessionStorage.getItem(appSettings.stateKey);
  const codeVerifier = sessionStorage.getItem(appSettings.codeVerifierKey);
  let returnTo = sessionStorage.getItem(appSettings.returnToKey);

  sessionStorage.removeItem(appSettings.stateKey);
  sessionStorage.removeItem(appSettings.codeVerifierKey);
  sessionStorage.removeItem(appSettings.returnToKey);

  // TODO: the ASU AuthService looks using a different encoding strategy
  // so this match would not always work. e.g. the char `+` is replaced with ` `
  // if (state !== storedState) {
  //   throw new AuthError('State values do not match.');
  // }

  const response = await axios.post(
    appSettings.tokenUrl,
    new URLSearchParams({
      grant_type: 'authorization_code',
      code,
      redirect_uri: appSettings.redirectUrl,
      client_id: appSettings.clientId,
      client_secret: appSettings.clientSecret,
      code_verifier: codeVerifier,
    }).toString(),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  const { data } = response;

  sessionStorage.setItem(appSettings.jwtKey, data.access_token);
  sessionStorage.setItem(appSettings.refreshTokenKey, data.refresh_token);
  sessionStorage.setItem(
    appSettings.tokenKeyFirstTimeRespond,
    new Date().toLocaleString(),
  );
  // If user is logging in from login page, return to redirect url.
  // Note: made returnTo nullable to work with auth-service.test.js handleRedirectCallback() unit test.
  if (returnTo?.startsWith(appSettings.loginPath)) returnTo = '/';

  return { returnTo };
}

export async function requestRefreshToken() {
  logger.group('Auth - refresh Token session');

  try {
    const refresh_token = sessionStorage.getItem(appSettings.refreshTokenKey);
    const url = appSettings.refreshTokenUrl;
    const { data } = await axios.post(
      url,
      new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token,
        client_id: appSettings.clientId,
        /* NB: don't use `encodeURIComponent(appSettings.scopes.join(' '))
         or encodeURI` the response would return
         `{ access_token: 'xxx ,  scope: []}`
         for which the new access_token would not be valid  */
        scope: appSettings.scopes.join(' '),
      }).toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    sessionStorage.setItem(appSettings.jwtKey, data.access_token);
    sessionStorage.setItem(
      appSettings.refreshTokenKeyLastTimeRespond,
      new Date().toLocaleString(),
    );
    console.log('Token got refreshed');
  } catch (error) {
    logger.error(error);
    logger.log('Token refresh error: ' + error.message);
  } finally {
    logger.groupEnd();
  }
}

/** @return {Promise<AppJwtToken | undefined>}   */
export async function checkSession() {
  logger.group('Auth - Validate Token session');

  if (!sessionStorage.getItem(appSettings.jwtKey)) {
    console.log('no jwt in session storage');
    return;
  }

  try {
    const jwt = await getTokenSilently();
    return jwt;
  } catch (error) {
    console.log('check session error: ', error);
  } finally {
    console.groupEnd();
  }
}

/**
 * 1. Check storage and verify valid
 * 2. Use refresh token
 * 3. loginWithRedirect
 * @returns {Promise<AppJwtToken | undefined>}
 */
export async function getTokenSilently() {
  const token = getTokenFromStorage(appSettings.jwtKey);
  if (token) return token;

  const retryPromise = async (cb, maxNumberOfRetries = 3) => {
    for (let i = 0; i < maxNumberOfRetries; i++) {
      console.log(`In retryPromise: i=${i}`);
      if (await cb()) {
        return true;
      }
    }

    return false;
  };

  const LOCK_KEY = 'get_token_silently';

  if (await retryPromise(() => lock.acquireLock(LOCK_KEY), 10)) {
    try {
      console.log('checking for tokens again');
      const token = getTokenFromStorage(appSettings.jwtKey);
      if (token) return token;

      console.log('no valid tokens - redirecting to login');
      loginWithRedirect();
    } finally {
      await lock.releaseLock(LOCK_KEY);
    }
  }
}

export function logout() {
  sessionStorage.removeItem(appSettings.jwtKey);
  sessionStorage.removeItem(appSettings.refreshTokenKey);
}

export async function getUser() {
  const token = sessionStorage.getItem(appSettings.jwtKey);
  const decodedToken = /** @type {JwtPayload} */ (decode(token));

  return decodedToken?.email || decodedToken?.sub;
}
