// @ts-check

import React from 'react';
import { Tooltip } from 'core/components';

/**
 * @param {{
 *    nameQuery: string
 *    onChange: (e) => void
 * }} props
 */
function CampaignName({ nameQuery, onChange }) {
  return (
    <div
      className="form-group"
      id="name-query-field"
      style={{ margin: '0 0 1rem 0' }}
    >
      <label>Campaign Name</label>
      <Tooltip tooltipId="name-query-tooltip">
        The name given to the campaign.
      </Tooltip>
      <input
        data-testid="name-query"
        type="text"
        className="form-control"
        style={{ border: '1px solid #BFBFBF' }}
        placeholder="Search by title"
        aria-describedby="error-name-query"
        aria-required="true"
        required
        name="nameQuery"
        id="nameQuery"
        value={nameQuery}
        autoComplete="off"
        autoFocus
        onChange={onChange}
      />
    </div>
  );
}

export { CampaignName };
