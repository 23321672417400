// @ts-check

import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// core
import {
  appRoles,
  AuthContext,
  DataTable,
  DBPagination,
  PageTitle,
  resolveRouteWithId,
  routerUrl,
} from 'core';
import { campaignTableConfig } from 'core/components/Tables/configs';

// state
import { useAppDispatch, useAppSelector } from 'core/store';
import { getCampaignListAsync, setSelectedRoute } from 'core/store/slices';

// Local components.
import { SearchForm } from './components/SearchForm';

const CampaignSearch = () => {
  const history = useHistory();
  const { userRoles } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const {
    campaigns,
    isLastPage,
    loading: campaignListLoading,
  } = useAppSelector((state) => state.campaignList);

  const searchCampaign = useCallback(
    ({ page = 1 } = {}) => {
      dispatch(
        getCampaignListAsync({
          searchFilter: {
            all: userRoles.includes(appRoles.ADMIN),
            page,
            perPage: 50,
          },
        }),
      );
    },
    [dispatch, userRoles],
  );

  useEffect(() => {
    dispatch(setSelectedRoute(routerUrl.CAMPAIGN_SEARCH));
    searchCampaign();
  }, [dispatch, searchCampaign]);

  const pageChange = useCallback(
    (page) => {
      searchCampaign({
        page,
      });
    },
    [searchCampaign],
  );

  return (
    <section>
      <header>
        <PageTitle title="Search campaigns" />
      </header>
      <section>
        <SearchForm />
      </section>
      <DataTable
        testId="tbl-search-campaigns"
        loading={campaignListLoading}
        config={campaignTableConfig}
        rows={campaigns}
        height="520px"
        onCellClick={({ col, id }) => {
          if (col.id === 'view_details') {
            const url = resolveRouteWithId(routerUrl.CAMPAIGN_DETAILS, id);
            history.push(url);
          }
        }}
      />
      <div className="mt-2 d-flex justify-content-end">
        <DBPagination
          loading={campaignListLoading}
          isLastPage={isLastPage}
          onChange={pageChange}
        />
      </div>
    </section>
  );
};

export { CampaignSearch };
