// @ts-check
import React from 'react';

/**
 * @param {{
 * totalRecords: number
 * unmatchedImportedFile: number
 * unmatchedFiltered: number
 * }} props
 */
const ExportFileSummary = ({
  totalRecords,
  unmatchedImportedFile,
  unmatchedFiltered,
}) => {
  return (
    <div data-testid="export-file-summary-container">
      <header>
        <h4>Export File Summary</h4>
      </header>
      <div>
        <strong>Total Export File Records:</strong> {totalRecords || 'n/a'}
        <div>
          <strong>Not Included (File Only):</strong>{' '}
          {unmatchedImportedFile || 'n/a'}
        </div>
        <div>
          <strong>Not Included (CB Only):</strong> {unmatchedFiltered || 'n/a'}
        </div>
      </div>
    </div>
  );
};

export { ExportFileSummary };
