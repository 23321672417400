import React from 'react';
import styled from 'styled-components';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CalendarCardContainer = styled.div`
    border: 1px solid #D0D0D0;
    padding: 20px;
    background-color: #FFFFFF;
`;

const CalendarButton = styled.button`
    margin-right: 12px;
    margin-top: 6px;
`;

const CalendarCard = () => {
  return (
    <CalendarCardContainer>
      <h3>View central outreach calendar</h3>
      <p>
        Student Success Innovations in the Office of the Provost manages a central outreach calendar to help ASU units
        coordinate and optimize communication efforts. Please review the outreach calendar to coordinate your student
        communications.
      </p>
      <a href="https://airtable.com/appSWFBDWOEeaeoDa/shrefsQL38ZcYxyz0/tblciOGfLYMGCtvB4?date=undefined&mode=undefined"
         target="_blank" rel="noopener noreferrer">
        <CalendarButton type="button" className="btn btn-md btn-maroon btn-sm">
          <FontAwesomeIcon icon={faExternalLinkAlt}/> Calendar view
        </CalendarButton>
      </a>
      <a
        href="https://links.asu.edu/studentoutreachlist"
        target="_blank" rel="noopener noreferrer">
        <CalendarButton type="button" className="btn btn-md btn-maroon btn-sm">
          <FontAwesomeIcon icon={faExternalLinkAlt}/> List view
        </CalendarButton>
      </a>
    </CalendarCardContainer>
  );
};

export {
  CalendarCard
};