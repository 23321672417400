// @ts-check
import React, { useState } from 'react';
import styled from 'styled-components';

// core
import { SpinnerLoader } from 'core/components/Loaders';
import { TableContainer } from '../TableContainer';

const Container = styled.div`
  .loader-wrapper {
    margin-block: 1rem;
  }

  .no-data-wrapper {
    text-align: left;
    letter-spacing: 0px;
    color: #191919;
    margin: 1rem;
  }

  td {
    white-space: nowrap;
  }

  .total-record-count {
    min-width: 4ch;
  }
`;

const SortIcon = styled.i.attrs({
  className: 'fas fa-sort',
})`
  margin-left: 0.5rem;
  cursor: pinter;
`;

const TotalRows = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  h2,
  h3,
  h4 {
    margin: 0;
  }
`;

/**
 * @param {PropsWithChildren<{
 *  loading?: boolean
 *  config: TableConfig
 *  columns: String[]
 *  records: String[][]
 *  totalRecordCount: number
 *  caption?: string
 *  captionTotalText?: string
 *  showCaption?: boolean
 *  height?: string
 *  noDataPlaceholder?: string
 *  showNoDataPlaceholder?: boolean
 *  onSort?: (colId: string, colIndex: number) => void
 * }>} props
 */
const CsvDataTable = ({
  loading,
  config,
  columns = [],
  records = [],
  totalRecordCount,
  height,
  noDataPlaceholder = 'No data to show',
  showNoDataPlaceholder = true,
  caption = config.caption,
  captionTotalText = config.captionTotalText,
  showCaption = true,
  onSort = (colKey, colIndex) => null,
}) => {
  const { columns: configColumns } = config;

  const [sortedRows, setSortRows] = useState([]);
  const [sortedColumns, setSortedColumns] = useState(
    new Array(columns.length).fill(false),
  );

  const clientSort = (colIndex = 0) => {
    const isSorted = sortedColumns[colIndex];
    const rows = records.sort((a, b) =>
      isSorted
        ? a[colIndex].localeCompare(b[colIndex])
        : b[colIndex].localeCompare(a[colIndex]),
    );
    setSortRows([...rows]);

    sortedColumns[colIndex] = !isSorted;
    setSortedColumns(sortedColumns);
  };

  const sortTable = (colId = '', colIndex = 0) => {
    config.clientSorting ? clientSort(colIndex) : onSort(colId, colIndex);
  };

  const rows = sortedRows.length > 0 ? sortedRows : records;
  const colMapping = {};
  configColumns.forEach((col) => (colMapping[col.id] = col));

  const totals = records?.length > 0 ? totalRecordCount : 0;

  return (
    <Container data-loading={loading}>
      {showCaption && (
        <TotalRows>
          <h4>{caption}</h4>
          <div>
            <strong>{captionTotalText}</strong>
            <output className="ml-1 total-record-count">
              {!loading ? (
                totals
              ) : (
                <SpinnerLoader
                  spinnerType="circle"
                  width="20px"
                  height="20px"
                />
              )}
            </output>
          </div>
        </TotalRows>
      )}

      <TableContainer
        columFixedLayout
        recordCount={rows.length}
        height={height}
      >
        <table>
          <thead>
            <tr>
              {columns?.map((colName, colIndex) => {
                const col = colMapping[colName];

                return (
                  <th
                    key={colName}
                    scope="col"
                    colSpan={col?.colSpan}
                    data-sortable={col?.sortable}
                    data-sorted={sortedColumns[colIndex]}
                    style={{
                      width: col?.width && 'auto',
                    }}
                    onClick={() =>
                      col?.sortable && sortTable(colName, colIndex)}
                  >
                    {col?.text || colName.replace(/_/g, ' ')}
                    {col?.sortable && <SortIcon />}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!loading &&
              rows.map((row, rowIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={rowIndex}>
                  {columns.map((colName, colIndex) => {
                    const col = colMapping[colName];
                    return (
                      <td
                        key={col?.id || colIndex}
                        style={{
                          textAlign: col?.textAlign || 'left',
                        }}
                      >
                        {row[colIndex]}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>

        {loading && (
          <div className="loader-wrapper">
            <SpinnerLoader
              spinnerType="circle"
              spinnerMessage={[
                'Preview loading...',
                'Preview may take a few minutes to load.',
              ]}
            />
          </div>
        )}

        {!loading && showNoDataPlaceholder && rows.length === 0 && (
          <p className="no-data-wrapper">{noDataPlaceholder}</p>
        )}
      </TableContainer>
    </Container>
  );
};

export { CsvDataTable };
