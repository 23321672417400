// @ts-check

/** @type {TableColumn[]} */
const columns = [
  {
    id: 'asu_email',
    text: 'ASU Email',
  },
  {
    id: 'aa_created_dttm',
    text: 'Created',
    dataType: 'date',
  },
  {
    id: 'aa_lastupd_dttm',
    text: 'Last update',
    dataType: 'date',
  },
];

/** @type {TableConfig} */
const previewTableConfig = {
  caption: 'Campaign List preview',
  captionTotalText: 'Total rows in Campaign list:',
  columns,
  clientSorting: true,
  rowKeyId: null,
};

export { previewTableConfig };
