// @ts-check
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// core
import { CAMPAIGN_STATUS, OPEN_MODE } from 'core/constants/app-constants';
import { defaultCampaignModel } from 'core/model/default-campaign-data';
import { removeFile, uploadFile } from 'core/services/campaign-file-service';
import {
  createCampaign,
  getCampaign,
  updateCampaign,
} from 'core/services/campaign-service';
import { wsResponseType } from 'core/services/web-socket-service';
import { debounce, makeCsvFileNameUnique } from 'core/utils';
import {
  computeMatchSummary,
  mapToCampaignClientModel,
} from 'core/utils/model-mapper-utils';
import {
  persistState,
  removePersistedState,
  retrieveState,
} from 'core/utils/storage-utils';

// state
import { resetCampaignListCache } from './campaignListSlice';
import {
  setErrorMessage,
  setHttpError,
  setInfoMessage,
  setPageLoading,
} from './sharedSlice';

/** @type {CampaignDataState} */
const initialState = {
  loading: false,
  reloadData: true,
  openMode: OPEN_MODE.VIEW,
  filename: null,
  newCampaignId: null,
  campaign: { ...defaultCampaignModel },
  status: undefined,
  resumeSavedState: false,
};

export const campaignDataSlice = createSlice({
  name: 'campaignData',
  initialState,
  reducers: {
    /**
     * @param {{ payload: boolean }} action
     */
    setCampaignLoading: (state, action) => {
      state.loading = action.payload;
    },
    /**
     * @param {{
     *  payload: {
     *    campaign: CampaignModel,
     *  }
     * }} action
     */
    setCampaign: (state, action) => {
      state.campaign = action.payload.campaign;
    },
    setCalculatedMatchSummaryWebsocket: (
      state,
      /**
       * @type {{
       *  payload: {
       *    wsData: WebSocketData,
       *  }
       * }}
       * */ action,
    ) => {
      const { wsData } = action.payload;

      if (wsData.detail_type === wsResponseType.QUERY_RESULTS) {
        state.campaign.calculatedMatchSummary = computeMatchSummary(
          wsData.detail.match_summary,
        );
      }
    },
    /**
     * @param {{ payload: Partial<CampaignDataState> }} action
     */
    setCampaignData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    /**
     * @param {{
     *  payload: Partial<CampaignModel>
     * }} action
     */
    setCampaignField: (state, action) => {
      state.campaign = {
        ...state.campaign,
        ...action.payload,
      };
    },
    resetCampaign: () => {
      removePersistedState();
      return {
        ...initialState,
      };
    },
    /**
     * @param {{ payload: Partial<CampaignModel> }} action
     */
    storeCampaignDraft: (state, action) => {
      const data = {
        ...state,
        campaign: {
          ...state.campaign,
          ...action.payload,
        },
      };
      persistState(data);
    },
    removeCampaignDraft: () => {
      removePersistedState();
    },
    restoreCampaignDraft: (state, action) => {
      const savedState = /** @type {CampaignDataState} */ (retrieveState());

      if (Object.keys(savedState).length > 0) {
        Object.keys(savedState).forEach(
          (key) => (state[key] = savedState[key]),
        );
        state.resumeSavedState = true;
      }
    },
  },
});

export const getCampaignAsync = createAsyncThunk(
  'campaignData/getCampaignAsync',
  /**
   * @param {Partial<CampaignDataState>} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setCampaignLoading(true));
    dispatch(setCampaignData({ campaign: defaultCampaignModel }));

    try {
      const campaign = await getCampaign(payload.campaignId);

      if (campaign) {
        dispatch(
          setCampaignData({
            campaign,
            reloadData: payload.reloadData,
          }),
        );
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getCampaignAsync.typePrefix,
        }),
      );
    }

    dispatch(setCampaignLoading(false));
  },
);

export const createCampaignAsync = createAsyncThunk(
  'campaignData/createCampaignAsync',
  /**
   * @param {any} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setPageLoading(true));
    const { campaign, reloadData, infoMessage } = payload;
    try {
      const { campaign_id: newCampaignId } = await createCampaign(campaign);
      if (newCampaignId) {
        if (campaign.csvFile) {
          const csvFile = {
            ...campaign.csvFile,
            fileNameNoExt: makeCsvFileNameUnique(campaign),
          };
          await uploadFile(newCampaignId, csvFile);
        }

        dispatch(
          setCampaignData({
            newCampaignId,
            reloadData,
            campaign: defaultCampaignModel,
          }),
        );
        dispatch(setInfoMessage(infoMessage));
      }

      dispatch(resetCampaignListCache());
      dispatch(setPageLoading(false));
      removePersistedState();

      return newCampaignId;
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: createCampaignAsync.typePrefix,
        }),
      );
    }
  },
);

export const updateCampaignAsync = createAsyncThunk(
  'campaignData/updateCampaignAsync',
  /**
   * @param {any} payload
   */
  async (payload, { dispatch }) => {
    try {
      dispatch(setPageLoading(true));

      const { campaign, reloadData, infoMessage } = payload;
      campaign.status = campaign.status || CAMPAIGN_STATUS.UPDATED;
      const newCampaignId = campaign.campaignId;

      await updateCampaign(campaign.campaignId, campaign);
      if (campaign.csvFile) {
        const csvFile = {
          ...campaign.csvFile,
          fileNameNoExt: makeCsvFileNameUnique(campaign),
        };
        await uploadFile(campaign.campaignId, csvFile);
      }

      dispatch(
        setCampaignData({
          newCampaignId,
          reloadData,
          campaign: defaultCampaignModel,
        }),
      );
      dispatch(setInfoMessage(infoMessage));
      dispatch(resetCampaignListCache());
      dispatch(setPageLoading(false));

      removePersistedState();

      return newCampaignId;
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: updateCampaignAsync.typePrefix,
        }),
      );
    }
  },
);

export const publishCampaignAsync = createAsyncThunk(
  'campaignData/publishCampaignAsync',
  /**
   * @param {{
   *  published: boolean,
   *  campaignId: string
   * }} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setPageLoading(true));

    const campaign = /** @type {CampaignModel} */ ({
      ...payload,
    });

    try {
      const updatedCampaign = await updateCampaign(
        campaign.campaignId,
        campaign,
      );

      dispatch(
        setCampaignData({
          campaign: updatedCampaign,
          status: CAMPAIGN_STATUS.UPDATED_PUBLISH,
        }),
      );
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: publishCampaignAsync.typePrefix,
        }),
      );
    }
    dispatch(setPageLoading(false));
  },
);

export const setRemoveFileAsync = createAsyncThunk(
  'campaignData/setRemoveFileAsync',
  /**
   * @param {{
   *  campaignId: string,
   *  fileName: string
   * }} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setPageLoading(true));
    try {
      /** @type {CampaignPostPutResponse} */
      const res = await removeFile(payload.campaignId, payload.fileName);

      if (res.metadata.filename !== payload.fileName) {
        dispatch(
          setInfoMessage(
            `File "${payload.fileName}" was removed successfully.`,
          ),
        );
        dispatch(setCampaign({ campaign: mapToCampaignClientModel(res) }));
      } else {
        dispatch(
          setErrorMessage(
            `An error occurred while trying to remove the file ` +
              `"${payload.fileName}".` +
              `Try again, if the error persists contact the application admin.`,
          ),
        );
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: setRemoveFileAsync.typePrefix,
        }),
      );
    }
    dispatch(setPageLoading(false));
  },
);

export const debounceStoreDraft =
  /** @type {( dispatch: Function, state: Partial<CampaignModel>) =>  void} */
  (
    debounce(
      (dispatch, formData) => dispatch(storeCampaignDraft(formData)),
      1000,
    )
  );

export const {
  setCampaignLoading,
  setCampaign,
  setCampaignData,
  setCampaignField,
  setCalculatedMatchSummaryWebsocket,
  resetCampaign,
  storeCampaignDraft,
  removeCampaignDraft,
  restoreCampaignDraft,
} = campaignDataSlice.actions;

export default campaignDataSlice.reducer;
