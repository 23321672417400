// @ts-check

import { AuthError } from 'core/auth/services/auth-error';

/**
 * @param {Error} sourceError
 * @return {CustomError}
 * */
function processAppError(sourceError) {
  const err = /** @type {HttpError} */ (sourceError);
  let errorCode = 0;
  const AUTH_ERROR =
    `Something went wrong. Please try to refresh this page to fi the issue.` +
    `If the error persists contact the support team.`;
  const GENERIC_ERROR = `Something went wrong. Please try again later.`;
  const PERMISSION_ERROR =
    `You do not have permission to access to the Campaign Builder. ` +
    `You must login with with valid user credentials.`;
  const NOT_FOUND_ERROR = `The page "${window.location.href}" was not found`;

  let error = GENERIC_ERROR;
  let resourceNotFound = false;

  if (err.message.includes('code 403')) {
    errorCode = 403;
    error = PERMISSION_ERROR;
  } else if (err.message.includes('code 404')) {
    errorCode = 404;
    error = NOT_FOUND_ERROR;
    resourceNotFound = true;
  } else if (err instanceof AuthError) {
    error = AUTH_ERROR;
  } else {
    errorCode = undefined;
  }

  console.error(sourceError);

  return {
    error,
    errorCode,
    resourceNotFound,
  };
}

export { processAppError };
