// @ts-check

import React from 'react';
import styled from 'styled-components';

const Message = styled.p`
  max-width: 50ch;
  margin: 0 auto;
  color: #637381;
`;

/**
 * @param {{title: string}} props
 */
function PageNotFound({ title }) {
  const header = title || 'Sorry, page not found!';

  return (
    <section className="container text-center">
      <h1 className="d-inline-block" data-testid="not-found">
        {header}
      </h1>
      <Message className="text">
        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
        mistyped the URL? Be sure to check your spelling.
      </Message>
    </section>
  );
}

export { PageNotFound };
