// @ts-check

import { fetchData } from '../utils/http-utils';

/**
 * @returns {Promise<{
 *    userRoles: string [],
 *    principalId: string
 * }>}
 */
async function getUserRoles() {
  try {
    /** @type {AxiosResponse<RoleResponse>} */
    const { data } = await fetchData(`/me`);

    return {
      userRoles: data.roles,
      principalId: data.principal_id,
    };
  } catch (err) {
    console.error('[getUserRoles]', err);
    return {
      userRoles: [],
      principalId: '',
    };
  }
}

function getSSOName() {
  try {
    let cookies = decodeURIComponent(document.cookie).split('; ');
    const ssoNameCookie = cookies.find((c) => c.includes('SSONAME'));
    const ssoNameCookieValue = ssoNameCookie.split('=')?.[1];
    return ssoNameCookieValue;
  } catch (error) {
    return '';
  }
}

export { getUserRoles, getSSOName };
