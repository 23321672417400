import React, { useEffect } from 'react';
import styled from 'styled-components';

import { initializeFixedTable } from './helper';

const scrollStyle = `
  &[data-scroll-left='0'] .scroll-control.previous {
    opacity: 0 !important;
  }

  &[data-records='0'] {
    .scroll-control {
      display: none !important;
    }
    .uds-table > table tbody tr:hover td {
      background-color: initial;
    }
  }

  &:hover .scroll-control {
    visibility: visible;
    opacity: 1;
  }

  & .scroll-control {
    display: block;
    position: absolute;
    height: 100%;
    pointer-events: none;
    top: 0;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  div[data-loading="true"] & .scroll-control{
    opacity: 0 !important;
  }
`;

const Container = styled.div.attrs(({ columFixedLayout }) => ({
  columFixedLayout,
}))`
  ${(props) => (props.columFixedLayout ? scrollStyle : '')}

  .uds-table:focus {
    box-shadow: none !important;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody {
    overflow-y: scroll;
  }

  th {
    white-space: nowrap;
    text-transform: capitalize;
  }

  th[data-sortable='true'] {
    cursor: pointer;
  }

  td {
    vertical-align: baseline;
  }
`;

/**
 * @param {PropsWithChildren<{
 *  height?: string,
 *  recordCount: number,
 *  columFixedLayout: boolean,
 * }>} props
 */
const TableContainer = ({
  recordCount,
  height,
  columFixedLayout = false,
  children,
}) => {
  useEffect(() => {
    columFixedLayout && initializeFixedTable();
  }, [columFixedLayout, recordCount]);

  return (
    <Container
      className={columFixedLayout ? 'uds-table-fixed-wrapper' : ''}
      columFixedLayout={columFixedLayout}
      data-records={recordCount}
      data-scroll-left="0"
    >
      {columFixedLayout && (
        <>
          <div className="scroll-control previous">
            <button
              type="button"
              className="btn btn-circle btn-circle-alt-gray"
            >
              <i className="fas fa-chevron-left" />
              <span className="sr-only">Previous</span>
            </button>
          </div>

          <div className="scroll-control next">
            <button
              type="button"
              className="btn btn-circle btn-circle-alt-gray"
            >
              <i className="fas fa-chevron-right" />
              <span className="sr-only">Next</span>
            </button>
          </div>
        </>
      )}

      <div
        className={columFixedLayout ? 'uds-table uds-table-fixed' : 'uds-table'}
        tabIndex={0}
        style={{
          height,
        }}
      >
        {children}
      </div>
    </Container>
  );
};

export { TableContainer };
