// @ts-check
import React from 'react';
import './ToggleSwitch.scss';

/**
 *
 * @param {{
 * id: string
 * name?: string
 * checked: boolean
 * onChange: (checked: boolean) => void
 * optionLabels?: string[]
 * small?: boolean
 * disabled?: boolean
 * }} param0
 * @returns
 */
const ToggleSwitch = ({
  id,
  name,
  checked,
  onChange,
  optionLabels = ['Yes', 'No'],
  small,
  disabled,
}) => {
  return (
    <div className={'toggle-switch' + (small ? ' small-switch' : '')}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e.target.checked)}
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span
            className={
              disabled
                ? 'toggle-switch-inner toggle-switch-disabled'
                : 'toggle-switch-inner'
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span
            className={
              disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
            }
          />
        </label>
      ) : null}
    </div>
  );
};

export { ToggleSwitch };
