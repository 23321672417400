// @ts-check

import React from 'react';
import { ErrorValidation, RequiredIcon, Tooltip } from 'core/components';

/**
 * @param {{
 *    campaignDescription: string
 *    onChange: (e) => void
 *    readonly: boolean
 *    errors?: string []
 * }} props
 */
function CampaignDescription({
  campaignDescription,
  onChange,
  readonly,
  errors,
}) {
  return (
    <div className="form-group" id="campaign-description-field">
      <label>
        <RequiredIcon />
        Campaign Description
      </label>
      <Tooltip tooltipId="campaign-description-tooltip">
        This is a description of what the campaign is about, such as topics,
        content area of focus, population grouping, etc; minimum 5 characters.
      </Tooltip>
      <textarea
        data-testid="campaign-description"
        className={`form-control ${errors.length > 0 ? 'is-invalid' : ''}`}
        rows={3}
        placeholder="Campaign description"
        name="campaignDescription"
        id="campaignDescription"
        value={campaignDescription}
        required
        readOnly={readonly}
        disabled={readonly}
        onChange={onChange}
      />
      <ErrorValidation errors={errors} />
    </div>
  );
}

export { CampaignDescription };
