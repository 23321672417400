// @ts-check
import { createSlice } from '@reduxjs/toolkit';

// core
import { PAGE_STEP } from 'core/constants/app-constants';

/** @type {NavigationState} */
const initialState = {
  selectedRoute: PAGE_STEP.NONE,
  previousPage: PAGE_STEP.NONE,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setSelectedRoute: (state, action) => {
      state.selectedRoute = action.payload;
    },
    setPreviousPage: (state, action) => {
      state.previousPage = action.payload;
    },
  },
});

export const { setSelectedRoute, setPreviousPage } = navigationSlice.actions;

export default navigationSlice.reducer;
