// @ts-check

// import mockData from '../../__dev__/data-mocks/api-response/filters-get-v2.json';
// import mockData from '../../__dev__/data-mocks/api-response/export-filters-get.json';
import { fetchData } from '../utils/http-utils';

async function getFilters() {
  // MOCK: use this for mock data only. The AxiosResponse below is the real api resonse.
  // const list = mockData.filters.map((category) => ({
  //   label: category.category,
  //   name: category.category.toLocaleLowerCase().replace(/ /g, '_'),
  //   value: category.category.toLocaleLowerCase().replace(/ /g, '_'),
  //   options: category.fields.map((field) => ({
  //     label: field.field_label,
  //     name: field.db_filter_key,
  //     value: field.db_filter_key,
  //     values: field.values,
  //   })),
  // }));

  /** @type {AxiosResponse<FiltersResponse>} */
  const { data } = await fetchData(`/filters?type=define_filters`);

  const list = data.filters.map((category) => ({
    label: category.category,
    name: category.category.toLocaleLowerCase().replace(/ /g, '_'),
    value: category.category.toLocaleLowerCase().replace(/ /g, '_'),
    options: category.fields.map((field) => ({
      label: field.field_label,
      name: field.db_filter_key,
      value: field.db_filter_key,
      values: field.values,
    })),
  }));

  return list;
}

async function getExportFilters() {
  /** @type {AxiosResponse<FiltersResponse>} */
  const { data } = await fetchData(`/filters?type=user_defined_export`);

  return data;
}

export { getExportFilters, getFilters };
