// @ts-check

import React from 'react';

function PageInProgress() {
  return (
    <section className="container text-center">
      <h1 className="d-inline-block" data-testid="in-progress">
        This page is still in progress!
      </h1>
    </section>
  );
}

export { PageInProgress };
