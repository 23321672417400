// @ts-check
import React from 'react';
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  cursor: wait;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgb(173 216 230 / 72%);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1100;

  &[data-place-in-container='true'] {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(173 216 230 / 44%);
  }
`;

const Container = styled.div`
  --width: 200px;
  --color: lightblue;
  z-index: 1200;

  margin-left: var(--width);
  width: var(--width);
  height: calc((var(--width) / 3) - 10px);
  transform: translateX(-50%) translateY(-50%);

  .ball-loader-ball {
    will-change: transform;

    height: calc((var(--width) / 3) - 10px);
    width: calc((var(--width) / 3) - 10px);

    border-radius: 50%;
    background-color: #009acc;
    position: absolute;
    animation: grow 1s ease-in-out infinite alternate;

    &.ball1 {
      left: 0;
      transform-origin: 100% 50%;
    }
    &.ball2 {
      left: 50%;
      transform: translateX(-50%) scale(1);
      animation-delay: 0.33s;
    }
    &.ball3 {
      right: 0;
      animation-delay: 0.66s;
    }
  }

  @keyframes grow {
    to {
      transform: translateX(-50%) scale(0);
    }
  }
`;

function BubbleLoader({ placeInContainer = false }) {
  return (
    <BackgroundContainer data-place-in-container={placeInContainer}>
      <Container data-testid="loader" className="sball-loader">
        <div className="ball-loader-ball ball1" />
        <div className="ball-loader-ball ball2" />
        <div className="ball-loader-ball ball3" />
      </Container>
    </BackgroundContainer>
  );
}

export { BubbleLoader };
