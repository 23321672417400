// @ts-check
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// core
import { AuthContext, routerUrl } from 'core';
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  closeAnnouncment,
  selectMostRecentOpenAnnouncement,
} from 'core/store/slices';
import { AnnouncementAlert, ErrorAlert, InfoAlert, SuccessAlert } from '..';

const AppStateAlert = () => {
  const dispatch = useAppDispatch();

  const { error: authError } = useContext(AuthContext);
  const { shared } = useAppSelector((state) => state);
  const {
    feedback: { errorMessage, infoMessage, successMessage },
  } = shared;
  const mostRecentOpenAnnouncement = useAppSelector(
    selectMostRecentOpenAnnouncement,
  );

  /** @type {HistoryState} */
  const history = useHistory();
  const [errorOpen, setErrorOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [announcementOpen, setAnnouncementOpen] = useState(false);

  useEffect(() => {
    shared.resourceNotFound && history.push(routerUrl.PAGE_NOT_FOUND);
  }, [history, shared.resourceNotFound]);

  useEffect(() => {
    if (authError) {
      setErrorOpen(true);
    }
  }, [authError]);

  useEffect(() => {
    setErrorOpen(!!errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    setInfoOpen(!!infoMessage);
  }, [infoMessage]);

  useEffect(() => {
    setSuccessOpen(!!successMessage);
  }, [successMessage]);

  // useEffect(() => {
  //   setAnnouncementOpen(!!mostRecentOpenAnnouncement);
  // }, [mostRecentOpenAnnouncement]);

  // useEffect(() => {
  //   //TODO: Fix getAnnouncementsAsync() call. It is expecting 1 parameter but currently passing none.
  //   // @ts-ignore
  //   dispatch(getActiveAnnouncementsAsync());
  // }, [dispatch]);

  return (
    <div>
      {announcementOpen ? (
        <AnnouncementAlert
          content={mostRecentOpenAnnouncement.content}
          title={mostRecentOpenAnnouncement.title}
          isOpen={announcementOpen}
          onClose={() => {
            setAnnouncementOpen(false);
            dispatch(closeAnnouncment(mostRecentOpenAnnouncement));
          }}
        />
      ) : null}
      {errorOpen ? (
        <ErrorAlert
          errors={[errorMessage || authError]}
          isOpen={errorOpen}
          onClose={() => setErrorOpen(false)}
        />
      ) : null}
      {infoOpen ? (
        <InfoAlert
          message={infoMessage}
          isOpen={infoOpen}
          onClose={() => setInfoOpen(false)}
        />
      ) : null}
      {successOpen ? (
        <SuccessAlert
          message={successMessage}
          isOpen={successOpen}
          onClose={() => setSuccessOpen(false)}
        />
      ) : null}
    </div>
  );
};

export { AppStateAlert };
