// @ts-check

const fontSize = '0.875rem';
/** @type {ReactSelectStyle} */
const dropdownStyles = {
  input: (provided) => {
    return {
      ...provided,
      'input:focus': {
        boxShadow: 'none !important',
      },
    };
  },
  control: (provided, state) => {
    const boxShadow = state.isFocused
      ? '0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #191919'
      : provided.boxShadow;
    return {
      ...provided,
      fontSize,
      boxShadow,
    };
  },
  multiValue: (provided, state) => {
    const color = state.isDisabled ? '#999999' : provided.color;
    const backgroundColor = state.isDisabled
      ? 'white'
      : provided.backgroundColor;
    return { ...provided, color, backgroundColor };
  },
  group: (provided) => ({
    ...provided,
    paddingBlock: 0,
  }),
};

export { dropdownStyles };
