// @ts-check

import { getTokenSilently } from 'core/auth';
import { isStorybook, useMockData } from 'core/constants/app-settings';

const createAuthHeaders = async () => {
  const jwt =
    isStorybook || useMockData
      ? { token: 'dummy-mock-token' }
      : await getTokenSilently();

  const headers = {
    Authorization: `Bearer ${jwt?.token}`,
  };
  return headers;
};

export { createAuthHeaders };
