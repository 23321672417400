// @ts-check
import React from 'react';

/**
 * @param {{
 *  message: string
 *  isOpen: boolean
 *  onClose: () => void
 * }} props
 */
function SuccessAlert({ message, isOpen = false, onClose = () => null }) {
  return isOpen ? (
    <div className="row">
      <div className="col-md-12">
        <div className="alert align-items-center alert-success" role="alert">
          <div className="alert-icon">
            <i title="Success" className="fa fa-icon fa-check-circle" />
          </div>
          <div className="alert-content">{message}</div>

          <div className="alert-close">
            <button
              type="button"
              className="btn btn-circle btn-circle-alt-black close"
              aria-label="Close"
              data-dismiss="alert"
              onClick={onClose}
            >
              <i className="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export { SuccessAlert };
