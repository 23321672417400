// @ts-check
const pk = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA3qXWEL5b1auP1yhtJGO9
qNF8kSszEY2Y3uYnOusOrfT5CP1p9AMd8f6jeH3EMFPuTJmYUBPKX03eTtG6vHMU
TpKuT3alfDfesgWMfOKza/I9J9KUHlXSo2yzFtI8b9S/mq6eyA6SortDEzZq1+y/
c6dkbr+i8LOSvffaISq4FwSY0TKXV9CvpGZfIKFne/LccFxMd/UVXHSlsA1LDOBe
BOl2O7RmBzQO47ORcHBam98mVn4b2OhxYUoVul0x2dtcquyhBfMXE0e+e0CsGIVr
BRayI4jCbo6y5jLtWVeM4xkUvRbOebOeMDucIB8UampkFRYT0yCO3dIKHWLBRQgB
RA8LdZXBTCelUdgie5nmlktMkGe6mXr0ZX343yP5LLpqRoLsGm4PFO2WQ68jRd5C
1cxyYKSyDj4/bFMeAnjk4p8eSIye7kkcFoNGpUyp8ifBkRGoUMv6rrQkWvUQHAVt
zq9zzPT71GWO8J5CmGfM1Ei6JmzTnyX4w/jYKZic9zwxmq2mP4VfJ46CzqfwyqZX
RSW157K0jfd2wEAF6VZz+bh9fcH27/s6a/rrn15PBUHou+cOTfI9Hfi9zSsisySW
TJjwC+AWGWJO0s/8VpuYpIBCLS3egjt/UvWIsyLfCxm/j7YCNjMWQcKgaTgPuWBI
2JOmKyKndXH+DXrlgGBCf3sCAwEAAQ==
-----END PUBLIC KEY-----`;

export default pk;
