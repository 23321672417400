// @ts-check
import React from 'react';
import styled from 'styled-components';

// Core.
import { SpinnerLoader } from '../../../core/components';

const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  h2,
  h3,
  h4 {
    margin: 0;
  }
`;

/**
 * @param {{
 * loading: boolean
 * censusDate: string
 * currentTerm: string
 * lastRefreshDate: string
 * nextRegularTerm: string
 * nextTerm: string
 * priorTerm: string
 * priorRegularTerm: string
 * }} props
 * @returns {JSX.Element} The UI display of the current, prior, and next terms.
 */
const DisplayContent = ({
  loading,
  censusDate,
  currentTerm,
  lastRefreshDate,
  nextRegularTerm,
  nextTerm,
  priorTerm,
  priorRegularTerm,
}) => {
  return (
    <section>
      <div style={{ display: 'flex', flex: 'wrap', flexDirection: 'row' }}>
        {!loading ? (
          <Container>
            <section>
              <div className="row text-nowrap">
                <div className="col-4 border-right pr-4">
                  <div>
                    <strong>Prior Term:</strong> {priorTerm}
                  </div>
                  <div>
                    <strong>Prior Regular Term:</strong> {priorRegularTerm}
                  </div>
                </div>
                <div className="col-4 border-right">
                  <div>
                    <strong>Current Term: </strong> {currentTerm}
                  </div>
                  <div>
                    <strong>Census Date: </strong>
                    {censusDate}
                  </div>
                </div>
                <div className="col-4">
                  <div>
                    <strong>Next Term: </strong>
                    {nextTerm}
                  </div>
                  <div>
                    <strong>Next Regular Term: </strong>
                    {nextRegularTerm}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <strong>Campaign Builder data refreshed as of</strong>{' '}
                  {lastRefreshDate}
                </div>
              </div>
            </section>
          </Container>
        ) : (
          <div className="float-left">
            <SpinnerLoader spinnerType="circle" width="20px" height="20px" />
          </div>
        )}
      </div>
    </section>
  );
};

export { DisplayContent };
