/* eslint-disable camelcase */
// @ts-check

import { parseCSVFileName } from './csv-utils';

/** @type {Record<keyof CampaignMetaData, keyof CampaignModel>} */
const schema = /** @type {const} */ ({
  name: 'campaignName',
  description: 'campaignDescription',
  purpose: 'campaignPurpose',
  outreach_channels: 'outreachChannel',
  logic_shared: 'isLogicShared',
  list_shared: 'isListShared',
  published: 'published',
  logic: 'logic',
  campaign_id: 'campaignId',
  created_by: 'createdByName',
  last_updated: 'lastUpdated',
  created_at: 'createdAt',
  filename: 'fileName',
  export_fields: 'exportFields',
});

const modelMapper = Object.entries(schema);

/**
 * @param {CampaignModel} data
 * @return {CampaignPostPutRequest}
 */
const mapToCampaignWebApiModel = (data) => {
  const metadata = /** @type {CampaignMetaData} */ ({
    export_fields: data.exportFields,
    filename: data.fileName,
  });

  for (let [serverKey, clientKey] of modelMapper) {
    metadata[serverKey] = data[clientKey];
  }

  /** @type {CampaignPostPutRequest} */
  const campaignData = {
    metadata,
    ...data.details,
  };

  return campaignData;
};

/**
 * @param {Source} lastFileUploaded
 * @return {string}
 */
const getLastUploadedFileName = (lastFileUploaded) => {
  let lastFileName = '';

  if (lastFileUploaded) {
    lastFileName = parseCSVFileName(lastFileUploaded.filename);
  }

  if (lastFileUploaded?.status === 'pending') {
    lastFileName = `${lastFileName} [pending]`;
  }

  return lastFileName;
};

/**
 * @param {MatchSummary} matchSummary
 */
const computeMatchSummary = (matchSummary) => {
  if (!matchSummary) return;

  const { statement, source } = matchSummary;

  const calculatedMatchSummary = {
    totalRecords: parseInt(source.matched_rows),
    unmatchedFiltered:
      parseInt(statement.total_rows) - parseInt(statement.matched_rows),
    unmatchedImportedFile:
      parseInt(source.total_rows) - parseInt(source.matched_rows),
  };

  return calculatedMatchSummary;
};

/**
 * @param {DisplayContentResponse} data
 */
const mapToDisplayContentModel = (data) => {
  const displayContent = /** @type {DisplayContentModel} */ {
    displayContent: {
      censusDate: data.display_content.census_date,
      currentTerm: data.display_content.current_term,
      lastRefreshDate: data.display_content.last_refresh_date,
      priorTerm: data.display_content.prior_term,
      priorRegularTerm: data.display_content.prior_regular_term,
      nextRegularTerm: data.display_content.next_regular_term,
      nextTerm: data.display_content.next_term,
    },
  };

  return displayContent;
};

/**
 * @param {CampaignResponse} data
 */
const mapToCampaignClientModel = (data) => {
  const { metadata, ...details } = data;

  //#region "Map HTTP response attribute to the client model attributes"
  const campaignData = /** @type {CampaignModel} */ ({
    details,
  });

  for (let [serverKey, clientKey] of modelMapper) {
    campaignData[clientKey] = /** @type {never} */ (metadata[serverKey]);
  }
  //#endregion

  //#region "Process source list"
  const lastSource = details?.sources?.slice(-1)?.[0];

  campaignData.calculatedMatchSummary =
    computeMatchSummary(lastSource?.match_summary) ||
    /** @type {CalculatedMatchSummary} */ ({});

  if (!campaignData.fileName) {
    //  metadata.filename might be null because the upload has not finished yet.
    campaignData.fileName = getLastUploadedFileName(lastSource) || undefined;
  }
  //#endregion

  return campaignData;
};

export {
  mapToCampaignWebApiModel,
  mapToCampaignClientModel,
  mapToDisplayContentModel,
  computeMatchSummary,
};
