// @ts-check
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

// core
import {
  appSettings,
  AuthContext,
  isRouteCreate,
  isRouteUpdate,
  isRouteView,
  Loader,
  OPEN_MODE,
} from 'core';
import {
  removeCampaignDraft,
  restoreCampaignDraft,
  setCampaignData,
} from 'core/store/slices/campaignDataSlice';
import { setCampaignPreviewLoading } from 'core/store/slices/campaignPreviewSlice';
import { setErrorMessage } from 'core/store/slices/sharedSlice';
import { useAppDispatch, useAppSelector } from 'core/store/store';
import { startWebsocket, wsResponseType } from '../services';

const AppContext = createContext(
  /** @type {AppContextValue} */
  ({
    stopSendMessage: () => null,
  }),
);

const useWebsocketRef = () => {
  /** @type {{ current: WebSocketService }} */
  const ws = useRef(null);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated && (!ws.current || ws.current?.isClosed())) {
      const token = sessionStorage.getItem(appSettings.tokenKey);
      const websocketUrl = `${appSettings.websocketUrl}?token=${token}`;
      ws.current = startWebsocket(websocketUrl);

      ws.current?.addEventListener('close', () => {
        const latestToken = sessionStorage.getItem(appSettings.tokenKey);
        ws.current?.tryReconnect(
          `${appSettings.websocketUrl}?token=${latestToken}`,
        );
      });
    } else if (!isAuthenticated && ws.current?.isOpen()) {
      ws.current?.closeSocket('user not authenticated');
    }

    return () => {
      ws.current?.closeSocket('context unmounted');
    };
  }, [isAuthenticated]);

  return ws;
};

/**
 * @param {PropsWithChildren<{}>} props
 */
const AppProvider = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isRouteCreate()) {
      dispatch(setCampaignData({ openMode: OPEN_MODE.CREATE }));
    } else if (isRouteView()) {
      dispatch(setCampaignData({ openMode: OPEN_MODE.VIEW }));
    } else if (isRouteUpdate()) {
      dispatch(setCampaignData({ openMode: OPEN_MODE.UPDATE }));
    }
  }, [dispatch]);

  const ws = useWebsocketRef();

  ws.current?.addEventListener('closePersist', () => {
    dispatch(
      setErrorMessage(
        'Server connection was lost. ' +
          'It is not possible to run query filter at the moment. ' +
          'Try to refresh the page.',
      ),
    );
  });

  useEffect(() => {
    dispatch(restoreCampaignDraft());

    return () => {
      dispatch(removeCampaignDraft());
    };
  }, [dispatch]);

  /**
   * @param {SendMessageParams} params
   */
  const sendMessage = useCallback(
    ({
      message,
      onMessageCallback,
      handleError,
      queryFailedError = 'The query has failed. ' +
        'If the issue persist contact the admin',
      internalServerError = 'Sorry, an unexpected error fails the query. ' +
        'If the issue persist contact the admin',
    }) => {
      if (typeof message !== 'string') return;

      const observer = ws.current.subscribe((evt) => {
        // ====================================================================
        // Parse the event evt.data which is JSON string
        // ====================================================================
        /** @type {WebSocketEvent} */
        let resEvent;
        try {
          resEvent = /** @type {WebSocketEvent} */ ({
            data: JSON.parse(evt.data),
          });
        } catch {
          console.log('Bad format WS message');
          resEvent = null;
          return;
        }
        onMessageCallback?.(resEvent);
        // ====================================================================
        // remove a subscriber once the call is completed or fails
        // ====================================================================
        const { detail_type: detailType, message } = resEvent.data || {};
        if (
          detailType === wsResponseType.QUERY_RESULTS ||
          detailType === wsResponseType.QUERY_FAILED
        ) {
          observer.unsubscribe();
        }
        // ====================================================================
        // Handle generic errors
        // ====================================================================
        if (handleError) {
          if (detailType === wsResponseType.QUERY_FAILED) {
            dispatch(setErrorMessage(queryFailedError));
          } else if (message === 'Internal server error') {
            dispatch(setErrorMessage(internalServerError));
            // Stop the loading spinner that displays in the Campaign List Preview table section.
            dispatch(setCampaignPreviewLoading(false));
            // WebSocket call returned a server error, so cancel the Observable.
            observer.unsubscribe();
          }
        }
        // ====================================================================
      });
      ws.current.sendMessage(message);
    },
    [dispatch, ws],
  );

  const stopSendMessage = useCallback(() => {
    if (ws.current) {
      ws.current.unsubscribeLast();
    }
    dispatch(setCampaignPreviewLoading(false));
  }, [dispatch, ws]);

  const { campaignData, queryBuilder, shared } = useAppSelector(
    (state) => state,
  );
  const loading =
    shared.pageLoading || campaignData.loading || queryBuilder.loading;
  return (
    <AppContext.Provider value={{ sendMessage, stopSendMessage }}>
      {loading ? <Loader /> : null}
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
