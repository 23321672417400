// @ts-check
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// core
import { getFilters } from 'core/services/campaign-filters-service';
import { setHttpError } from './sharedSlice';

/** @type {QueryBuilderState} */
const initialState = {
  loading: false,
  filters: [],
};

export const queryBuilderSlice = createSlice({
  name: 'queryBuilder',
  initialState,
  reducers: {
    setFiltersLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload.filters;
    },
  },
});

export const getFiltersAsync = createAsyncThunk(
  'queryBuilderSlice/getFiltersAsync',
  /**
   * @param {any} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setFiltersLoading(true));

    try {
      const filters = await getFilters();
      // If the "list" object is returned from campaign-filters-service /filters api call.
      if (filters) {
        dispatch(setFilters({ filters }));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getFiltersAsync.typePrefix,
        }),
      );
    }

    dispatch(setFiltersLoading(false));
  },
);

export const { setFiltersLoading, setFilters } = queryBuilderSlice.actions;

export default queryBuilderSlice.reducer;
