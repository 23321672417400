// @ts-check
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// core
import { getExportFilters } from 'core/services/campaign-filters-service';
import { setHttpError } from './sharedSlice';

/** @type {ExportFilterState} */
const initialState = {
  loading: false,
  exportFilters: [],
};

export const exportFilterSlice = createSlice({
  name: 'exportFilter',
  initialState,
  reducers: {
    setExportFiltersLoading: (state, action) => {
      state.loading = action.payload;
    },
    setExportFilters: (state, action) => {
      state.exportFilters = action.payload.exportFilters;
    },
  },
});

export const getExportFiltersAsync = createAsyncThunk(
  'exportFilterSlice/getExportFiltersAsync',
  /**
   * @param {any} payload
   */
  async (payload, { dispatch }) => {
    dispatch(setExportFiltersLoading(true));

    try {
      const exportFilters = await getExportFilters();
      // If the "list" object is returned from campaign-filters-service /filters api call.
      if (exportFilters) {
        dispatch(setExportFilters({ exportFilters }));
      }
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: error,
          sourceAction: getExportFiltersAsync.typePrefix,
        }),
      );
    }

    dispatch(setExportFiltersLoading(false));
  },
);

export const { setExportFiltersLoading, setExportFilters } =
  exportFilterSlice.actions;

export default exportFilterSlice.reducer;
