// @ts-check

import React from 'react';
import {
  CheckBoxList,
  ErrorValidation,
  RequiredIcon,
  Tooltip,
} from 'core/components';

/** @type {CheckBoxListItem[]} */
const channels = [
  { id: 'asuSunnyChatbot', value: 'ASU Sunny Chatbot' },
  { id: 'asuMobileApp', value: 'ASU Mobile App / Push Notifications' },
  {
    id: 'directOutreach',
    value: 'Direct Outreach (e.g direct email, call, text, etc.)',
  },
  {
    id: 'entSFAdvisorPortal',
    value: 'Enterprise Salesforce Advisor Portal',
  },
  { id: 'entSFCoachPortal', value: 'Enterprise Salesforce Coach Portal' },
  {
    id: 'entSFMarketingCloud',
    value: 'Enterprise Salesforce Marketing Cloud',
  },
  {
    id: 'entSFServicePortal',
    value: 'Enterprise Salesforce Case Management',
  },
];

/** @type {CheckBoxListItem} */
const otherItem = { id: 'other', value: '', checked: false };

/**
 * @param {{
 *    outreachChannel: OutreachChannel
 *    onChange: (e) => void
 *    readonly: boolean
 *    errors?: string []
 * }} props
 */
function OutreachChannel({ outreachChannel, onChange, readonly, errors }) {
  return (
    <div
      className={`form-group list-group m-0 ${
        errors.length > 0 ? 'is-invalid' : ''
      }`}
      id="outreach-channel-field"
      data-testid="outreach-channel-field"
    >
      <div className="form-control border-0 m-0 pl-0">
        <label>
          <RequiredIcon />
          Outreach Channel
          <Tooltip tooltipId="outreach-channel-tooltip">
            Select at least 1. This field is for reporting and tracking
            purposes, as well as to inform future development.
          </Tooltip>
        </label>

        <div className={errors?.length > 0 ? 'mb-2' : 'd-none'}>
          <ErrorValidation errors={errors} />
        </div>

        <CheckBoxList
          testId="outreach-channel"
          id="outreach-channel"
          name="outreachChannel"
          items={channels}
          otherItem={otherItem}
          value={{
            selectedItems: outreachChannel.channels,
            other: outreachChannel.other,
          }}
          placeholder="Other channel"
          readOnly={readonly}
          disabled={readonly}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export { OutreachChannel };
