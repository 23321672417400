/* eslint-disable react/jsx-props-no-spreading */
// @ts-check
import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

// core
import { useMockData } from 'core/constants/app-settings';
import { routerUrl } from 'core/constants/router-url';
import { logger } from 'core/utils';
import { AuthContext } from '../context/context';

const RedirectLogin = () => <div>Redirecting you to the login page...</div>;
const CheckPermission = () => <div>Checking your permissions...</div>;

/**
 * @param {RouteProps & { roles?: string []}} props
 */
const RealProtectedRoute = ({ component, roles, ...props }) => {
  logger.logYellow('authentication is required');

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    loadUserRoles,
    userRoles,
    errorBlocker,
  } = useContext(AuthContext);

  useEffect(() => {
    if (errorBlocker) {
      logger.logRed('Blocking error. Check the network tab.');
      return;
    }
    if (isLoading) {
      logger.log('either loading or user has valid auth');
      return;
    }

    if (!isAuthenticated) {
      (async () => await loginWithRedirect())();
    }

    if ((isAuthenticated && !userRoles) || userRoles?.length === 0) {
      (async () => await loadUserRoles())();
    }
  }, [
    isLoading,
    isAuthenticated,
    userRoles,
    errorBlocker,
    loginWithRedirect,
    loadUserRoles,
  ]);

  if (!isAuthenticated) {
    return <Route component={RedirectLogin} />;
  }

  if (!userRoles) {
    return <Route component={CheckPermission} {...props} />;
  }

  if (
    userRoles.length === 0 ||
    (roles && !roles.some((role) => userRoles.includes(role)))
  ) {
    return <Redirect to={routerUrl.PAGE_NOT_AUTHORIZED} />;
  }

  return <Route component={component} {...props} />;
};
/**
 * @param {RouteProps & { roles?: string []}} props
 */
const MockProtectedRoute = ({ component, roles, ...props }) => {
  const context = useContext(AuthContext);
  const { userRoles, loadUserRoles } = context;

  useEffect(() => {
    if (!userRoles || userRoles?.length === 0) {
      (async () => await loadUserRoles())();
    }

    context.isAuthenticated = true;
    context.user = 'mock-user@mock';
    context.ssoName = 'Mock User';
  }, [context, loadUserRoles, userRoles]);

  return <Route component={component} {...props} />;
};

const ProtectedRoute = useMockData ? MockProtectedRoute : RealProtectedRoute;

export { ProtectedRoute };
