// @ts-check
import React, { useState } from 'react';
import { ElementTooltip } from '../Tooltip';

// Copies a selection of text to the user's clipboard.
function ClipboardCopy({ textToCopy }) {
  const [isCopied, setIsCopied] = useState(false);
  const handleClick = () => {
    setIsCopied(!isCopied);
  };

  return (
    <div className="App">
      <ElementTooltip
        tooltipId="copy-id-tooltip"
        tooltip="This will copy the Campaign ID to your clipboard."
      >
        <button
          aria-describedby="copy-id-tooltip"
          className="btn btn-sm btn-dark"
          type="button"
          onClick={() => {
            handleClick();
            navigator.clipboard.writeText(textToCopy);
          }}
        >
          <i className="fas fa-copy" />
          &nbsp; {isCopied ? 'ID Copied!' : 'Copy ID'}
        </button>
      </ElementTooltip>
    </div>
  );
}

export { ClipboardCopy };
