// @ts-check

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

// assets
// After upgrading to node 16, import @asu throws error:
// Cannot find module '@asu/component-header/dist/assets/img/arizona-state-university-logo.png' or
// its corresponding type declarations.ts(2307). However, the app renders the logo. Thus, using ts-ingore for now.
// @ts-ignore
import desktopLogo from '@asu/component-header/dist/assets/img/arizona-state-university-logo-vertical.png';
// @ts-ignore
import mobileLogo from '@asu/component-header/dist/assets/img/arizona-state-university-logo.png';

// @asu Unity theme package.
import { ASUHeader } from '@asu/component-header/dist/asuHeader.es';

// core
import { AuthContext, OPEN_MODE, routerUrl } from 'core';

// state
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  resetAlertMessages,
  resetCampaign,
  resetCampaignPreview,
  resetPageSummary,
  setCampaignData,
  setSelectedRoute,
} from 'core/store/slices';

const HeaderSection = styled.div`
  header {
    .content-container {
      font-size: 1rem;
      border: 0;
      margin: 0;
      height: fit-content;
      align-items: start;
      flex-direction: row;
    }

    a {
      cursor: pointer;
    }
  }
`;

// since the header is position fixed,
// we need to add the margin to the input fields when scrolling
/** @type {*} */
const GlobalStyle = createGlobalStyle`
  form *[id*='-field'] {
    scroll-margin-top: 100px;
  }
`;

function Navbar() {
  const [navHeight, setNavHeight] = useState('0px');
  const history = useHistory();
  const { isAuthenticated, user, userRoles, logout, loginWithRedirect } =
    useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { selectedRoute } = useAppSelector((state) => state.navigation);

  useEffect(() => {
    const navHeader = document.querySelector('#nav-header header');
    setNavHeight(navHeader.clientHeight + 'px');
  }, []);

  // start workaround
  /* TODO:
    this hook is workaround fr ASU Header component which right
    now does not provide click events for login and  logout.
    ASU team is on track to update `component-header`,
    when done this workaround component will be removed */
  useEffect(() => {
    const navLinkContainer = document.querySelector(
      '#asuHeader nav .links-container',
    );
    function loginLogoutClick(e) {
      /** @type { HTMLAnchorElement } */
      const target = e.target;
      const linkKey = target.innerText.toLowerCase().replace(/ /g, '-');

      if (linkKey === 'sign-in') {
        loginWithRedirect();
      } else if (linkKey === 'sign-out') {
        logout();
      }
    }
    navLinkContainer?.addEventListener('click', loginLogoutClick);
    return () =>
      navLinkContainer?.removeEventListener('click', loginLogoutClick);
  }, [loginWithRedirect, logout]);
  // end workaround

  /**
   * @param {string} routePath
   * @param {string} text
   * @param {string} [type]
   */
  const buildRoute = (routePath, text, type) => ({
    text,
    type,
    selected: selectedRoute === routePath,
    onClick: () => {
      dispatch(resetPageSummary());
      dispatch(resetAlertMessages());
      dispatch(setSelectedRoute(routePath));
      history.push(routePath);
    },
  });

  // Customize the navigation menu base on the user's local role(s).
  // All roles currently have access to the following routes:
  const navBarItems = [
    buildRoute(routerUrl.HOME, 'Home', 'icon-home'),
    buildRoute(routerUrl.CAMPAIGN_SEARCH, 'Search'),
    buildRoute(routerUrl.CAMPAIGN_SCHEDULE, 'Schedules'),
    buildRoute(routerUrl.REPORTING, 'Reporting'),
    buildRoute(routerUrl.SUPPORT, 'Support'),
  ];

  // Add the Admin route if user has that role.
  if (userRoles?.includes('admin')) {
    navBarItems.push(buildRoute(routerUrl.ADMIN, 'Admin'));
  }

  return (
    <HeaderSection id="nav-header" style={{ marginBottom: navHeight }}>
      <GlobalStyle />
      <ASUHeader
        logo={{
          alt: 'logo',
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={isAuthenticated}
        userName={user}
        navTree={navBarItems}
        title="Campaign Builder"
        buttons={[
          {
            onClick: () => {
              dispatch(resetCampaign());
              dispatch(resetCampaignPreview());
              dispatch(resetPageSummary());
              dispatch(resetAlertMessages());
              dispatch(setCampaignData({ openMode: OPEN_MODE.CREATE }));
              dispatch(setSelectedRoute(routerUrl.CAMPAIGN_CREATE_DATA));
              history.push(routerUrl.CAMPAIGN_CREATE_DATA);
            },
            text: 'Create Campaign',
            color: 'gold',
          },
        ]}
      />
    </HeaderSection>
  );
}

export { Navbar };
