/* eslint-disable react/jsx-props-no-spreading */
// @ts-check
import React from 'react';

import { Dropdown } from 'core/components/Dropdown';
import {
  betweenOperators,
  equalityOperators,
  multiValueOperators,
  numericOperators,
} from '../../utils/query-builder-config';

/**
 * @param {import('react-select').Props} props
 */
const QBDropdown = ({ className = '', ...props }) => (
  <Dropdown
    {...props}
    menuPortalTarget={document.body}
    className={`dropdown ${className}`}
  />
);

/**
 * @param {InputHTMLAttributes<HTMLInputElement>} props
 */
const DefaultValueEditor = (props) => (
  <input className="input-value" {...props} />
);

/**
 * This function creates and returns the input component which used used
 * query builder to allow the user to choose a value or values.
 *
 * @param { Pick<ValueEditorProps, "value" | "values" | "operator"> & {
 *   isDisabled: boolean,
 *   index: number,
 *   handleOnChange: (newValue: any, actionMeta: any, rowIndex: number) => void
 * }} props
 */
const ValueEditor = (props) => {
  const {
    isDisabled,
    handleOnChange,
    value: inputValue,
    values,
    operator,
    index,
  } = props;

  const placeholder = 'Select filter value(s)';

  // Nullish Value Editor.
  if (!operator || ['null', 'notNull'].includes(operator)) {
    return <DefaultValueEditor disabled value="" />;
  }

  // Multi Select Value Editor.
  if (multiValueOperators.includes(operator)) {
    let pValue = inputValue;
    let pValues = values;

    // Process multi values.
    if (Array.isArray(inputValue) && !inputValue[0]?.id) {
      const remapValues = (items) =>
        items?.map((v) => ({
          ...v,
          label: v.value,
          name: v.value,
        }));

      pValue = remapValues(inputValue);
      pValues = remapValues(pValues);
    }

    // Process Between Operators.
    const validBetweenOperators =
      betweenOperators.includes(operator) &&
      Array.isArray(pValue) &&
      pValue.length > 1;

    if (validBetweenOperators) {
      pValue = pValue.filter((_, index) => index < 2);
    }

    return (
      <QBDropdown
        name="value"
        value={pValue}
        options={pValues}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        isMulti
        isDisabled={isDisabled}
        onChange={
          /**
           *  @type {(
           *  selectedValues: object | object [],
           *  actionMeta: any
           * ) => void}
           * */
          (selectedValues, actionMeta) => {
            let newValue = validBetweenOperators
              ? selectedValues.filter((_, index) => index < 2)
              : selectedValues;

            handleOnChange(newValue, actionMeta, index);
          }
        }
      />
    );
  }

  // Single Select Value Editor.
  if (equalityOperators.includes(operator)) {
    const checkValue = (valueToCheck) => {
      // The react-select component requires an object(s). Transform the passed-in value or
      // options to an array of objects, if necessary.
      // If the operator is equality (=, !=, >, etc.), then passed-in value will be a string.
      // If the operator is "includes" or "between", then passed-in options will be an arrary of strings.
      if (valueToCheck && typeof valueToCheck[0] === 'string') {
        return valueToCheck?.map((v) => ({
          label: v,
          value: v,
        }));
      }
      // Else the values will already be an array of objects; no need to transform.
      else return valueToCheck;
    };

    return (
      <QBDropdown
        name="value"
        value={checkValue(inputValue)}
        options={checkValue(values)}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onChange={(selectedValues, actionMeta) => {
          handleOnChange(selectedValues, actionMeta, index);
        }}
      />
    );
  }

  // Numeric or Text Value Editor.
  return (
    <DefaultValueEditor
      className="input-value"
      type={numericOperators.includes(operator) ? 'number' : 'text'}
      value={inputValue || ''}
      placeholder={placeholder}
      disabled={isDisabled}
      onChange={({ target }) => {
        handleOnChange({ value: target.value }, { name: 'value' }, index);
      }}
    />
  );
};

export { ValueEditor, QBDropdown };
