//@ts-check
import React from 'react';
import styled from 'styled-components';

// Core.
import { formatDate, formatUsername } from 'core';

const Container = styled.div`
  background-color: #f5f5f5;
  border: 1px solid #dcdbdb;
  border-radius: 4px;
  margin-top: 3rem;

  font-size: 0.8rem;
  background-color: gray-1;
`;

/**
 * @param {{
 *    createdBy: string
 *    createdAt: string
 *    lastUpdatedBy: string
 *    updatedAt: string
 * }} props
 */
function AnnouncementHistory({
  createdBy,
  createdAt,
  lastUpdatedBy,
  updatedAt,
}) {
  return (
    <Container className="container p-0">
      <div className="p-1">
        Created by {formatUsername(createdBy)} on {formatDate(createdAt)}
      </div>
      <div className="p-1">
        Updated by {formatUsername(lastUpdatedBy)} on {updatedAt}
      </div>
    </Container>
  );
}

export { AnnouncementHistory };
