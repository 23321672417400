// @ts-check
import React, { useEffect, useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '../Icons';

/**
 * This pagination is specifically implemented to work with the Postgres
 * SQL database. The API exposes a `page` param that returns the next page
 * of records. E.g. if page=1 and perPage=50, then 50 records are returned.
 *
 * @param {{
 *   isLastPage: boolean
 *   loading: boolean
 *   onChange: (page: number) => void
 * }} props
 */
const DBPagination = ({ isLastPage, loading, onChange = () => null }) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    onChange(currentPage);
  }, [currentPage, onChange]);

  /**
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
   */
  const moveFirst = (e) => {
    if (e.currentTarget.getAttribute('disabled')) return;

    setCurrentPage(1);
  };

  /**
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
   */
  const movePrev = (e) => {
    if (e.currentTarget.getAttribute('disabled')) return;
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
  };

  /**
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e
   */
  const moveNext = (e) => {
    if (e.currentTarget.getAttribute('disabled')) return;
    setCurrentPage(currentPage + 1);
  };

  const isPrevDisabled = loading || currentPage === 1;
  // If data is loading or currently on the last page then disable the next button.
  const isNextDisabled = loading || isLastPage;
  const prevItemClass = `page-item ${isPrevDisabled ? 'disabled' : ''}`;
  const nextItemClass = `page-item ${isNextDisabled ? 'disabled' : ''}`;

  return (
    <ul className="pagination">
      <li className={prevItemClass}>
        <button
          type="button"
          className="page-link"
          disabled={isPrevDisabled}
          aria-disabled={isPrevDisabled}
          onClick={moveFirst}
        >
          First
        </button>
      </li>
      <li className={prevItemClass}>
        <button
          type="button"
          className="page-link"
          disabled={isPrevDisabled}
          aria-disabled={isPrevDisabled}
          onClick={movePrev}
        >
          <ArrowLeftIcon className="mr-2" />
          Prev
        </button>
      </li>
      <li className="page-item active" aria-current="page">
        <output className="page-link">{currentPage}</output>
      </li>
      <li className={nextItemClass}>
        <button
          type="button"
          className="page-link"
          disabled={isNextDisabled}
          aria-disabled={isNextDisabled}
          onClick={moveNext}
        >
          Next
          <ArrowRightIcon className="ml-2" />
        </button>
      </li>
    </ul>
  );
};

export { DBPagination };
