/* eslint-disable react/jsx-props-no-spreading */
// @ts-check
import React from 'react';
import Select from 'react-select';

// Core.
import { Tooltip } from 'core';

/**
 * @param {{
 *    id: string
 *    label: string
 *    name: string
 *    placeholder: string
 *    testId: string
 *    tooltip?: string
 *    options: OptionsOrGroups
 *    value: any
 *    onChange: (e) => void
 * }} props
 */
function SearchDropdown({
  id,
  label,
  name,
  placeholder,
  testId,
  tooltip,
  options,
  value,
  onChange,
}) {
  // Note: the dropdown control was partially hidden by the results table header.
  // Adding the following props fixes this issue.
  // menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
  return (
    <div className="form-group" id="search-dropdown-field">
      <label>{label}</label>
      {tooltip && tooltip.length ? (
        <Tooltip tooltipId="search-dropdown-tooltip">{tooltip}</Tooltip>
      ) : null}
      <Select
        id={id}
        data-testid={testId}
        placeholder={placeholder}
        name={name}
        autoFocus
        options={options}
        isMulti
        isSearchable={false}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export { SearchDropdown };
