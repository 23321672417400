// @ts-check
import React, { useState } from 'react';
import styled from 'styled-components';
import { appSettings, isDevMode } from 'core';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .box {
    display: flex;
    flex-direction: column;
    background: white;
    border: 2px solid #000;
    box-shadow: 5px 5px 20px 50px rgb(109 111 113 / 47%);
    max-width: 700px;
    height: fit-content;
    padding: 1rem;
  }
  input {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const DevModeLogin = () => {
  const [token, setToken] = useState();
  const [refreshToken, setRefreshToken] = useState();

  if (!isDevMode) return null;

  const saveToken = (e) => {
    sessionStorage.setItem(appSettings.tokenKey, token);
    sessionStorage.setItem(appSettings.refreshTokenKey, refreshToken);
    const redirectUrl = sessionStorage.getItem(appSettings.returnToKey);
    setTimeout(() => {
      sessionStorage.removeItem(appSettings.stateKey);
      sessionStorage.removeItem(appSettings.codeVerifierKey);
      sessionStorage.removeItem(appSettings.returnToKey);
      window.location.href = redirectUrl || '/';
    }, 500);
  };

  return (
    <Container className="">
      <div className="box">
        <h3 data-testid="dev-mode-login">You are in Dev mode.</h3>
        <ul>
          <li>
            Click on the{' '}
            <a
              target="_blank"
              href="https://campaignbuilder-qa.apps.asu.edu"
              rel="noreferrer"
            >
              https://campaignbuilder-qa.apps.asu.edu
            </a>
          </li>
          <li>
            Open the session storage to copy the JWT token and if present also
            the Refresh token
          </li>
          <li>Copy those values into the respective input fields</li>
          <li>Click on save</li>
        </ul>

        <div>
          <label>Type the JWT token</label>
          <input
            autoFocus
            onChange={(e) =>
              setToken(
                // @ts-ignore
                e.target.value,
              )}
          />
        </div>
        <div>
          <label>Type the Refresh JWT token</label>
          <input
            onChange={(e) =>
              setRefreshToken(
                // @ts-ignore
                e.target.value,
              )}
          />
        </div>
        <button
          type="button"
          className="btn btn-md btn-gold"
          disabled={!token && !refreshToken}
          onClick={saveToken}
        >
          Save
        </button>
      </div>
    </Container>
  );
};

export { DevModeLogin };
