/* eslint-disable camelcase */
// @ts-check

//#region constants
export const wsResponseType = /** @type {const} */ ({
  QUERY_RUNNING: 'query_running',
  QUERY_RESULTS: 'query_results',
  QUERY_FAILED: 'query_failed',
});

const wsState = {
  CONNECTING: 0, // Socket has been created. The connection is not yet open.
  OPEN: 1, // The connection is open and ready to communicate.
  CLOSING: 2, // The connection is in the process of closing.
  CLOSED: 3, // The connection is closed or couldn't be opened.
};
//#endregion

//=================================
//#region Logging
//=================================
const backgroundStyle = 'background: #202124eb;';
function formatLog({
  action = '',
  message = '',
  printTime = false,
  alert = false,
}) {
  const dateTime = printTime ? `⏰ Time: ${new Date().toLocaleString()}` : '';
  const messageStyle = `color: ${
    alert ? '#ff6347' : '#37cc00'
  }; font-weight: bolder;`;

  return [
    `%c[WebSocket][${action}] %c${message} %c${dateTime}`,
    `${backgroundStyle} color: #ffff00;`,
    `${backgroundStyle} ${messageStyle}`,
    `${backgroundStyle} color: #7ec4f9;`,
  ];
}

function logSocketOpen(e) {
  console.log(
    ...formatLog({
      action: 'open',
      message: `✅ Connection established.`,
      printTime: true,
    }),
  );
}

function logSocketMessage(event) {
  console.groupCollapsed(
    ...formatLog({ action: 'message', message: 'Data received from server' }),
  );
  console.log(event.data);
  console.groupEnd();
}

function logSocketClose(event) {
  if (event.wasClean) {
    console.log(
      ...formatLog({
        action: 'close',
        message:
          `🔐 Connection closed cleanly, ` +
          `code=${event.code} reason=${event.reason}.`,
        printTime: true,
      }),
    );
  } else {
    // e.g. server process killed or network down
    // event.code is usually 1006 in this case
    console.log(
      ...formatLog({
        action: 'close',
        message: `🪦 Connection died.`,
        printTime: true,
        alert: true,
      }),
    );
  }
  console.log(...formatLog({ action: 'close-details' }), event);
}

function logSocketError(error) {
  console.log(
    ...formatLog({
      action: 'error',
      message: `❌ ${error.message}`,
      alert: true,
    }),
  );
  console.log(...formatLog({ action: 'error-details', alert: true }), error);
}
//#endregion

/**
 * @param {string} websocketUrl
 * @param {{
 *  allowAutoReconnect?: boolean
 * }} options
 * @return {WebSocketService}
 */
function startWebsocket(websocketUrl, { allowAutoReconnect = false } = {}) {
  /** @type {WebSocketMessageCallback []} */
  let subscribers = [];
  let reconnectAttempts = 0;
  //=================================
  //#region Event listener
  //=================================
  /** @type {Record<WebSocketServiceEventMap, () =>  void>} */
  const serviceListeners = {
    closePersist: null,
    close: null,
    error: null,
    message: null,
    open: null,
  };
  const addEventListener = /** @type {WebSocketEventListener} */ (
    name,
    eventCallback,
  ) => {
    serviceListeners[name] = eventCallback;
  };

  const removeEventListener = /** @type {WebSocketEventListener} */ (
    name,
    _eventCallback,
  ) => {
    serviceListeners[name] = null;
  };
  //#endregion

  //=================================
  //#region utility functions
  //=================================
  /**
   * @param {WebSocketMessageCallback} subscriber
   */
  const subscribe = (subscriber) => {
    subscribers.push(subscriber);
    return {
      unsubscribe() {
        subscribers = subscribers.filter((sub) => sub !== subscriber);
      },
    };
  };

  const unsubscribeAll = () => subscribers.forEach(() => subscribers.pop());
  const unsubscribeLast = () => subscribers.pop();
  const broadcast = (event) => subscribers.forEach((sub) => sub(event));
  //#endregion

  //=================================
  //#region create WebSocket
  //=================================
  /** @type {WebSocket} */
  let socket = createWebsocket(websocketUrl);

  function createWebsocket(url) {
    const ws = new WebSocket(url);

    ws.addEventListener('open', (event) => {
      reconnectAttempts = 0;
      logSocketOpen(event);
      serviceListeners.open?.();
    });

    ws.addEventListener('message', (event) => {
      logSocketMessage(event);
      broadcast(event);
      serviceListeners.message?.();
    });

    ws.addEventListener('close', (event) => {
      unsubscribeAll();
      logSocketClose(event);
      serviceListeners.close?.();
    });

    ws.addEventListener('error', (event) => {
      logSocketError(event);

      if (allowAutoReconnect) {
        tryReconnect();
      }
      serviceListeners.error?.();
    });

    return ws;
  }
  //#endregion

  const tryReconnect = (
    reconnectionUrl = websocketUrl,
    connectionTimeout = 2000,
  ) => {
    // connection closed, discard old websocket and create a new one
    socket = null;

    if (reconnectAttempts < 3) {
      reconnectAttempts++;
      console.log(
        ...formatLog({
          action: 'retry-connection',
          message: `🔑 Attempt N.${reconnectAttempts}`,
        }),
      );

      setTimeout(() => {
        socket = createWebsocket(reconnectionUrl);
      }, connectionTimeout);
    } else {
      console.log(
        ...formatLog({
          action: 'close-persist',
          message:
            `❌ The ${reconnectAttempts} ` +
            `attempts to try to reopen the WS have failed!`,
          alert: true,
        }),
      );
      reconnectAttempts = 0;
      serviceListeners.closePersist?.();
    }
  };

  const sendMessage = (message) => {
    if (socket.readyState === wsState.OPEN) {
      socket.send(message);
    } else {
      console.log(
        ...formatLog({
          action: 'close',
          message: `State: ${socket.readyState}.The message was not sent`,
        }),
      );
    }
  };

  const closeSocket = (message = '') => {
    message && console.log(...formatLog({ action: 'close', message }));
    if (socket?.readyState === wsState.OPEN) {
      socket?.close();
    }
  };

  const isOpen = () => socket?.readyState === wsState.OPEN;
  const isClosed = () => socket?.readyState === wsState.CLOSED;
  //=================================
  return {
    subscribe,
    unsubscribeAll,
    unsubscribeLast,
    sendMessage,
    closeSocket,
    isOpen,
    isClosed,
    addEventListener,
    removeEventListener,
    tryReconnect,
  };
}

/**
 * @param {Partial<CampaignModel>} campaign
 * @return {string}
 */
const formatQueryCampaignMessage = (campaign) => {
  const logic = JSON.stringify(campaign.logic);
  /** @type {WebSocketRequest} */
  const query = {
    action: 'execute_query',
    json_logic: logic,
    campaign_id: campaign.campaignId,
  };

  const message = JSON.stringify(query);

  return message;
};

export { startWebsocket, formatQueryCampaignMessage };
