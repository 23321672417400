// @ts-check
import { appSettings } from 'core/constants/app-settings';
import { logger } from 'core/utils';
import { verify } from 'jsonwebtoken';

import pk from './pk';

/**
 * @param {string} jwtKey
 * @param {number} expiryAdjustmentSeconds
 * @returns {AppJwtToken}
 */
const getTokenFromStorage = (
  jwtKey = appSettings.jwtKey,
  expiryAdjustmentSeconds = 30,
) => {
  logger.group('Verify Token');
  console.log('getting token from storage');
  try {
    const token = sessionStorage.getItem(jwtKey);
    if (!token) {
      console.log('no token in storage');
      return;
    }

    const decodedToken = /** @type {JwtPayload} */ (
      verify(token, pk, {
        algorithms: ['RS256'],
      })
    );

    // check that token isn't expiring too soon
    const nowSeconds = Math.floor(Date.now() / 1000);
    if (decodedToken.exp - expiryAdjustmentSeconds < nowSeconds) {
      console.log(
        `token is expiring in less than ${expiryAdjustmentSeconds} seconds`,
      );
      return;
    }

    const remainingTimeSeconds =
      decodedToken.exp - nowSeconds - expiryAdjustmentSeconds;
    console.log(`token is ok for another ${remainingTimeSeconds} seconds`);

    return { token, remainingTimeSeconds };
  } catch (error) {
    console.log('error getting token from storage: ', error);
  } finally {
    console.groupEnd();
  }
};

export { getTokenFromStorage };
