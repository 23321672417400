// @ts-check
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// core
import {
  OPEN_MODE,
  PAGE_STEP,
  resolveRouteWithId,
  routerUrl,
  useScrollTop,
} from 'core';
import { Breadcrumb, PageTitle } from 'core/components';
import { useStateCampaign } from 'core/hooks/use-state-campaign';

// state
import { useAppDispatch, useAppSelector } from 'core/store';
import {
  setCampaignData,
  setPageSummary,
  setPreviousPage,
  setSelectedRoute,
} from 'core/store/slices';

// components
import { DataForm } from './components/DataForm';

const CampaignBasicData = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const openMode = useAppSelector((state) => state.campaignData.openMode);
  const campaignId = useAppSelector(
    (state) => state.campaignData.campaign.campaignId,
  );

  useStateCampaign();
  useScrollTop();

  useEffect(() => {
    dispatch(setSelectedRoute(routerUrl.CAMPAIGN_CREATE_DATA));
    dispatch(setPageSummary('Step 1 of 2'));
  }, [dispatch]);

  /** @param {CampaignModel} updCampaign */
  const onNext = (updCampaign) => {
    dispatch(setPreviousPage(PAGE_STEP.CAMPAIGN_DATA));
    dispatch(
      setCampaignData({
        campaign: updCampaign,
        reloadData: false,
      }),
    );

    let filterPage = routerUrl.CAMPAIGN_DEFINE_FILTER;

    if (openMode === OPEN_MODE.UPDATE) {
      filterPage = resolveRouteWithId(
        routerUrl.CAMPAIGN_UPDATE_FILTER,
        updCampaign.campaignId,
      );
    }

    history.push(filterPage);
  };

  const viewFilters = () => {
    let filterPage = resolveRouteWithId(
      routerUrl.CAMPAIGN_UPDATE_FILTER,
      campaignId,
    );

    history.push(filterPage);
  };

  let pageTitle = 'View campaign';

  if (openMode === OPEN_MODE.CREATE) pageTitle = 'Create a campaign';
  else if (openMode === OPEN_MODE.UPDATE) pageTitle = 'Update campaign';

  return (
    <section>
      <header>
        <PageTitle title={pageTitle} />
        {openMode === OPEN_MODE.VIEW && (
          <Breadcrumb
            currentPage={PAGE_STEP.CAMPAIGN_DATA}
            openMode={openMode}
          />
        )}
      </header>
      <DataForm onNext={onNext} onViewFilters={viewFilters} />
    </section>
  );
};

export { CampaignBasicData };
