// @ts-check

import { mapCsvTableToJson } from 'core/utils/csv-utils';
import { API, fetchData, putData } from 'core/utils/http-utils';

/**
 * @param {string} campaignId
 * @param {ProcessedCsvFile} csvFile
 */
const uploadFile = async (campaignId, csvFile) => {
  const { headers, rows, fileNameNoExt: filename } = csvFile;
  const jsonObject = mapCsvTableToJson({
    headers,
    rows,
  });
  const jsonFile = JSON.stringify(jsonObject);

  const uploadUrl = `/presigned-url?campaign_id=${campaignId}&filename=${filename}`;
  /** @type {AxiosResponse<PresignedUrlResponse>} */
  const { data } = await fetchData(uploadUrl);
  // Create the formData which contains the Binary data to be uploaded.
  const formData = new FormData();
  Object.keys(data.fields).forEach((key) =>
    formData.append(key, data.fields[key]),
  );
  formData.append('file', jsonFile);
  /** @type {AxiosResponse<string>} */
  const res = await API.post(data.url, formData);

  return res.data;
};

/**
 * @param {string} campaignId
 * @param {string} filename
 */
const removeFile = async (campaignId, filename) => {
  /** @type {AxiosResponse<CampaignPostPutResponse>} */
  const { data } = await putData(`/campaign/${campaignId}`, {
    action: 'delete_source',
    filename,
  });

  return data;
};

/**
 * @param {string} statementId
 * @param {string} [type]
 */
async function getUrlDownload(statementId, type = null) {
  const BASE_URL = `/presigned-url?statement_id=${statementId}`;
  const url = type ? `${BASE_URL}&type=${type}` : BASE_URL;

  /** @type {AxiosResponse<{ url: string }>} */
  const response = await fetchData(url);

  return response.data;
}

export { uploadFile, removeFile, getUrlDownload };
