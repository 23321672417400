//@ts-check

import React from 'react';

function PageNotAuthorized({ title }) {
  const header = title || 'User Not Authorized';

  return (
    <section className="container text-center">
      <h1 className="d-inline-block" data-testid="not-authorized">
        {header}
      </h1>
      <p>You do not have access to this application.</p>
      <p>
        If you feel this is an error or would like to request access please
        email&nbsp;
        <a href="mailto:aacb@asu.edu">aacb@asu.edu</a>.
      </p>
    </section>
  );
}

export { PageNotAuthorized };
