// @ts-check

import { appSettings } from 'core';

/**
 * @template T
 * @param {T} state
 */
function persistState(state, key = appSettings.appStateKey) {
  const rawState = typeof state === 'string' ? state : JSON.stringify(state);
  sessionStorage.setItem(key, rawState);
}

/**
 * @template T
 * @return {T}
 */
function retrieveState(key = appSettings.appStateKey) {
  try {
    const rawState = sessionStorage.getItem(key);
    return JSON.parse(rawState || '');
  } catch (ex) {
    return /** @type {T} */ ({});
  }
}

function removePersistedState(key = appSettings.appStateKey) {
  sessionStorage.removeItem(key);
}

export { persistState, retrieveState, removePersistedState };
