// @ts-check
import React from 'react';
import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
  padding: 0;

  .checkbox-list-item {
    display: flex;
    align-items: center;
    label {
      font-weight: normal !important;
      margin-left: 0.2rem;
      vertical-align: middle;
      white-space: nowrap;
    }
  }

  .other-list-item {
    .other-input-text {
      display: inline-block;
      width: auto;
    }
  }

  label.duplicated-item {
    color: #b72a2a;
    font-weight: 700 !important;
  }
`;

/**
 * @param {CheckBoxListItem[]} items
 * @param {String} newOtherValue
 */
const hasDuplicatedError = (items = [], newOtherValue = '') => {
  const duplicatedItem = items.find(
    (selItem) => newOtherValue.toLowerCase() === selItem.value?.toLowerCase(),
  );
  return duplicatedItem;
};

/**
 * @param {{
 *    testId?: string
 *    id?: string
 *    name?: string
 *    className?: string
 *    placeholder?: string
 *    items: CheckBoxListItem[]
 *    otherItem: CheckBoxListItem
 *    value?: CheckBoxListValue
 *    readOnly?: boolean
 *    disabled?: boolean
 *    onChange: (e: CheckBoxListEventChange) => void
 * }} props
 */
const CheckBoxList = ({
  testId,
  id,
  name,
  className,
  placeholder,
  value,
  items = [],
  otherItem,
  readOnly,
  disabled,
  onChange = () => null,
}) => {
  const selectedItems = [...(value?.selectedItems || [])];
  const duplicatedItem = hasDuplicatedError(items, value?.other?.value);
  const isOtherItemInvalid = !value?.other?.value && value?.other?.checked;
  const componentId =
    id || name || `cb-list-${String(Math.random()).replace('.', '-')}`;
  const checkboxOtherId = componentId + '-other';
  /**
   * @param {CheckBoxListValue} newValue
   * @returns {CheckBoxListEventChange}
   */
  const createChangeEvent = (newValue) => {
    return {
      target: {
        id,
        name,
        value: newValue,
      },
    };
  };

  /**
   * @param {{
   *  testId: string,
   *  item: CheckBoxListItem,
   *  checkboxId: string,
   *  checked: boolean
   * }} props
   * */
  const renderCheckBoxItem = ({
    testId,
    item = /** @type {CheckBoxListItem} */ ({}),
    checkboxId,
    checked,
  }) => {
    return (
      <input
        data-testid={testId}
        className="form-check-input"
        type="checkbox"
        data-id={item.id}
        id={checkboxId}
        name={item.id}
        value={item.value}
        checked={checked}
        readOnly={readOnly}
        disabled={disabled}
        onChange={({ target }) => {
          const [targetDataId, targetId, targetValue] = [
            target.dataset.id,
            target.id,
            target.value,
          ];
          let newSelectedItems = [...selectedItems];
          let newOtherValue = { ...value?.other };
          // `other` checkbox
          if (targetId === checkboxOtherId) {
            newOtherValue = {
              ...value?.other,
              checked: target.checked,
              invalid: isOtherItemInvalid,
              duplicated: !!hasDuplicatedError(items, newOtherValue.value),
            };
          }
          // `item` checkbox checked == true
          else if (target.checked) {
            newSelectedItems = selectedItems.concat({
              id: targetDataId,
              value: targetValue,
            });
          }
          // `item` checkbox checked == false
          else {
            newSelectedItems = selectedItems.filter(
              (selItem) => selItem.id !== targetDataId,
            );
          }

          onChange(
            createChangeEvent({
              selectedItems: newSelectedItems,
              other: newOtherValue,
              duplicatedItem,
            }),
          );
        }}
      />
    );
  };

  /** @param {{ otherItem: CheckBoxListItem }} props */
  const renderOtherInput = ({
    otherItem = /** @type {CheckBoxListItem} */ ({}),
  }) => (
    <input
      autoComplete="off"
      type="text"
      className={`form-control form-control-sm other-input-text ${
        duplicatedItem || isOtherItemInvalid ? 'is-invalid' : ''
      }`}
      placeholder={placeholder}
      id={otherItem.id}
      name={otherItem.id}
      value={otherItem.value || value?.other?.value}
      readOnly={readOnly}
      disabled={readOnly}
      onChange={(e) => {
        let newSelectedItems = [...selectedItems];
        let newOtherValue = { ...value?.other, value: e.target.value };
        const duplicatedItem = hasDuplicatedError(items, newOtherValue?.value);

        onChange(
          createChangeEvent({
            selectedItems: newSelectedItems,
            other: newOtherValue,
            duplicatedItem,
            isOtherItemInvalid,
          }),
        );
      }}
    />
  );

  return (
    <List className={className} id={id} data-testid={testId}>
      {items.map((item, itemIndex) => {
        const checkboxId = `${componentId}-${item.id || itemIndex}`;
        return (
          <li key={checkboxId}>
            <div className={`checkbox-list-item ${item.id}-list-item`}>
              <div className="form-check">
                {renderCheckBoxItem({
                  testId: `${testId}-${itemIndex}`,
                  item,
                  checkboxId,
                  checked: value?.selectedItems?.some(
                    (selItem) => item.value && selItem.value === item.value,
                  ),
                })}
                <label
                  className={`form-check-label mr-2 ${
                    item.value === duplicatedItem?.value
                      ? 'duplicated-item font-weight-bold'
                      : ''
                  }`}
                  htmlFor={checkboxId}
                >
                  {item.label || item.value}
                </label>
              </div>
            </div>
          </li>
        );
      })}

      <li key={checkboxOtherId}>
        <div className="checkbox-list-item other-list-item">
          <div className="form-check">
            {renderCheckBoxItem({
              testId: `${testId}-other`,
              item: otherItem,
              checkboxId: checkboxOtherId,
              checked: value?.other?.checked,
            })}
            <label className="form-check-label mr-2" htmlFor={checkboxOtherId}>
              {otherItem?.label || 'Other'}
            </label>
            {renderOtherInput({
              otherItem,
            })}
          </div>
        </div>
      </li>
    </List>
  );
};

export { CheckBoxList };
