import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div.attrs((props) => ({
  top: props.top ?? '24',
  left: props.left ?? '0',
}))`
  position: relative;
  max-width: fit-content;
  display: inline;

  .tooltip-element {
    width: fit-content;
  }

  .tooltip-element:hover + div[role='tooltip'].tooltip-description {
    visibility: visible;
    opacity: 1;
  }

  [role='tooltip'].tooltip-description {
    background: #191919 0 0 no-repeat padding-box;
    color: #fafafa;
    font: normal normal normal 1rem Arial;
    line-height: 1.5rem;
    margin: 0 5px;
    max-width: 353px;
    min-width: 300px;
    padding: 2rem;
    position: absolute;
    left: ${(props) => props.left}px;
    top: ${(props) => props.top}px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 1000;
  }
`;

const TooltipContainerExtension = styled.div`
  button.uds-tooltip:hover + div[role='tooltip'].uds-tooltip-description {
    opacity: 1;
  }

  div[role='tooltip'].uds-tooltip-description {
    opacity: 0;
    transition: opacity 0.5s;
  }
`;

/**
 * @param {PropsWithChildren<{
 *   tooltipId: string
 * }>} props
 */
const Tooltip = ({ children, tooltipId }) => {
  return (
    <TooltipContainerExtension className="uds-tooltip-container">
      <button
        type="button"
        aria-describedby={tooltipId}
        className="uds-tooltip uds-tooltip-white"
        tabIndex={0}
      >
        <span className="fa-stack">
          <i className="fas fa-circle fa-stack-2x" />
          <i className="fas fa-info fa-stack-1x" />
        </span>
        <span className="uds-tooltip-visually-hidden">Notifications</span>
      </button>
      <div
        className="uds-tooltip-description text-wrap"
        id={tooltipId}
        role="tooltip"
      >
        {children}
      </div>
    </TooltipContainerExtension>
  );
};

/**
 * @param {PropsWithChildren<{
 *  tooltip: string
 *  tooltipId: string
 *  direction?: "bottom" | "right"
 * }>} props
 */
const ElementTooltip = ({
  children,
  tooltip,
  tooltipId,
  direction = 'bottom',
}) => {
  /** @type {{ current: HTMLDivElement }} */
  const elementRef = useRef(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    setTop(elementRef.current?.offsetHeight + 5);
  }, [elementRef]);

  useEffect(() => {
    if (direction === 'right') {
      setTop(0);
      setLeft(elementRef.current?.offsetWidth + 5);
    }
  }, [elementRef, direction]);

  return (
    <TooltipContainer top={top} left={left}>
      <div ref={elementRef} className="tooltip-element">
        {children}
      </div>
      <div className="tooltip-description" id={tooltipId} role="tooltip">
        {tooltip}
      </div>
    </TooltipContainer>
  );
};

export { ElementTooltip, Tooltip };
