// @ts-check
import { createContext } from 'react';
import { initialAuthState } from 'core/auth/store/state';

const stub = () => {
  throw new Error('You forgot to wrap your component in <AuthProvider>');
};

/**
 * @type {AuthContext}
 */
const initialContext = {
  ...initialAuthState,
  loginWithRedirect: stub,
  loadUserRoles: stub,
  logout: stub,
};

const AuthContext = createContext(initialContext);

export { AuthContext };
