// @ts-check
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// core
import { AppProvider, appRoles, routerUrl } from 'core';
import { AuthProvider, ProtectedRoute } from 'core/auth';
// base components
import { AppStateAlert, Footer, Navbar, NavSummary } from 'core/components';
// pages
import {
  Admin,
  Announcement,
  CampaignBasicData,
  CampaignDefineFilter,
  CampaignDetails,
  CampaignSearch,
  DevModeLogin,
  Home,
  LogOut,
  PageInProgress,
  PageNotAuthorized,
  PageNotFound,
  Support,
} from 'pages';
// styles
import './App.css';

function AppPages() {
  return (
    <AuthProvider>
      <AppProvider>
        <section className="app">
          <Navbar />
          <main className="container p-2">
            <AppStateAlert />
            <NavSummary />
            <Switch>
              <ProtectedRoute
                exact
                path={routerUrl.HOME}
                component={Home}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <ProtectedRoute
                path={routerUrl.CAMPAIGN_SEARCH}
                component={CampaignSearch}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <ProtectedRoute
                path={routerUrl.CAMPAIGN_SCHEDULE}
                component={PageInProgress}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <ProtectedRoute
                path={routerUrl.REPORTING}
                component={PageInProgress}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <ProtectedRoute
                path={routerUrl.SUPPORT}
                component={Support}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <ProtectedRoute
                path={routerUrl.ADMIN}
                component={Admin}
                roles={[appRoles.ADMIN]}
              />
              <ProtectedRoute
                path={routerUrl.ANNOUNCEMENT_VIEW_DATA}
                component={Announcement}
                roles={[appRoles.ADMIN]}
              />
              <ProtectedRoute
                exact
                path={[
                  routerUrl.CAMPAIGN_UPDATE_DATA,
                  routerUrl.CAMPAIGN_VIEW_DATA,
                  routerUrl.CAMPAIGN_CREATE_DATA,
                ]}
                component={CampaignBasicData}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <ProtectedRoute
                exact
                path={[
                  routerUrl.CAMPAIGN_UPDATE_FILTER,
                  routerUrl.CAMPAIGN_VIEW_FILTER,
                  routerUrl.CAMPAIGN_DEFINE_FILTER,
                ]}
                component={CampaignDefineFilter}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <ProtectedRoute
                path={routerUrl.CAMPAIGN_DETAILS}
                component={CampaignDetails}
                roles={[appRoles.CREATOR, appRoles.ADMIN]}
              />
              <Route path={routerUrl.LOG_OUT} component={LogOut} />
              <Route
                path={routerUrl.PAGE_NOT_AUTHORIZED}
                component={PageNotAuthorized}
              />
              <Route
                path={['*', routerUrl.PAGE_NOT_FOUND]}
                component={PageNotFound}
              />
            </Switch>
          </main>
          <Footer />
        </section>
      </AppProvider>
    </AuthProvider>
  );
}

function App() {
  return (
    <Router>
      <Switch>
        <Route
          path={[routerUrl.DEV_LOGIN, `${routerUrl.DEV_LOGIN}/*`]}
          component={DevModeLogin}
        />
        <Route path="/" component={AppPages} />
      </Switch>
    </Router>
  );
}

export default App;
