// @ts-check
import React from 'react';

// Core.
import { useStateAnnouncement } from 'core/hooks/use-state-announcement';

// Local components.
import { DataForm } from './components/DataForm';

const Announcement = () => {
  useStateAnnouncement();

  return (
    <section>
      <h2>
        <span className="highlight-gold" data-testid="support">
          Edit Announcement
        </span>
      </h2>
      <DataForm />
    </section>
  );
};

export { Announcement };
