// @ts-check

/**
 * @param {Function} func
 * @param {number} [delay=500]
 */
const debounce = (func, delay = 500) => {
  let timer;
  return function (...args) {
    const context = this;
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export { debounce };
