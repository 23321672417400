// @ts-check

/**
 * This code is a porting of @asu-design-system/bootstrap4-theme `table`
 */

// TODO: refactor this code to be a fully React component

function setPreButtonPosition() {
  const wrapperSelector = '.uds-table-fixed-wrapper';
  const tableSelector = '.uds-table.uds-table-fixed table';
  const prevScrollSelector = '.scroll-control.previous';

  const wrappers = document.querySelectorAll(wrapperSelector);
  wrappers?.forEach((wrapper, index) => {
    /** @type {HTMLTableElement} */
    const table = wrapper.querySelector(tableSelector);
    table.setAttribute('id', 'uds-table-' + index);
    /** @type {HTMLTableCellElement} */
    const firstCol = table.querySelector('thead tr > *');
    /** @type {HTMLElement} */
    const prevButton = wrapper.querySelector(prevScrollSelector);
    prevButton.style.left = firstCol?.offsetWidth + 'px';
  });
}

function setButtonLiListeners() {
  const containerSelector = '.uds-table-fixed';
  const wrapperSelector = '.uds-table-fixed-wrapper';
  const prevScrollSelector = '.scroll-control.previous';
  const nextScrollSelector = '.scroll-control.next';

  const wrapper = document.querySelector(wrapperSelector);
  if (!wrapper) {
    console.warn(`the element ${wrapperSelector} was not found!`);
    return;
  }
  const container = wrapper.querySelector(containerSelector);
  const prevButton = wrapper.querySelector(prevScrollSelector);
  const nextButton = wrapper.querySelector(nextScrollSelector);

  function scrollLeft(value) {
    const currentScrollLeft = container.scrollLeft;
    const newValue = container.scrollLeft + value;
    container.scrollLeft = newValue;
    // reached the end of the area
    wrapper.setAttribute(
      'data-scroll-end',
      String(currentScrollLeft === container.scrollLeft),
    );
  }

  ['click', 'focus'].forEach((eventName) => {
    prevButton.addEventListener(eventName, () => scrollLeft(-100));
    nextButton.addEventListener(eventName, () => scrollLeft(100));
  });

  container.addEventListener('scroll', () => {
    debounce(() => {
      wrapper.setAttribute('data-scroll-left', String(container.scrollLeft));
    }, 100)();
  });
}

function debounce(func, timeout) {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function initializeFixedTable() {
  setPreButtonPosition();
  setButtonLiListeners();
  window.addEventListener('resize', function () {
    debounce(setPreButtonPosition, 100)();
  });
}

export { initializeFixedTable };
