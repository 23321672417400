import { processAppError } from 'core';
import { actionTypes } from './actions';

// @ts-check

/**
 * @param {AuthState} state
 * @param {AuthAction} action
 * @return {AuthState}
 */
export const reducer = (state, action) => {
  console.group('Auth reducer');
  console.log('State: ', state);
  console.log('Action: ', action);
  console.groupEnd();

  switch (action.type) {
    case actionTypes.INITIALISED:
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        ssoName: action.ssoName,
        error: undefined,
        isLoading: false,
      };
    case actionTypes.HANDLE_REDIRECT_COMPLETE:
    case actionTypes.GET_ACCESS_TOKEN_COMPLETE:
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
      };
    case actionTypes.SET_USER_ROLES:
      return {
        ...state,
        userRoles: action.userRoles,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case actionTypes.ERROR:
      return {
        ...state,
        errorBlocker: action.errorBlocker,
        error: processAppError(action.error).error,
        isLoading: false,
      };
    default:
      return state;
  }
};
